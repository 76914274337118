import { useEffect, useRef, useState } from "react";

import { AttachmentIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FaArrowUp } from "react-icons/fa6";
import { VscDebugRestart } from "react-icons/vsc";

import ChatImage from "../chat-image";
import { Textarea } from "./ui/textarea";

const MAX_IMAGES = 5;

export default function ChatInput(props: {
  selectedModelName: string;
  onSubmit: (text: string, images?: string[]) => void;
  multiModal?: boolean;
  isLoading?: boolean;
  isSessionEnded: boolean;
  onReset: () => void;
  isVlmModel: boolean;
}) {
  const [value, setValue] = useState<string>("");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [images, setImages] = useState<string[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValue("");
    setImages([]);
  }, [props.selectedModelName]);

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;
    setValue(val);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (value.length === 0) {
      return;
    }
    const message = e.currentTarget.elements.namedItem(
      "message"
    ) as HTMLInputElement;
    props.onSubmit(message.value, images);
    setTimeout(() => {
      setValue("");
      setImages([]);
    }, 10);
  };

  const handleReset = () => {
    props.onReset();
    setTimeout(() => {
      setValue("");
      setImages([]);
    }, 10);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.nativeEvent.isComposing || event.keyCode === 229) {
      return;
    }

    if (
      ((event.which || event.keyCode) === 13 || event.key === "Enter") &&
      !event.shiftKey &&
      !event.altKey &&
      !event.ctrlKey &&
      !event.metaKey
    ) {
      const form = event.currentTarget.form;
      setTimeout(() => {
        form?.requestSubmit();
      }, 50);
    } else if (event.key === "Enter") {
      // Adds a new line
      setValue(`${value}`);
    } else {
      setValue(event.currentTarget.value);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files);
      fileArray.slice(0, MAX_IMAGES - images.length).forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          setImages((prevList) => [...prevList, base64String]);
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleImageDeletion = (index: number) => {
    setImages((prevList) => prevList.filter((_, i) => i !== index));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Flex
        borderColor={"highlightBg"}
        borderWidth={1}
        mt={2}
        align="center"
        flexWrap="wrap"
      >
        <Stack w="100%" gap={0}>
          {props.isVlmModel && images.length > 0 && (
            <Flex m={1}>
              {images.map((imageBase64, index) => (
                <ChatImage
                  key={index}
                  imageBase64={imageBase64}
                  handleImageDeletion={() => handleImageDeletion(index)}
                />
              ))}
            </Flex>
          )}
          <Flex align="center">
            {props.isVlmModel && (
              <Box ml={3}>
                <IconButton
                  isRound={true}
                  icon={<AttachmentIcon />}
                  onClick={handleButtonClick}
                  aria-label={`Upload images`}
                  size="sm"
                  isDisabled={images.length >= MAX_IMAGES}
                />
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                  style={{ display: "none" }}
                  multiple
                />
              </Box>
            )}
            {!props.isSessionEnded && (
              <>
                <Textarea
                  name="message"
                  onKeyDown={handleKeyDown}
                  placeholder="Message..."
                  ref={textAreaRef}
                  rows={1}
                  className="focus:shadow-none focus:outline-none focus:ring-0 focus:border-none flex-1 border-none text-zinc-500 min-h-[38px] overflow-hidden resize-none text-base"
                  value={value}
                  onChange={handleChange}
                />
                <Flex display={{ base: "none", md: "flex" }}>
                  <Button
                    type="submit"
                    m={1}
                    variant={"cta"}
                    isDisabled={props.isLoading || value.length === 0}
                  >
                    Send
                  </Button>
                  <Button
                    type="reset"
                    m={1}
                    isDisabled={props.isLoading}
                    onClick={handleReset}
                  >
                    Start Over
                  </Button>
                </Flex>
                <Flex display={{ base: "flex", md: "none" }}>
                  <Button
                    type="submit"
                    m={1}
                    variant={"cta"}
                    isDisabled={props.isLoading}
                  >
                    <FaArrowUp />
                  </Button>
                  <Button
                    m={1}
                    isDisabled={props.isLoading}
                    onClick={handleReset}
                  >
                    <VscDebugRestart />
                  </Button>
                </Flex>
              </>
            )}
            {props.isSessionEnded && (
              <Flex align="center" gap={2} w="full" justifyContent="center">
                <Text fontSize={"medium"}>Session ended</Text> <Text>|</Text>
                <Link onClick={handleReset}>Start over</Link>
              </Flex>
            )}
          </Flex>
        </Stack>
      </Flex>
    </form>
  );
}
