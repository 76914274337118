import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

interface ChatImageProps {
  imageBase64: string;
  handleImageDeletion?: () => void;
}

export default function ChatImage({
  imageBase64,
  handleImageDeletion,
}: ChatImageProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        cursor="pointer"
        display="inline-block"
        role="group"
        position="relative"
        overflow="hidden"
        borderRadius={5}
        borderWidth="1px"
        borderColor="white"
        _hover={{
          borderColor: "highlightBg",
        }}
      >
        <Image src={imageBase64} boxSize="100px" onClick={onOpen} />
        {handleImageDeletion && (
          <IconButton
            isRound={true}
            position="absolute"
            top="0"
            right="0"
            icon={<CloseIcon />}
            onClick={handleImageDeletion}
            aria-label={`Delete image`}
            size="sm"
            opacity={0}
            _groupHover={{ opacity: 1 }}
          />
        )}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW="80%">
          <ModalCloseButton />
          <ModalBody>
            <Flex justify="center" mx={5}>
              <Image
                src={imageBase64}
                maxH="90vh"
                maxW="100%"
                objectFit="contain"
                alt="Selected"
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
