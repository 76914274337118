import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Link,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { ReactElement, useRef } from "react";
import { postApiMutation } from "../services/api/api-service";
import { useGlobalAlert } from "../services/global-alert/global-alert-context";
import { contactEmailUrl } from "../utils";

interface ProductPlanConfirmationDialogProps {
  children: ReactElement<{
    onClick: () => void;
  }>;
  tier: string;
}
export function ProductPlanConfirmationDialog({
  children,
  tier,
}: ProductPlanConfirmationDialogProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { addAlert } = useGlobalAlert();
  const queryClient = useQueryClient();

  const { mutate, isPending } = postApiMutation("/billing/plan");
  const handleChoosePlan = async () => {
    mutate(
      { tier },
      {
        onSuccess: () => {
          addAlert({
            type: "success",
            body: <div>Successfully subscribed to {tier} tier.</div>,
          });
          queryClient.resetQueries();
        },
        onError: (error) => {
          addAlert({
            type: "error",
            body: (
              <div>
                Failed to subscribe to {tier} tier: {error.message}
              </div>
            ),
          });
        },
      }
    );
  };

  return (
    <>
      {React.cloneElement(children, {
        onClick: onOpen,
      })}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        closeOnOverlayClick={!isPending}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm the subscription
            </AlertDialogHeader>
            <AlertDialogBody>
              <Stack>
                <Text>
                  Please confirm the subscribing to <b>{tier}</b> tier.
                </Text>
                <Text>
                  Billing cycle is based on the calendar month, from the 1st day
                  of the month to the last day of the month. Invoices will be
                  generated on the last day of each month and the first invoice
                  will be prorated based on the subscription start date.
                </Text>
                <Text>
                  Please <Link href={contactEmailUrl}>contact us</Link> for any
                  question you have.
                </Text>
              </Stack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Flex className="gap-1">
                <Button
                  variant={"cta"}
                  onClick={handleChoosePlan}
                  ml={3}
                  isLoading={isPending}
                  loadingText="Updating"
                >
                  Confirm
                </Button>
                <Button
                  ref={cancelRef}
                  onClick={onClose}
                  isDisabled={isPending}
                >
                  Cancel
                </Button>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
