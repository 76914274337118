import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Link,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FaCode, FaRegUser } from "react-icons/fa";
import { LuLayers } from "react-icons/lu";
import {
  MdAttachMoney,
  MdAvTimer,
  MdDriveFileRenameOutline,
  MdOutlineDashboardCustomize,
} from "react-icons/md";
import Actor from "../../components/actor";
import UsageStats from "../../components/usage-stats/usage-stats";
import {
  BaseModelResponse,
  LoRAResponse,
  ModelDeploymentResponse,
  ModelResponse,
  ModelType,
} from "../../interfaces/model";
import DashboardContentLayout from "../../layouts/dashboard-content-layout";
import { ApiError, getApi } from "../../services/api/api-service";

import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useEffect } from "react";
import { SiTheconversation } from "react-icons/si";
import { NavLink, useNavigate } from "react-router-dom";
import { ActorType } from "../../interfaces/actor";
import { useGlobalAlert } from "../../services/global-alert/global-alert-context";
import { formatTimestampToLocal, tryUrlEncode } from "../../utils";
import { ContactSupportLink } from "../contact-support-link";
import ExternalLink from "../external-link";
import QueryModelModal from "./query-model-modal";

interface ModelDetailsProps {
  modelName: string;
  modelType: ModelType;

  mainTitle: string;
  mainTitleHelperText: string | ReactElement;
}

export default function ModelDetails({
  modelName,
  modelType,
  mainTitle,
  mainTitleHelperText,
}: ModelDetailsProps) {
  const urlEncodedLoraName = tryUrlEncode(modelName);

  let data: ModelResponse<ModelDeploymentResponse> | undefined,
    error: ApiError | null,
    sectionTitle: string,
    rootPagePath: string;
  if (modelType === ModelType.LORA) {
    const result = getApi<LoRAResponse>(`/lora/${urlEncodedLoraName}`);

    rootPagePath = "/lora";
    sectionTitle = "LoRA";
    data = result.data;
    error = result.error;
  } else {
    const result = getApi<BaseModelResponse>(
      `/base-model/${urlEncodedLoraName}`
    );

    rootPagePath = "/base-model";
    sectionTitle = "Base Model";
    data = result.data;
    error = result.error;
  }

  const { addAlert } = useGlobalAlert();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  useEffect(() => {
    if (error) {
      if (error.statusCode === 404) {
        addAlert({
          type: "error",
          body: (
            <Text>
              Requested {sectionTitle} {modelName} does not exist.
            </Text>
          ),
        });
        navigate("/lora");
      } else {
        addAlert({
          type: "error",
          body: (
            <Text>
              Unexpected error happened when fetching model details, please
              retry and <ContactSupportLink /> if the issue persists.
            </Text>
          ),
        });
      }
    }
  }, [error]);

  return (
    <DashboardContentLayout
      rootNavSection={
        <Link
          as={NavLink}
          to={rootPagePath}
          fontSize="sm"
          color="black"
          mr={3}
          textDecoration="none"
        >
          {mainTitle}
        </Link>
      }
      subNavSection={
        data?.name ? (
          data.name
        ) : (
          <Flex pl={3}>
            <Skeleton height="20px" w="200px" pl={3} />
          </Flex>
        )
      }
      mainTitle={mainTitle}
      mainTitleHelperText={mainTitleHelperText}
      sectionTitle={`${sectionTitle} Detail`}
      sectionTitleCallToAction={
        <Flex gap={2}>
          <Button
            variant="cta"
            leftIcon={<SiTheconversation />}
            isDisabled={!data}
            onClick={() =>
              navigate("/playground?model=" + tryUrlEncode(modelName))
            }
          >
            Chat
          </Button>
          <QueryModelModal modelType={modelType} name={data?.name ?? ""}>
            <Button variant="cta" leftIcon={<FaCode />} isDisabled={!data}>
              Query
            </Button>
          </QueryModelModal>
        </Flex>
      }
    >
      <Stack spacing={5}>
        <Card variant="information">
          <CardHeader>MODEL INFORMATION</CardHeader>
          <CardBody>
            {data?.activeDeployment ? (
              <SimpleGrid spacing={3} columns={[1, null, 2]}>
                <Box>
                  <Stack>
                    <Flex align="center" gap={2}>
                      <MdOutlineDashboardCustomize fill="gray" />
                      <Text as="b">Name</Text>
                    </Flex>
                    <Text pl={6}>{data.name}</Text>
                  </Stack>
                </Box>
                <Box>
                  <Flex gap={1} align="baseline">
                    <Stack>
                      <Flex align="center" gap={2}>
                        <FaRegUser color="gray" />
                        <Text as="b">Deployed by:</Text>
                      </Flex>
                      <Box pl={6}>
                        <Actor actor={data.activeDeployment.creator} />
                      </Box>
                    </Stack>
                  </Flex>
                </Box>
                {modelType === ModelType.LORA && (
                  <>
                    <Box>
                      <Flex gap={1} align="baseline">
                        <Stack>
                          <Flex align="center" gap={2}>
                            <LuLayers color="gray" />
                            <Text as="b">Base Model:</Text>
                          </Flex>
                          <Link
                            as={NavLink}
                            pl={6}
                            to={`/base-model/${encodeURIComponent(
                              (data as LoRAResponse).activeDeployment!
                                .baseModelName
                            )}`}
                          >
                            {
                              (data as LoRAResponse).activeDeployment!
                                .baseModelName
                            }
                          </Link>
                        </Stack>
                      </Flex>
                    </Box>
                    <Box>
                      <Flex gap={1} align="baseline">
                        <Stack>
                          <Flex align="center" gap={2}>
                            <MdAvTimer color="gray" />
                            <Text as="b">Deployed at:</Text>
                          </Flex>
                          <Text pl={6}>
                            {formatTimestampToLocal(
                              data.activeDeployment.createdAt
                            )}
                          </Text>
                        </Stack>
                      </Flex>
                    </Box>
                  </>
                )}
                {(modelType !== ModelType.LORA ||
                  data.activeDeployment.creator.actorType !==
                    ActorType.OFFICIAL) && (
                  <Box>
                    <Flex gap={1} align="baseline">
                      <Stack>
                        <Flex align="center" gap={2}>
                          <MdDriveFileRenameOutline fill="gray" />
                          <Text as="b">Model Source:</Text>
                        </Flex>
                        <Text pl={6}>
                          Hugging Face Model{" "}
                          <ExternalLink
                            text={data.activeDeployment.modelName}
                            href={`https://huggingface.co/${data.activeDeployment.modelName}`}
                          />{" "}
                          {data.activeDeployment.hfRevision &&
                            `hash: ${data.activeDeployment.hfRevision}`}
                        </Text>
                      </Stack>
                    </Flex>
                  </Box>
                )}
                <Box>
                  <Flex gap={1} align="baseline">
                    <Stack>
                      <Flex align="center" gap={2}>
                        <MdAttachMoney fill="gray" />
                        <Text as="b">Price 1M Tokens:</Text>
                      </Flex>
                      <Text pl={6}>
                        {(data.unitPrice / 100).toFixed(2)} USD
                      </Text>
                    </Stack>
                  </Flex>
                </Box>
              </SimpleGrid>
            ) : (
              <Skeleton height="250px" />
            )}
          </CardBody>
        </Card>
        <Heading as="h2" size={"md"} fontWeight="500" pt={5}>
          Usage Stats{" "}
          <Link
            textDecoration="none"
            fontSize="xx-large"
            _hover={{ textDecoration: "none" }}
            onClick={() =>
              queryClient.resetQueries({
                predicate: (query) => {
                  const queryKey = String(query.queryKey[0]);

                  return (
                    queryKey.includes("billing") || queryKey.includes("usage")
                  );
                },
              })
            }
          >
            ⟳
          </Link>
        </Heading>
        {data ? (
          <UsageStats
            selectedModel={{
              type: ModelType.LORA,
              name: data.name,
            }}
          />
        ) : (
          <Skeleton height="700px" />
        )}
      </Stack>
      {/* @TODO(@daiyi): handle the case when there's no active deployment */}
    </DashboardContentLayout>
  );
}
