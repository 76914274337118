import { Button } from "@chakra-ui/react";
import { GrDeploy } from "react-icons/gr";
import NewEvaluationModal from "../components/evaluation/new-evaluation-modal";
import DashboardContentLayout from "../layouts/dashboard-content-layout";

export function EvaluationsPage() {
  return (
    <DashboardContentLayout
      subNavSection="Overview"
      mainTitle="Evaluations"
      mainTitleHelperText="Evaluate model performance with datasets."
      sectionTitle="Manage Evaluations"
      sectionTitleHelperText="Create, view, and manage evaluations."
      sectionTitleCallToAction={
        <NewEvaluationModal>
          <Button variant="cta" leftIcon={<GrDeploy />}>
            Run new evaluation
          </Button>
        </NewEvaluationModal>
      }
    >
      <>TODO</>
    </DashboardContentLayout>
  );
}
