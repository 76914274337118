import { Button, Input } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApiClient } from "../../services/api/api-client-context";
import { postApiWithFormData } from "../../services/api/api-service";
import { tryUrlEncode } from "../../utils";

interface DatasetUploadResponse {
  fileId: string;
}

export default function DatasetUploader() {
  const [file, setFile] = useState<File>();
  const apiClient = useApiClient();
  const navigate = useNavigate();

  return (
    <>
      <Input
        placeholder="Basic usage"
        type="file"
        onChange={(e) => setFile(e.target.files?.[0])}
      />
      <Button
        onClick={async () => {
          const result = await postApiWithFormData<DatasetUploadResponse>(
            apiClient,
            "/dataset/convert-training-data",
            {
              name: "name",
              file: await file?.text(),
            },
            "v1",
            async () => {},
            async () => {}
          );

          navigate(`/dataset-viewer/${tryUrlEncode(result.fileId)}`);
        }}
      >
        Submit
      </Button>
    </>
  );
}
