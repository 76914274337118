import { ActorResponse } from "./actor";

export enum ModelSource {
  HF_HUB = "HF_HUB",
}

export enum ModelType {
  LORA,
  BASE_MODE,
}

export interface LoRAResponse extends ModelResponse<LoRADeploymentResponse> {}
export interface LoRADeploymentResponse extends ModelDeploymentResponse {
  baseModelName: string;
}

export interface BaseModelResponse
  extends ModelResponse<BaseModelDeploymentResponse> {}
export interface BaseModelDeploymentResponse extends ModelDeploymentResponse {}

export interface ModelResponse<T extends ModelDeploymentResponse> {
  name: string;
  unitPrice: number;
  activeDeployment: T | null;
  toolsEnabled: boolean;
  isVlmModel: boolean;
}
export interface ModelDeploymentResponse {
  modelName: string;
  modelSource: ModelSource;
  createdAt: number;
  creator: ActorResponse;
  hfRevision: string | null;
}
