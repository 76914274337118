import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import applyCaseMiddleware from "axios-case-converter";
import { useApiClient } from "./api-client-context";

export interface ApiError {
  errorCode: ApiErrorCode;
  statusCode: number;
  message: string;
}

export enum ApiErrorCode {
  NOT_FOUND = "NOT_FOUND",
  BAD_REQUEST = "BAD_REQUEST",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  UNAUTHORIZED = "UNAUTHORIZED",

  EXISTING_API_KEY_NAME = "EXISTING_API_KEY_NAME",

  NON_EXISTING_MODEL = "NON_EXISTING_MODEL",
  NON_EXISTING_MODEL_REVISION = "NON_EXISTING_MODEL_REVISION",
  UNSUPPORTED_BASE_MODEL = "UNSUPPORTED_BASE_MODEL",
}

export type ApiVersion = "v1";

export function queryApi<T>(
  fetcher: (client: ApiClient) => Promise<T>,
  queryKey: ReadonlyArray<unknown>
) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const apiClient = useApiClient();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const res = useQuery({
    queryFn: async () => fetcher(apiClient),
    queryKey,
    staleTime: 60 * 5 * 1000,
    retry: retryHandler,
  });

  return res;
}

export function getApi<T>(
  path: string,
  queryParams: object = {},
  version: ApiVersion = "v1",
  onSuccess: (data: T) => Promise<void> = async () => {},
  onError: (e: unknown) => Promise<void> = async () => {},
  skipConvertingCase: boolean = false
) {
  const fullPath = buildFullPath(path, version);
  return queryApi<T>(
    (apiClient) =>
      apiClient.get(
        fullPath,
        queryParams,
        onSuccess,
        onError,
        skipConvertingCase
      ),
    [fullPath, queryParams]
  );
}

export async function postApiWithFormData<T>(
  apiClient: ApiClient,
  path: string,
  formData: object,
  version: ApiVersion = "v1",
  onSuccess: (data: T) => Promise<void> = async () => {},
  onError: (e: unknown) => Promise<void> = async () => {}
) {
  const fullPath = buildFullPath(path, version);
  return await apiClient.post<T>(
    fullPath,
    formData,
    onSuccess,
    onError,
    false,
    {
      "Content-Type": "multipart/form-data",
    }
  );
}

export async function postStreamingApi<T>(
  apiClient: ApiClient,
  path: string,
  payload: object = {},
  version: ApiVersion = "v1",
  onReceiveNewChunks: (newChunks: T[], allChunks: T[]) => void,
  onFinished: () => void,
  skipConvertingCase: boolean = false
) {
  const axiosClient = apiClient.getAxiosClient(skipConvertingCase);

  let previousChunks: T[] = [];
  await axiosClient.post(
    apiClient.baseUrl + buildFullPath(path, version),
    payload,
    {
      headers: { Authorization: `Bearer ${await apiClient.authTokenGetter()}` },
      responseType: "stream",
      onDownloadProgress: (progressEvent) => {
        const xhr = progressEvent.event.target;
        const { responseText } = xhr;

        const chunks = (responseText as string)
          .split("\n\n")
          .filter((text) => text.length > 0)
          .map((text) => JSON.parse(text.substring("data:".length).trim()));

        const newChunks = chunks.slice(previousChunks.length);

        onReceiveNewChunks(newChunks, chunks);
        previousChunks = chunks;
      },
    }
  );

  onFinished();
}

export function postApi<T>(
  path: string,
  payload: object = {},
  version: ApiVersion = "v1",
  onSuccess: (data: T) => Promise<void> = async () => {},
  onError: (e: unknown) => Promise<void> = async () => {},
  skipConvertingCase: boolean = false,
  additionalHeaders: { [key: string]: string } = {}
) {
  const fullPath = buildFullPath(path, version);
  return queryApi<T>(
    (apiClient) =>
      apiClient.post(
        fullPath,
        payload,
        onSuccess,
        onError,
        skipConvertingCase,
        additionalHeaders
      ),
    [fullPath, payload]
  );
}

export function postApiMutation<TInput extends object, TOutput>(
  path: string,
  version: ApiVersion = "v1"
) {
  const fullPath = buildFullPath(path, version);
  return apiMutationHelper(
    fullPath,
    async (apiClient, path, data: TInput) =>
      await apiClient.post<TOutput>(path, data)
  );
}

export function putApiMutation<TInput extends object, TOutput>(
  path: string,
  version: ApiVersion = "v1"
) {
  const fullPath = buildFullPath(path, version);
  return apiMutationHelper(
    fullPath,
    async (apiClient, path, data: TInput) =>
      await apiClient.put<TOutput>(path, data)
  );
}

export function deleteApiMutation<TInput extends object, TOutput>(
  path: string,
  version: ApiVersion = "v1"
) {
  const fullPath = buildFullPath(path, version);
  return apiMutationHelper(
    fullPath,
    async (apiClient, path, data: TInput) =>
      await apiClient.delete<TOutput>(path, data)
  );
}

export function buildFullPath(path: string, version: ApiVersion) {
  return `/${version}/${path}`;
}

type AuthTokenGetter = () => Promise<string | null>;
export default class ApiClient {
  baseUrl: string;
  authTokenGetter: AuthTokenGetter;
  private axiosClient: AxiosInstance;
  private axiosClientWithoutCaseMiddleware: AxiosInstance;

  constructor(authTokenGetter: AuthTokenGetter, baseUrl: string) {
    this.authTokenGetter = authTokenGetter;
    this.baseUrl = baseUrl;
    this.axiosClient = applyCaseMiddleware(axios.create());
    this.axiosClientWithoutCaseMiddleware = axios.create();
  }

  getAxiosClient(skipConvertingCase: boolean = false) {
    return skipConvertingCase
      ? this.axiosClientWithoutCaseMiddleware
      : this.axiosClient;
  }

  private async getHeaders() {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await this.authTokenGetter()}`,
    };
  }

  private async handleRequest<T>(
    request: () => Promise<AxiosResponse<T, any>>,
    onSuccess: (data: T) => Promise<void> = async () => {},
    onError: (e: unknown) => Promise<void> = async () => {}
  ): Promise<T> {
    try {
      const returnData = (await request()).data;
      await onSuccess(returnData);
      return returnData;
    } catch (e) {
      await onError(e);
      if (e instanceof AxiosError) {
        // eslint-disable-next-line no-throw-literal
        throw {
          statusCode: e.response?.status,
          errorCode: e.response?.data.error?.code,
          message:
            e.response?.data.error?.message ??
            "Something wrong happened, please try again or contact founders@empower.dev.",
        };
      } else {
        throw e;
      }
    }
  }

  private getRequestUrl(endpoint: string): string {
    return (this.baseUrl + endpoint).replace(/([^:]\/)\/+/g, "$1");
  }

  public async get<T>(
    endpoint: string,
    queryParams: { [key: string]: any } = {},
    onSuccess: (data: T) => Promise<void> = async () => {},
    onError: (e: unknown) => Promise<void> = async () => {},
    skipConvertingCase: boolean = false
  ): Promise<T> {
    return await this.handleRequest<T>(
      async () =>
        await this.getAxiosClient(skipConvertingCase).get<T>(
          this.getRequestUrl(endpoint),
          {
            params: queryParams,
            headers: await this.getHeaders(),
          }
        ),
      onSuccess,
      onError
    );
  }

  public async post<T>(
    endpoint: string,
    body: object,
    onSuccess: (data: T) => Promise<void> = async () => {},
    onError: (e: unknown) => Promise<void> = async () => {},
    skipConvertingCase: boolean = false,
    additionalHeaders: { [key: string]: string } = {}
  ): Promise<T> {
    return await this.handleRequest<T>(
      async () =>
        await this.getAxiosClient(skipConvertingCase).post<T>(
          this.getRequestUrl(endpoint),
          body,
          {
            headers: {
              ...(await this.getHeaders()),
              ...additionalHeaders,
            },
          }
        ),
      onSuccess,
      onError
    );
  }

  public async put<T>(
    endpoint: string,
    body: object,
    onSuccess: (data: T) => Promise<void> = async () => {},
    onError: (e: unknown) => Promise<void> = async () => {},
    skipConvertingCase: boolean = false
  ): Promise<T> {
    return await this.handleRequest<T>(
      async () =>
        await this.getAxiosClient(skipConvertingCase).put<T>(
          this.getRequestUrl(endpoint),
          body,
          {
            headers: await this.getHeaders(),
          }
        ),
      onSuccess,
      onError
    );
  }

  public async delete<T>(
    endpoint: string,
    params: object = {},
    onSuccess: (data: T) => Promise<void> = async () => {},
    onError: (e: unknown) => Promise<void> = async () => {},
    skipConvertingCase: boolean = false
  ): Promise<T> {
    return await this.handleRequest<T>(
      async () =>
        await this.getAxiosClient(skipConvertingCase).delete<T>(
          this.getRequestUrl(endpoint),
          {
            headers: await this.getHeaders(),
            params,
          }
        ),
      onSuccess,
      onError
    );
  }
}

function retryHandler(failureCount: number, error: ApiError): boolean {
  // only retry on 500
  return error.statusCode === 500 && failureCount < 2;
}

function apiMutationHelper<TInput extends object, TOutput>(
  path: string,
  mutator: (
    apiClient: ApiClient,
    path: string,
    data: TInput
  ) => Promise<TOutput>
) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const apiClient = useApiClient();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const res = useMutation({
    mutationFn: (data: TInput) => mutator(apiClient, path, data),
    retry: retryHandler,
  });

  return res;
}
