import { Flex, Icon, Stack, Text } from "@chakra-ui/react";
import { useState } from "react";
import { IconType } from "react-icons";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useLocation } from "react-router-dom";
import { default as SidenavItemComponent } from "./sidenav-item";
import { SidenavItem } from "./sidenav-items";

interface SidenavItemGroupProps {
  label: string;
  icon: IconType;
  sidenavItems: SidenavItem[];
}

export default function SidenavItemGroup({
  label,
  icon,
  sidenavItems,
}: SidenavItemGroupProps) {
  const location = useLocation();

  const [isExpanded, setIsExpanded] = useState<boolean>(
    sidenavItems.find(
      (item) =>
        item.to === location.pathname || `/${item.to}` === location.pathname
    ) !== undefined
  );

  return (
    <Stack>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        py={2}
        px={4}
        cursor={"pointer"}
        onClick={() => setIsExpanded(!isExpanded)}
        _hover={{
          bg: "gray.300",
        }}
        borderRadius="md"
      >
        <Flex>
          <Icon boxSize="5" as={icon} />
          <Text ml={3} fontSize="sm">
            {label}
          </Text>
        </Flex>
        {isExpanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </Flex>
      {isExpanded && (
        <Stack pl={5}>
          {sidenavItems.map((item, index) => (
            <SidenavItemComponent
              label={item.label}
              to={item.to}
              icon={item.icon}
              key={index}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
}
