// eslint-enable no-implicit-coercion
"use client";
import * as amplitude from "@amplitude/analytics-browser";
import {
  Button,
  Card,
  Checkbox,
  Flex,
  Link,
  Select,
  Stack,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import { useAuth, useUser } from "@clerk/clerk-react";
import { SearchSelect, SearchSelectItem } from "@tremor/react";
import OpenAI from "openai";
import { useEffect, useReducer, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ChatInput, ChatMessages } from ".";
import { BaseModelResponse, LoRAResponse } from "../../interfaces/model";
import DashboardContentLayout from "../../layouts/dashboard-content-layout";
import { getApi } from "../../services/api/api-service";
import { useGlobalAlert } from "../../services/global-alert/global-alert-context";
import { getBaseApiUrl } from "../../utils";
import { ChatMessage, ChatState } from "./common/types";
import { HEALTHCARE_FIRST_MESSAGE, get_healthcare_prompt } from "./lib/prompts";
import { sample1, sample2, sample3 } from "./lib/sample-messages";
import { getToolResponse, tools } from "./lib/tools";
import SystemPromptAndFunctionsPromptModal from "./system-prompt-and-functions-modal";

const openai = new OpenAI({
  baseURL: `${getBaseApiUrl()}/v1/demo`,
  apiKey: "",
  dangerouslyAllowBrowser: true,
});

type ChatAction<Type extends keyof ChatState> = {
  field: Type;
  value: ChatState[Type];
};

function snakeToCamel(s: string) {
  return s.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());
}

export function ChatInferenceModule() {
  const { userId } = useAuth();
  const { user } = useUser();
  const [functionSpecs, setFunctionSpecs] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { addAlert, clear } = useGlobalAlert();
  const [isSessionEnded, setIsSessionEnded] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [language, setLanguage] = useState("english");
  const { onCopy, setValue } = useClipboard("");
  const [includeThinking, setIncludeThinking] = useState(true);
  const [enforceVerification, setEnforceVerification] = useState(true);

  let lorasResult = undefined;
  let baseModelsResult = undefined;
  if (user) {
    lorasResult = getApi<LoRAResponse[]>("/lora");
    baseModelsResult = getApi<BaseModelResponse[]>("/base-model");
  }

  // const officialLoras = [
  //   "empower-functions-small",
  //   "empower-functions-medium",
  //   "empower-functions-large",
  // ];
  const [modelName, setModelName] = useState("empower-functions-small");

  const [requestBody, setRequestBody] = useReducer(
    (state: ChatState, action: ChatAction<keyof ChatState>): ChatState => {
      if (action.field === "stop") {
        return {
          ...state,
          [action.field]: (action.value as string[]).filter(
            (v: string) => v.trim().length > 0
          ),
        };
      }
      return { ...state, [action.field]: action.value };
    },
    {
      messages: [
        {
          id: uuidv4(),
          content: HEALTHCARE_FIRST_MESSAGE[language],
          role: "assistant",
        },
      ] as ChatMessage[],
      stop: [],
      top_p: 1,
      top_k: 50,
      presence_penalty: 0,
      frequency_penalty: 0,
      context_length_exceeded_behavior: "truncate",
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      // ...model.generationDefaults!,
      temperature: 0,
      max_tokens: 1024,
    }
  );

  const streamFunctionCall = async (
    updatedMessages: ChatMessage[],
    chunk: OpenAI.Chat.Completions.ChatCompletionChunk
  ) => {
    if (!chunk.choices[0].delta.tool_calls) {
      return;
    }

    // Stream the function call.
    for (const delta of chunk.choices[0].delta.tool_calls) {
      if (!delta) {
        continue;
      }
      const toolCalls =
        updatedMessages[updatedMessages.length - 1].toolCalls || [];
      if (delta.index >= toolCalls.length) {
        if (updatedMessages[updatedMessages.length - 1]?.metadata != null) {
          updatedMessages[updatedMessages.length - 1].metadata!.loading = false;
        }
        const toolCall = {
          id: delta.id!,
          type: delta.type!,
          function: {
            name: delta.function!.name!,
            arguments: delta.function!.arguments || "",
          },
        };
        toolCalls.push(toolCall);
      } else {
        toolCalls[delta.index] = {
          ...toolCalls[delta.index],
          function: {
            ...toolCalls[delta.index].function,
            arguments:
              toolCalls[delta.index].function.arguments +
                delta.function!.arguments || "",
          },
        };
      }
      updatedMessages[updatedMessages.length - 1].toolCalls = toolCalls;
      setRequestBody({ field: "messages", value: [...updatedMessages] });
      setTrigger((prev) => prev + 1);
    }
  };

  async function maybeAppendFunctionResponse(
    lastMessage: ChatMessage,
    lastUserMessage: string
  ) {
    if (
      lastMessage.toolCalls &&
      lastMessage.toolCalls[0] &&
      lastMessage.metadata
    ) {
      const toolName = snakeToCamel(lastMessage.toolCalls[0].function.name);

      let deserializedArguments: { [key: string]: any } = {};
      try {
        deserializedArguments = JSON.parse(
          lastMessage.toolCalls[0].function.arguments
        );
        lastMessage.content =
          lastMessage.content + String(deserializedArguments.message);
      } catch (e) {
        lastMessage.content = "";
      }

      if (toolName === "hangUp" || toolName === "transferToCustomerService") {
        setIsSessionEnded(true);
        return;
      }

      const toolResponse = await getToolResponse(
        toolName,
        deserializedArguments,
        lastUserMessage,
        language
      );
      await fetchChatCompletion(
        {
          id: uuidv4(),
          toolCallId: toolName,
          content: JSON.stringify(toolResponse),
          role: "tool",
        },
        true
      );
    }
  }

  let lastToolCallId = "";
  const serializeMessages = (messages: ChatMessage[]) => {
    const serializedMessages = messages.flatMap((message) => {
      const serializedMessage: OpenAI.Chat.Completions.ChatCompletionMessageParam[] =
        [];
      if (message.metadata?.functionResponse) {
        serializedMessage.push({
          role: "tool",
          content: message.metadata.functionResponse,
          tool_call_id:
            message.toolCalls?.[0].id ||
            message.toolCalls?.[0].function.name ||
            lastToolCallId,
        });
      }
      if (message.toolCalls) {
        lastToolCallId = message.toolCalls[0].id;
        serializedMessage.push({
          role: "assistant",
          tool_calls: [
            ...message.toolCalls.map(
              (toolCall) =>
                ({
                  id: toolCall.id,
                  type: toolCall.type,
                  function: {
                    name: toolCall.function.name,
                    arguments: toolCall.function.arguments,
                  },
                }) as OpenAI.Chat.Completions.ChatCompletionMessageToolCall
            ),
          ],
        });
      }
      if (message.content && message.toolCalls == null) {
        serializedMessage.push({
          role: message.role,
          content: message.content,
          tool_call_id: message.toolCallId,
        } as OpenAI.Chat.Completions.ChatCompletionMessageParam);
      }
      return serializedMessage;
    });
    return serializedMessages;
  };
  function copyRequest(excludeLast: boolean) {
    const serializedMessages = serializeMessages([
      {
        id: uuidv4(),
        content: get_healthcare_prompt(enforceVerification)[language],
        role: "system",
      },
      ...requestBody.messages,
    ]);
    const curlBody = {
      model: modelName,
      messages: serializedMessages.slice(
        0,
        excludeLast ? -1 : serializedMessages.length
      ),
      temperature: 0,
      max_tokens: 1024,
      tools,
    };
    setValue(JSON.stringify(curlBody));
    onCopy();
  }

  // eslint-disable-next-line complexity
  const fetchChatCompletion = async (
    newMessage: ChatMessage,
    isNested: boolean
  ) => {
    const updatedMessages = requestBody.messages;
    try {
      if (!isNested) {
        clear();
        setIsLoading(true);
      }

      if (newMessage.role !== "tool") {
        updatedMessages.push(newMessage);
        updatedMessages.push({
          id: uuidv4(),
          content: "",
          role: "assistant",
          metadata: {
            loading: true,
          },
        });
      } else {
        updatedMessages.push({
          id: uuidv4(),
          content: "",
          role: "assistant",
          metadata: {
            loading: true,
            functionResponse: newMessage.content,
          },
        });
      }
      setRequestBody({
        field: "messages",
        value: [...updatedMessages],
      });

      const stream = await openai.chat.completions.create({
        model: modelName,
        stream: true,
        messages: serializeMessages([
          {
            id: uuidv4(),
            content: get_healthcare_prompt(enforceVerification)[language],
            role: "system",
          },
          ...updatedMessages,
        ]),
        temperature: 0,
        tool_choice: "auto",
        // @ts-expect-error - TS doesn't know that the type conversion is safe.
        tools: tools,
        include_thinking: includeThinking,
        // num_cached_prefix_messages: 3,
        // messages: [{ role: "user", content: "Say this is a test" }],
      });

      for await (const chunk of stream) {
        if (chunk.choices[0].delta.content) {
          if (updatedMessages[updatedMessages.length - 1]?.metadata != null) {
            updatedMessages[updatedMessages.length - 1].metadata!.loading =
              false;
          }
          updatedMessages[updatedMessages.length - 1].content +=
            chunk.choices[0]?.delta?.content || "";
          setTrigger((prev) => prev + 1);
          setRequestBody({ field: "messages", value: [...updatedMessages] });
        }

        if (chunk.choices[0].delta.tool_calls) {
          await streamFunctionCall(updatedMessages, chunk);
        }
      }
      // Append function response and new assistant message.
      const lastMessage = updatedMessages[updatedMessages.length - 1];
      await maybeAppendFunctionResponse(
        lastMessage,
        updatedMessages[updatedMessages.length - 2].content
      );
    } catch (e) {
      addAlert({
        type: "error",
        body: (
          <Text>
            <b>Error happened when getting response: </b> {String(e)}
          </Text>
        ),
      });
      setRequestBody({ field: "messages", value: [...updatedMessages] });
    }

    if (!isNested) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setFunctionSpecs(JSON.stringify(tools, null, 2));
  }, []);
  const endOfMessagesRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    // Scroll to the bottom of the chat when the messages change, setTimeout is a trick to let browser render.
    setTimeout(scrollToBottom, 0);
  }, [requestBody]); // Dependency array includes messages, so effect runs when messages change

  return (
    <DashboardContentLayout
      subNavSection="Overview"
      mainTitle="Empower Functions Chat Demo"
      mainTitleHelperText={
        <>
          <Text color="gray.600" fontSize={{ base: "md", md: "sm" }} pt={2}>
            This chat app demonstrates the <b>empower-functions</b> model in
            real-world, complex usage scenarios. Read more about the model{" "}
            <Link
              href="https://www.empower.dev/article/launching-empower-functions---a-gpt4-level-function-calling-model-tailored-for-real-world-use-cases"
              target="_blank"
            >
              here
            </Link>
            . Note that none of the functions or conversations in the chat are
            included in the training data.
          </Text>
          <Flex justify="center" pt={6} display={{ base: "flex", md: "none" }}>
            <Button
              variant="cta"
              onClick={() => window.scrollTo(0, document.body.scrollHeight)}
            >
              Start Chatting
            </Button>
          </Flex>
        </>
      }
      sectionTitle="Details"
      sectionTitleHelperText={
        <Stack>
          <Text color="gray.600" fontSize={{ base: "md", md: "sm" }} pt={2}>
            The bot is instructed to be Alex, a coordinator at HealthBridge
            Medical Center, assisting patients in booking appointments. The full
            flow involves patient identity verification, department/doctor
            lookup, checking available times, and final confirmation. The bot
            needs to navigate the patient through the process and interact with
            the provided functions.{" "}
          </Text>
          <Text color="gray.600" fontSize={{ base: "md", md: "sm" }}>
            <b>
              Function response is coded to be random, so please feel free to
              ask for a arbitrary department or doctor.
            </b>{" "}
            Here are a few sample conversations:{" "}
            <Link
              onClick={() => {
                setRequestBody({
                  field: "messages",
                  value: sample1,
                });
                setIsSessionEnded(true);
                window.scrollTo(0, document.body.scrollHeight);
              }}
            >
              [1]
            </Link>{" "}
            <Link
              onClick={() => {
                setRequestBody({
                  field: "messages",
                  value: sample2,
                });
                setIsSessionEnded(true);
                window.scrollTo(0, document.body.scrollHeight);
              }}
            >
              [2]
            </Link>{" "}
            <Link
              onClick={() => {
                setRequestBody({
                  field: "messages",
                  value: sample3,
                });
                setIsSessionEnded(true);
                window.scrollTo(0, document.body.scrollHeight);
              }}
            >
              [3]
            </Link>
            . We show the <b>"Function call response"</b> and{" "}
            <b>"Function execution result"</b> for demo purpose. Detailed system
            prompts and function definitions can be seen{" "}
            <SystemPromptAndFunctionsPromptModal
              systemPrompt={
                get_healthcare_prompt(enforceVerification)[language]
              }
              functionSpecs={functionSpecs}
            >
              <Link>here</Link>
            </SystemPromptAndFunctionsPromptModal>
            .
          </Text>
        </Stack>
      }
    >
      <Stack spacing={2}>
        {/* <Flex gap={2} align="center">
          <Text fontSize="sm" flexWrap="nowrap" display="flex" w="120px">
            Choose a model:
          </Text>
          <SearchSelect
            className="max-w-xs"
            placeholder="Select a model"
            onValueChange={setModelName}
            value={modelName}
          >
            {officialLoras.map((data) => (
              <SearchSelectItem
                title={data}
                key={`lora-${data}`}
                value={data}
                className="cursor-pointer"
              >
                {data}
              </SearchSelectItem>
            ))}
          </SearchSelect>
        </Flex> */}
        <Flex gap={1} mb={2}>
          <Checkbox
            isChecked={includeThinking}
            onChange={(e) => setIncludeThinking(e.target.checked)}
          >
            <Text fontSize="sm">Enable Thinking Mode</Text>
          </Checkbox>
          <Text>
            (include the bot's thought process in the chat, see more details in
            this{" "}
            <Link
              href="https://docs.empower.dev/inference/tool-use/cot-mode"
              target="_blank"
            >
              doc
            </Link>
            )
          </Text>
        </Flex>
      </Stack>
      <>
        {user?.emailAddresses[0]?.emailAddress === "daiyi@dsensei.app" ||
        user?.emailAddresses[0]?.emailAddress === "yulong@dsensei.app" ? (
          <>
            <Text color="red">Admin only:</Text>
            <Checkbox
              isChecked={enforceVerification}
              onChange={(e) => setEnforceVerification(e.target.checked)}
            >
              <Text fontSize="sm">Enforce Verification</Text>
            </Checkbox>
            <Flex gap={2} align="center" pb={4}>
              <Link w={100} onClick={() => copyRequest(true)}>
                Copy as curl
              </Link>
              <Link w={75} onClick={() => copyRequest(false)}>
                Copy all
              </Link>
              {lorasResult?.data && baseModelsResult?.data && (
                <SearchSelect
                  className="max-content"
                  placeholder="Select a model"
                  onValueChange={setModelName}
                  value={modelName}
                >
                  {lorasResult?.data
                    .filter((data) => data.toolsEnabled)
                    .sort(
                      (data1, data2) =>
                        (data2.activeDeployment?.createdAt ?? 0) -
                        (data1.activeDeployment?.createdAt ?? 0)
                    )
                    .map((data) => (
                      <SearchSelectItem
                        title={data.name}
                        key={`lora-${data.name}`}
                        value={data.name}
                        className="cursor-pointer"
                      >
                        LoRA: {data.name}
                      </SearchSelectItem>
                    ))}
                  {baseModelsResult?.data
                    .filter((data) => data.toolsEnabled)
                    .sort(
                      (data1, data2) =>
                        (data2.activeDeployment?.createdAt ?? 0) -
                        (data1.activeDeployment?.createdAt ?? 0)
                    )
                    .map((data) => (
                      <SearchSelectItem
                        title={data.name}
                        key={`lora-${data.name}`}
                        value={data.name}
                        className="cursor-pointer"
                      >
                        LoRA: {data.name}
                      </SearchSelectItem>
                    ))}
                </SearchSelect>
              )}
            </Flex>
          </>
        ) : null}
        <Card height={{ base: "85vh", md: "65vh" }} p={5} variant="information">
          <ChatMessages
            messages={requestBody.messages}
            isLoading={isLoading}
            trigger={trigger}
          />
          <Stack>
            <ChatInput
              selectedModelName={modelName}
              isVlmModel={false}
              onSubmit={async (text) => {
                amplitude.track("Send Chat Message");
                await fetchChatCompletion(
                  {
                    id: uuidv4(),
                    content: text,
                    role: "user",
                  },
                  false
                );
              }}
              multiModal={false}
              isLoading={isLoading}
              isSessionEnded={isSessionEnded}
              onReset={() => {
                setRequestBody({
                  field: "messages",
                  value: [
                    {
                      id: uuidv4(),
                      content: HEALTHCARE_FIRST_MESSAGE[language],
                      role: "assistant",
                    },
                  ],
                });
                setIsSessionEnded(false);
              }}
            />
            <Flex px={1} gap={2} align="center" flexWrap="wrap">
              {!userId && (
                <>
                  <Text>
                    Want to use in API?{" "}
                    <Link href="/sign-up">Sign-up here</Link>
                  </Text>
                  <Text display={{ base: "none", md: "flex" }}> | </Text>
                </>
              )}
              <SystemPromptAndFunctionsPromptModal
                systemPrompt={
                  get_healthcare_prompt(enforceVerification)[language]
                }
                functionSpecs={functionSpecs}
              >
                <Link display={{ base: "none", md: "flex" }}>
                  Full system prompt and function definitions
                </Link>
              </SystemPromptAndFunctionsPromptModal>
              <Text display={{ base: "none", md: "flex" }}> | </Text>
              <Text>Sample conversations: </Text>
              <Select
                maxW={{ base: "180px", md: "fit" }}
                size="sm"
                onChange={(value) => {
                  let sample;
                  switch (value.target.value) {
                    case "1":
                      sample = sample1;
                      break;
                    case "2":
                      sample = sample2;
                      break;
                    case "3":
                      sample = sample3;
                      break;
                    default:
                      break;
                  }

                  if (sample != null) {
                    setRequestBody({
                      field: "messages",
                      value: sample,
                    });
                    setIsSessionEnded(true);
                  }
                }}
              >
                <option value="0" key="0">
                  Select conversation
                </option>
                <option value="1" key="1">
                  Visit a doctor
                </option>
                <option value="2" key="2">
                  Visit a department
                </option>
                <option value="3" key="3">
                  Transfer to customer service
                </option>
              </Select>
              <Text display={{ base: "none", md: "flex" }}> | </Text>
              <Text>Prompt language: </Text>
              <Select
                maxW={{ base: "180px", md: "fit" }}
                size="sm"
                onChange={(value) => {
                  setLanguage(value.target.value);
                  setRequestBody({
                    field: "messages",
                    value: [
                      {
                        id: uuidv4(),
                        content: HEALTHCARE_FIRST_MESSAGE[value.target.value],
                        role: "assistant",
                      },
                    ],
                  });
                  setIsSessionEnded(false);
                }}
              >
                <option value="english" key="english">
                  English
                </option>
                <option value="chinese" key="chinese">
                  中文
                </option>
              </Select>
            </Flex>
          </Stack>
        </Card>
      </>
    </DashboardContentLayout>
  );
}
