import { ReactElement, createContext, useContext, useState } from "react";

export interface GlobalAlertState {
  alerts: GlobalAlert[];
}
export interface GlobalAlert {
  type: "warning" | "error" | "success" | "info";
  body: ReactElement;
}

const GlobalAlertContext = createContext<
  | {
      globalAlertState: GlobalAlertState;
      setGlobalAlertState: (state: GlobalAlertState) => void;
    }
  | undefined
>(undefined);

export function useGlobalAlert() {
  const globalAlertContext = useContext(GlobalAlertContext);

  if (!globalAlertContext) {
    throw new Error("Cannot use global alert outside GlobalStateProvider.");
  }

  const { globalAlertState, setGlobalAlertState } = globalAlertContext;

  return {
    addAlert: (alert: GlobalAlert) => {
      const alerts = globalAlertState.alerts;
      alerts.push(alert);
      setGlobalAlertState({ alerts });

      setTimeout(() => {
        const alerts = globalAlertState.alerts;
        alerts.shift();
        setGlobalAlertState({ alerts });
      }, 5000);
    },
    currentAlert: globalAlertState.alerts[globalAlertState.alerts.length - 1],
    clear: () => {
      setGlobalAlertState({ alerts: [] });
    },
  };
}

export interface GlobalAlertContextProviderProps {
  children: React.ReactNode;
}

export function GlobalAlertProvider({
  children,
}: GlobalAlertContextProviderProps) {
  const [globalAlertState, setGlobalAlertState] = useState<GlobalAlertState>({
    alerts: [],
  });

  return (
    <GlobalAlertContext.Provider
      value={{ globalAlertState, setGlobalAlertState }}
    >
      {children}
    </GlobalAlertContext.Provider>
  );
}
