import { useAuth } from "@clerk/clerk-react";
import { createContext, useContext, useState } from "react";
import {
  ProductPlanResponse,
  ProductPlansResponse,
} from "../../interfaces/billing";
import { getApi } from "../api/api-service";

export interface BillingState {
  hasPayment: boolean;
  currentPlan?: ProductPlanResponse;
  creditsLeft: number;
}

const BillingContext = createContext<
  | {
      billingState: BillingState;
      setBillingState: (state: BillingState) => void;
    }
  | undefined
>(undefined);

export function useBilling() {
  const billingContext = useContext(BillingContext);

  if (!billingContext) {
    throw new Error("Cannot use billing outside BillingStateProvider.");
  }

  const { billingState, setBillingState } = billingContext;

  return {
    hasPayment: billingState.hasPayment,
    currentPlan: billingState.currentPlan,
    creditsLeft: billingState.creditsLeft,
    setHasPayment: (hasPayment: boolean) => {
      setBillingState({ ...billingState, hasPayment });
    },
  };
}

export interface BillingContextProviderProps {
  children: React.ReactNode;
}

export function BillingContextProvider({
  children,
}: BillingContextProviderProps) {
  const [billingState, setBillingState] = useState<BillingState>({
    hasPayment: true,
    creditsLeft: 0,
  });
  const { isSignedIn } = useAuth();

  if (isSignedIn) {
    getApi<ProductPlansResponse>("/billing/plan", {}, "v1", async (data) => {
      setBillingState({
        hasPayment: data.hasPaymentMethod,
        currentPlan: data.currentPlan,
        creditsLeft: data.creditsLeft,
      });
    });
  }

  return (
    <BillingContext.Provider value={{ billingState, setBillingState }}>
      {children}
    </BillingContext.Provider>
  );
}
