import {
  Flex,
  List,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useParams } from "react-router-dom";
import {
  EvaluationDataRow,
  FunctionTool,
  Message,
} from "../../interfaces/evaluation";
import { getApi } from "../../services/api/api-service";
import { DataTable } from "../data-table";
import LoadingIndicator from "../loading-indicator";

interface Response {
  result: EvaluationDataRow[];
}
interface EvaluationDataRowGroup {
  first: {
    rowNumber: number;
    row: EvaluationDataRow;
  };
  second?: {
    rowNumber: number;
    row: EvaluationDataRow;
  };
}

function formatTools(input: FunctionTool[]) {
  return (
    <Flex
      // w="400px" // or a specific width like "300px"
      h="300px" // height of the scrollable area
      overflowY="scroll" // enables vertical scrolling
      p={4} // padding inside the box
      flex={1} // take up all available space
      flexDir="column-reverse"
    >
      <OrderedList>
        {input.map((func) => (
          <ListItem>
            <Text color={"blue"}>
              <b>{func.name}</b>
            </Text>
            <Text>
              <b>Description: </b>
              {func.description}
            </Text>
            <Text>
              <b>Parameters:</b>
              {func.parameters && func.parameters["properties"] && (
                <UnorderedList>
                  {Object.entries(func.parameters["properties"]).map(
                    (entry) => {
                      const [key, value] = entry;
                      return (
                        <ListItem>
                          <Text>
                            <b>{key}</b>: {JSON.stringify(value)}
                          </Text>
                        </ListItem>
                      );
                    }
                  )}
                </UnorderedList>
              )}
            </Text>
          </ListItem>
        ))}
      </OrderedList>
    </Flex>
  );
}

function renderMessageBody(message: Message) {
  console.log(message);
  if (message.content) {
    return (
      <Text>
        <b>Text:</b> {message.content}
      </Text>
    );
  } else if (message.function_call) {
    return (
      <List>
        <ListItem>
          <Text>
            <b>Function:</b> {message.function_call.name}
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>Arguments:</b> {message.function_call.arguments}
          </Text>
        </ListItem>
      </List>
    );
  } else if (message.tool_calls) {
    return message.tool_calls.map((toolCall) => (
      <List>
        <ListItem>
          <Text>
            <b>Function:</b> {toolCall.function.name}
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>Arguments:</b> {toolCall.function.arguments}
          </Text>
        </ListItem>
      </List>
    ));
  }
}

function formatInput(input: Message[]) {
  return (
    <Flex
      // w="400px" // or a specific width like "300px"
      h="400px" // height of the scrollable area
      overflowY="scroll" // enables vertical scrolling
      p={4} // padding inside the box
      flex={1} // take up all available space
      flexDir="column-reverse"
    >
      <List>
        {input.map((message) => (
          <ListItem>
            <Text color={"blue"}>
              <b>{message.role}</b>
            </Text>
            {renderMessageBody(message)}
          </ListItem>
        ))}
      </List>
    </Flex>
  );
}

export default function DatasetViewer() {
  const { fileId } = useParams();
  if (!fileId) {
    return <>File does not exist.</>;
  }

  const { data } = getApi<Response>(
    "/dataset",
    { file_id: decodeURIComponent(fileId) },
    "v1",
    async () => {},
    async () => {},
    true
  );

  if (!data) {
    return <LoadingIndicator />;
  }

  const columnHelper = createColumnHelper<EvaluationDataRowGroup>();

  const columns = [
    columnHelper.accessor("first", {
      header: "Row #",
      id: "first_row_number",
      enableSorting: false,
      cell: (info) => <b>{info.getValue().rowNumber}</b>,
    }),
    columnHelper.accessor("first", {
      header: "Tools",
      id: "first_tools",
      enableSorting: false,
      cell: (info) => formatTools(info.getValue().row.functions || []),
    }),
    columnHelper.accessor("first", {
      header: "Input",
      id: "first_input",
      enableSorting: false,
      cell: (info) => formatInput(info.getValue().row.messages),
    }),
    columnHelper.accessor("second", {
      header: "Row #",
      id: "second_row_number",
      enableSorting: false,
      cell: (info) =>
        info.getValue() ? <b>{info.getValue()!.rowNumber}</b> : <></>,
    }),
    columnHelper.accessor("second", {
      header: "Tools",
      id: "second_tools",
      enableSorting: false,
      cell: (info) =>
        info.getValue() ? (
          formatTools(info.getValue()!.row.functions || [])
        ) : (
          <></>
        ),
    }),
    columnHelper.accessor("second", {
      header: "Input",
      id: "second_input",
      enableSorting: false,
      cell: (info) =>
        info.getValue() ? formatInput(info.getValue()!.row.messages) : <></>,
    }),
  ];

  return (
    <DataTable
      columns={columns}
      data={data.result.reduce((acc, curr, index) => {
        if (index % 2 === 0) {
          acc.push({
            first: {
              rowNumber: index + 1,
              row: curr,
            },
          });
        } else {
          acc[acc.length - 1].second = {
            rowNumber: index + 1,
            row: curr,
          };
        }
        return acc;
      }, [] as EvaluationDataRowGroup[])}
      isLoading={false}
    />
  );
}
