import { AbsoluteCenter, Link } from "@chakra-ui/react";
import {
  SignedIn,
  SignedOut,
  useAuth,
  useOrganization,
  useUser,
} from "@clerk/clerk-react";
import React, { createContext, useContext, useEffect, useState } from "react";
import LoadingIndicator from "../../components/loading-indicator";
import { retryWithExponentialBackoff } from "../../utils";
import ApiClient from "./api-service";

type ApiClientContextValue = {
  apiClient: ApiClient;
};

const ApiClientContext = createContext<ApiClientContextValue | undefined>(
  undefined
);

export function useApiClient(): ApiClient {
  const apiClientContext = useContext(ApiClientContext);

  if (!apiClientContext) {
    throw new Error("Cannot use Api client context outside ApiClientProvider.");
  }
  return apiClientContext.apiClient;
}

export interface ApiClientContextProviderProps {
  baseUrl: string;
  children: React.ReactNode;
}

export function ApiClientProvider({
  baseUrl,
  children,
}: ApiClientContextProviderProps) {
  const { getToken, isSignedIn } = useAuth();
  const { user } = useUser();
  const { organization } = useOrganization();

  const [isReady, setIsReady] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  function renderSignedInContent() {
    if (hasError) {
      return (
        <AbsoluteCenter>
          Unexpected error happened, please retry and contact{" "}
          <Link href="mailto:founders@empower.dev">founders@empower.dev</Link>{" "}
          if the issue persists.
        </AbsoluteCenter>
      );
    } else if (isReady) {
      return (
        <ApiClientContext.Provider
          value={{
            apiClient: new ApiClient(async () => {
              return await getToken({
                template: "default",
              });
            }, baseUrl),
          }}
        >
          {children}
        </ApiClientContext.Provider>
      );
    } else {
      return <LoadingIndicator />;
    }
  }

  useEffect(() => {
    if (isSignedIn) {
      // eslint-disable-next-line no-inner-declarations
      async function run() {
        const apiClient = new ApiClient(async () => {
          return await getToken({
            template: "default",
          });
        }, baseUrl);

        try {
          await retryWithExponentialBackoff(async () => {
            await apiClient.get("/v1/lora", {}, async () => {
              setIsReady(true);
            });
          });
        } catch (e) {
          setHasError(true);
        }
      }

      const now = new Date().getTime();
      const userCreatedAt = user?.createdAt?.getTime() ?? now;
      const orgCreatedAt = organization?.createdAt?.getTime() ?? now;
      if (
        organization &&
        (now - userCreatedAt < 60 * 1000 || now - orgCreatedAt < 60 * 1000)
      ) {
        run();
      } else {
        setIsReady(true);
      }
    }
  }, [isSignedIn, user, organization]);

  return (
    <>
      <SignedIn>{renderSignedInContent()}</SignedIn>
      <SignedOut>{children}</SignedOut>
    </>
  );
}

export default ApiClientProvider;
