"use client";

import React, { useRef } from "react";
import { useInView } from "react-intersection-observer";
// import { useAtBottom } from '~/hooks/use-at-bottom';

interface ChatScrollAnchorProps {
  trackVisibility?: boolean;
  trigger: number;
}

export function ChatScrollAnchor({
  trackVisibility,
  trigger,
}: ChatScrollAnchorProps) {
  const isAtBottom = true;
  const { ref, entry, inView } = useInView({
    trackVisibility,
    delay: 100,
    rootMargin: "0px 0px -150px 0px",
  });

  const prevTrackVisibility = useRef(trackVisibility);

  React.useEffect(() => {
    if (
      isAtBottom &&
      (trackVisibility || trackVisibility !== prevTrackVisibility.current) &&
      !inView
    ) {
      entry?.target.scrollIntoView({
        block: "start",
      });

      prevTrackVisibility.current = trackVisibility;
    }
  }, [inView, trackVisibility, entry, isAtBottom, trigger]);

  return <div ref={ref} className="h-px w-full" />;
}
