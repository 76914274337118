import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MultiSelect, MultiSelectItem } from "@tremor/react";
import React, { ReactElement } from "react";
import { Controller, useForm } from "react-hook-form";
import { GrDeploy } from "react-icons/gr";
import { BaseModelResponse, LoRAResponse } from "../../interfaces/model";
import { getApi } from "../../services/api/api-service";
import LoadingIndicator from "../loading-indicator";

interface NewEvaluationModalProps {
  children: ReactElement<{
    onClick: () => void;
  }>;
}

interface RunNewEvaluationRequestInput {
  modelNames: string[];
  datasetName: string;
  compareFunctionNameOnly: boolean;
  checkParameterInExample: boolean;
  isVlm: boolean;
}

export default function NewEvaluationModal({
  children,
}: NewEvaluationModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, register, formState, control } =
    useForm<RunNewEvaluationRequestInput>({
      mode: "all",
    });
  const lorasResult = getApi<LoRAResponse[]>("/lora");
  const baseModelsResult = getApi<BaseModelResponse[]>("/base-model");

  if (!lorasResult.data || !baseModelsResult.data) {
    return <LoadingIndicator />;
  }

  async function onSubmit(input: RunNewEvaluationRequestInput) {
    window.open(
      `/evaluation-result?datasetName=${input.datasetName}&modelNames=${input.modelNames.join(",")}&compareFunctionNameOnly=${input.compareFunctionNameOnly}&checkParameterInExample=${input.checkParameterInExample}&isVlm=${input.isVlm}`,
      "_blank"
    );
    onClose();
  }

  return (
    <>
      {React.cloneElement(children, {
        onClick: onOpen,
      })}
      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="800px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>
              Run a new evaluation
              <Text fontSize="sm" color="gray.500">
                TBD
              </Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl isInvalid={Boolean(formState.errors.root)}>
                <FormErrorMessage>
                  Error: {formState.errors.root?.message}
                </FormErrorMessage>
              </FormControl>
              <Flex direction="column" className="gap-y-3" pt={5}>
                <FormControl>
                  <FormLabel>
                    <Text>Select the evaluation dataset to run:</Text>
                  </FormLabel>
                  <Select
                    id="datasetName"
                    {...register("datasetName", {
                      required: "Dataset is required",
                    })}
                  >
                    <option value="single_turn_full.jsonl">
                      Single Turn Full
                    </option>
                    <option value="single_turn_small.jsonl">
                      Single Turn Small
                    </option>
                    <option value="healthcare.jsonl">Healthcare Full</option>
                    <option value="healthcare_with_message.jsonl">
                      Healthcare with Message
                    </option>
                    <option value="healthcare_small.jsonl">
                      Healthcare Small
                    </option>
                    <option value="healthcare_greetings.jsonl">
                      Healthcare Greetings
                    </option>
                    <option value="function_used_not_in_training_eval.jsonl">
                      Training Data
                    </option>
                    <option value="function_used_not_in_training_eval_small.jsonl">
                      Training Data Small
                    </option>
                    <option value="rlhf_full_training.jsonl">
                      RLHF Full Training Data
                    </option>
                    <option value="rlhf_full_eval.jsonl">
                      RLHF Full Eval Data
                    </option>
                    <option value="rlhf_full_new_eval.jsonl">
                      RLHF New Full Training Data
                    </option>
                    <option value="rlhf_manual_training.jsonl">
                      RLHF Manual Training Data
                    </option>
                    <option value="parallel_call.jsonl">Parallel Call</option>
                    <option value="bd_multi_turn_with_function_response.jsonl">
                      BD Multi-turn with Function Response
                    </option>
                    <option value="bd_multi_turn.jsonl">BD Multi-turn</option>
                    <option value="bd_single_turn.jsonl">BD Single-turn</option>
                    <option value="bd_full.jsonl">BD Full</option>
                    <option value="consecutive_fixed_formatted_eval.jsonl">
                      Consecutive
                    </option>
                    <option value="pablo_small.jsonl">Pablo Small</option>
                    <option value="pablo.jsonl">Pablo Latest</option>
                    <option value="vlm/skyvern/samples_small.jsonl">
                      Skyvern Small
                    </option>
                    <option value="vlm/skyvern/samples.jsonl">Skyvern</option>
                    <option value="vlm/skyvern/skyvern_test_small.jsonl">
                      Skyvern Small New
                    </option>
                    <option value="vlm/skyvern/samples.jsonl">Skyvern</option>
                    <option value="vlm/skyvern/skyvern_test_gpt4o_small.jsonl">
                      Skyvern GPT-4o small
                    </option>
                    <option value="vlm/skyvern/experiment-eval/failure.jsonl">
                      Skyvern failures
                    </option>
                    <option value="vlm/skyvern/experiment-eval/success.jsonl">
                      Skyvern successes
                    </option>
                    <option value="vlm/skyvern/skyvern_test_0815.jsonl">
                      Skyvern 0815
                    </option>
                    <option value="vlm/skyvern/skyvern_test_0815_small.jsonl">
                      Skyvern 0815 Small
                    </option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>
                    <Flex align="center" className="gap-1">
                      <Text>Select models to evaluate with the dataset:</Text>
                    </Flex>
                    <Controller
                      render={({ field }) => (
                        <MultiSelect
                          placeholder="Select models to evaluate"
                          {...field}
                          onValueChange={(value) => {
                            field.onChange(value);
                          }}
                        >
                          {[
                            "gpt-4",
                            "gpt-4o",
                            "gpt-4o-mini",
                            "gpt-3.5",
                            "fireworks",
                            "anyscale",
                            "together-ai",
                          ].map((data, idx) => (
                            <MultiSelectItem
                              title={data}
                              key={`${data}-${idx}`}
                              value={data}
                            >
                              Model: {data}
                            </MultiSelectItem>
                          ))}
                          {lorasResult
                            .data!.sort(
                              (a, b) =>
                                (b.activeDeployment?.createdAt ?? 0) -
                                (a.activeDeployment?.createdAt ?? 0)
                            )
                            .map((data, idx) => (
                              <MultiSelectItem
                                title={data.name}
                                key={`${data.name}-${idx}`}
                                value={data.name}
                              >
                                LoRA: {data.name}
                              </MultiSelectItem>
                            ))}
                          {baseModelsResult.data!.map((data, idx) => (
                            <MultiSelectItem
                              title={data.name}
                              key={`${data.name}-${idx}`}
                              value={data.name}
                            >
                              Base Model: {data.name}
                            </MultiSelectItem>
                          ))}
                        </MultiSelect>
                      )}
                      name="modelNames"
                      rules={{ validate: (value) => value.length > 0 }}
                      control={control}
                    />
                  </FormLabel>
                </FormControl>
                <FormControl>
                  <FormLabel>
                    <Text>Function name only mode:</Text>
                  </FormLabel>
                  <Checkbox {...register("compareFunctionNameOnly")}>
                    Whether to compare only the function name (skip the check of
                    arguments)
                  </Checkbox>
                  <Checkbox {...register("checkParameterInExample")}>
                    Whether to check the function parameter is in the example
                  </Checkbox>
                  <p></p>
                  <Checkbox {...register("isVlm")}>
                    Whether is this model a VLM model?
                  </Checkbox>
                </FormControl>
              </Flex>
            </ModalBody>

            <ModalFooter className="gap-1">
              <Button
                variant="cta"
                leftIcon={<GrDeploy />}
                type="submit"
                loadingText="Submitting"
                isDisabled={!formState.isValid}
              >
                Run
              </Button>
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}
