import { Box, Heading, Text } from "@chakra-ui/react";
import { ReactElement } from "react";

export interface PageTitleProps {
  mainTitle: string;
  helperText?: string | ReactElement;
}
export function PageTitle({ mainTitle, helperText }: PageTitleProps) {
  function renderHelperText() {
    if (!helperText) {
      return <></>;
    }

    if (typeof helperText === "string") {
      return (
        <Text color="gray.600" fontSize="sm" pt={2}>
          {helperText}
        </Text>
      );
    }

    return helperText;
  }
  return (
    <Box>
      <Heading as="h1" size={"lg"} fontWeight="500">
        {mainTitle}
      </Heading>
      {renderHelperText()}
    </Box>
  );
}
