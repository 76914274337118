import { ApiKeyResponse } from "./api-key";
import { UserResponse } from "./user";

export enum ActorType {
  USER = "USER",
  API_KEY = "API_KEY",
  OFFICIAL = "OFFICIAL",
}

export interface ActorResponse {
  actorType: ActorType;
  user?: UserResponse;
  apiKey?: ApiKeyResponse;
}
