import { AbsoluteCenter, Link } from "@chakra-ui/react";

export function NotFoundPage() {
  return (
    <AbsoluteCenter>
      404 | The page requested does not exist, click{" "}
      <Link href="https://app.empower.dev">here</Link> to get back to the main
      app.
    </AbsoluteCenter>
  );
}
