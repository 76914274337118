import { Link } from "@chakra-ui/react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ModelType } from "../interfaces/model";

import ModelDetails from "../components/model/model-details";

export default function LoRADetailsPage() {
  const { loraName } = useParams();

  const navigate = useNavigate();
  if (!loraName) {
    navigate("/");
    return <></>;
  }

  return (
    <ModelDetails
      modelName={loraName}
      modelType={ModelType.LORA}
      mainTitle="LoRAs"
      mainTitleHelperText={
        <>
          Low rank adapter for Large Language Models (LLMs). LoRAs needs to be
          trained based on of of the{" "}
          <Link as={NavLink} to="/base-model">
            base models
          </Link>{" "}
          and can be queried directly.
        </>
      }
    />
  );
}
