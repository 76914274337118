import {
  Flex,
  NumberInput,
  NumberInputField,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from "@chakra-ui/react";

export interface SliderInputProps {
  label: string;
  min: number;
  max: number;
  value: number;
  setValue: (value: number) => void;
  step?: number;
}

export default function SliderInput(props: SliderInputProps) {
  return (
    <>
      <Flex align="center" justify="space-between">
        <Text>{props.label}</Text>
        <NumberInput
          w="fit-content"
          size="sm"
          width="100px"
          value={props.value}
          max={props.max}
          min={props.min}
          onChange={(_, value) => props.setValue(value)}
        >
          <NumberInputField />
        </NumberInput>
      </Flex>
      <Slider
        max={props.max}
        min={props.min}
        value={props.value}
        onChange={props.setValue}
        step={props.step ?? 1}
      >
        <SliderTrack bg="highlightBg">
          <SliderFilledTrack bg="action" />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </>
  );
}
