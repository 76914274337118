import { Divider, Stack } from "@chakra-ui/react";
import MonthlySpentOverview from "../components/monthly-spent-overview";
import { SectionTitle } from "../components/section-title";
import UsageStats from "../components/usage-stats/usage-stats";
import DashboardContentLayout from "../layouts/dashboard-content-layout";

export function BillingUsageStatsPage() {
  return (
    <DashboardContentLayout
      rootNavSection="Billing"
      subNavSection="Usage Stats"
      mainTitle="Usage Stats"
      mainTitleHelperText="View cost summary and detailed usage activity stats."
      sectionTitle="Cost"
      sectionTitleHelperText="Cost summary of the current billing cycle."
    >
      <Stack spacing={5}>
        <MonthlySpentOverview />
        <Divider />
        <SectionTitle
          mainTitle="Activity"
          helperText="Detailed usage activity stats."
        />{" "}
        <UsageStats />
      </Stack>
    </DashboardContentLayout>
  );
}
