import DatasetUploader from "../components/dataset/dataset-uploader";
import DashboardContentLayout from "../layouts/dashboard-content-layout";

export function UploadDatasetPage() {
  return (
    <DashboardContentLayout
      subNavSection="Overview"
      mainTitle="Dataset Viewer"
      mainTitleHelperText="View and manage datasets."
      sectionTitle=""
      sectionTitleHelperText=""
    >
      <DatasetUploader />
    </DashboardContentLayout>
  );
}
