import { ChakraProvider } from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./index.css";

// Import the layouts
import DashboardLayout from "./layouts/dashboard-layout";
import RootLayout from "./layouts/root-layout";

// Import the components
import * as amplitude from "@amplitude/analytics-browser";
import { ClerkLoaded, ClerkProvider } from "@clerk/clerk-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SidenavProvider } from "./components/sidenav";
import IndexPage from "./pages";
import ApiKeysPage from "./pages/api-keys-page";
import BaseModelDetailsPage from "./pages/base-model-details-page";
import BaseModelsPage from "./pages/base-models-page";
import BillingSettingsPage from "./pages/billing-settings-page";
import { BillingUsageStatsPage } from "./pages/billing-usage-stats-page";
import ChatPage from "./pages/chat-page";
import DatasetDetailPage from "./pages/dataset-detail-page";
import { DatasetViewerPage } from "./pages/dataset-viewer-page";
import { EvaluationResultPage } from "./pages/evaluation-result-page";
import { EvaluationsPage } from "./pages/evaluations-page";
import LoRADetailsPage from "./pages/lora-details-page";
import LoRAsPage from "./pages/loras-page";
import { NotFoundPage } from "./pages/not-found-page";
import PlaygroundPage from "./pages/playground-page";
import SelectOrgPage from "./pages/select-org-page";
import SignInPage from "./pages/sign-in-page";
import SignUpPage from "./pages/sign-up-page";
import TasksPage from "./pages/tasks-page";
import TrainingJobDetailsPage from "./pages/training-job-details-page";
import { UploadDatasetPage } from "./pages/upload-dataset-page";
import ApiClientProvider from "./services/api/api-client-context";
import { BillingContextProvider } from "./services/billing/billing-context";
import { GlobalAlertProvider } from "./services/global-alert/global-alert-context";
import { theme } from "./theme";
import { getBaseApiUrl } from "./utils";

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;
const STRIPE_KEY = import.meta.env.VITE_STRIPE_PK;
const stripePromise = loadStripe(STRIPE_KEY);

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

if (import.meta.env.MODE !== "development") {
  amplitude.init("8b60e0b78bb6dec7366f7e5371053416");
}

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      { path: "*", element: <NotFoundPage /> },
      { path: "/", element: <IndexPage /> },
      { path: "/sign-in", element: <SignInPage /> },
      { path: "/sign-up", element: <SignUpPage /> },
      { path: "/select-org", element: <SelectOrgPage /> },
      { path: "/chat-demo", element: <ChatPage /> },
      { path: "/playground", element: <PlaygroundPage /> },
      {
        element: <DashboardLayout />,
        path: "",
        children: [
          { path: "/lora", element: <LoRAsPage /> },
          { path: "/lora/:loraName", element: <LoRADetailsPage /> },
          { path: "/api-key", element: <ApiKeysPage /> },
          { path: "/base-model", element: <BaseModelsPage /> },
          {
            path: "/billing/settings",
            element: <BillingSettingsPage />,
          },
          {
            path: "/billing/usage-stats",
            element: <BillingUsageStatsPage />,
          },
          {
            path: "/base-model/:baseModelName",
            element: <BaseModelDetailsPage />,
          },
          {
            path: "/evaluation-result",
            element: <EvaluationResultPage />,
          },
          {
            path: "/evaluation",
            element: <EvaluationsPage />,
          },
          {
            path: "/dataset-viewer/:fileId",
            element: <DatasetViewerPage />,
          },
          {
            path: "/upload-dataset",
            element: <UploadDatasetPage />,
          },
          {
            path: "/dataset",
            element: <DatasetDetailPage />,
          },
          {
            path: "/tasks",
            element: <TasksPage />,
          },
          {
            path: "training-jobs",
            element: <TrainingJobDetailsPage />,
          },
        ],
      },
    ],
  },
]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60, // 1 hour in ms
      refetchOnWindowFocus: false, // Disables automatic refetching when browser window is focused.
    },
  },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ClerkProvider
      publishableKey={PUBLISHABLE_KEY}
      localization={{
        signIn: {
          start: {
            title: "Welcome to Empower",
            subtitle: "Sign in to get started",
          },
        },
        signUp: {
          start: {
            title: "Welcome to Empower",
            subtitle: "Sign up an account to get started",
          },
        },
      }}
      appearance={{
        variables: {
          colorPrimary: "#596aff",
        },
        elements: {
          logoImage: {
            height: "45px",
          },
          headerTitle: {
            display: "flex",
            justifyContent: "center",
          },
          headerSubtitle: {
            display: "flex",
            justifyContent: "center",
          },
          card: {
            borderColor: "#CBD5E0",
            borderRadius: "0.5em",
          },
          logoBox: {
            justifyContent: "center",
          },
          formFieldInput: {
            borderColor: "#596aff",
            borderRadius: "2px",
            backgroundColor: "red",
          },
        },
      }}
    >
      <ClerkLoaded>
        <ApiClientProvider baseUrl={`${getBaseApiUrl()}`}>
          <GlobalAlertProvider>
            <ChakraProvider theme={theme}>
              <QueryClientProvider client={queryClient}>
                <BillingContextProvider>
                  <Elements stripe={stripePromise}>
                    <SidenavProvider>
                      <RouterProvider router={router} />
                    </SidenavProvider>
                  </Elements>
                </BillingContextProvider>
              </QueryClientProvider>
            </ChakraProvider>
          </GlobalAlertProvider>
        </ApiClientProvider>
      </ClerkLoaded>
    </ClerkProvider>
  </React.StrictMode>
);
