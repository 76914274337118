import { Box, Heading, Text } from "@chakra-ui/react";
import { ReactElement } from "react";

export interface SectionTitleProps {
  mainTitle: ReactElement | string;
  helperText?: ReactElement | string;
}

export function SectionTitle({ mainTitle, helperText }: SectionTitleProps) {
  function renderHelperText() {
    if (!helperText) {
      return <></>;
    }

    if (typeof helperText === "string") {
      return (
        <Text color="gray.600" fontSize="sm" pt={2}>
          {helperText}
        </Text>
      );
    }

    return helperText;
  }

  function renderMainTitle() {
    if (typeof mainTitle === "string") {
      return (
        <Heading as="h2" size={"md"} fontWeight="500">
          {mainTitle}
        </Heading>
      );
    }

    return mainTitle;
  }

  return (
    <Box>
      {renderMainTitle()}
      {renderHelperText()}
    </Box>
  );
}
