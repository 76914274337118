import { AbsoluteCenter } from "@chakra-ui/react";
import { OrganizationList, useOrganizationList } from "@clerk/clerk-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingIndicator from "../components/loading-indicator";
import { AFTER_SIGN_IN_REDIRECTION_TARGET } from "../utils";

export default function SelectOrgPage() {
  const { isLoaded, setActive, userMemberships } = useOrganizationList({
    userMemberships: {
      infinite: true,
    },
  });
  const navigate = useNavigate();

  useEffect(() => {
    async function switchOrg(org: { id: string }) {
      await setActive!({ organization: org.id });
      navigate(AFTER_SIGN_IN_REDIRECTION_TARGET);
    }

    if (userMemberships.count === 1 && isLoaded) {
      switchOrg(userMemberships.data[0]);
    }
  }, [isLoaded, userMemberships, navigate, setActive]);

  if (!isLoaded || userMemberships.isLoading || userMemberships.count === 1) {
    return <LoadingIndicator />;
  }

  return (
    <AbsoluteCenter>
      <OrganizationList
        hidePersonal
        afterCreateOrganizationUrl={AFTER_SIGN_IN_REDIRECTION_TARGET}
        afterSelectOrganizationUrl={AFTER_SIGN_IN_REDIRECTION_TARGET}
      />
    </AbsoluteCenter>
  );
}
