import {
  Badge,
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Link,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { LineChart } from "@tremor/react";
import * as yaml from "js-yaml";
import { FaRegUser } from "react-icons/fa";
import { LuLayers } from "react-icons/lu";
import {
  MdAvTimer,
  MdDriveFileRenameOutline,
  MdOutlineDashboardCustomize,
} from "react-icons/md";
import { PiExam } from "react-icons/pi";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { vs } from "react-syntax-highlighter/dist/esm/styles/hljs";
import DashboardContentLayout from "../layouts/dashboard-content-layout";

const stats = [
  {
    "Training Loss": 0.13940972505953714,
    "Evaluation Loss": 0.13940972505953714,
    "Learning Rate": 2.702702702702703e-6,
    Epoch: 0.0,
  },
  {
    "Training Loss": 0.13182,
    "Learning Rate": 1.891891891891892e-5,
    Epoch: 0.01,
  },
  {
    "Training Loss": 0.12982,
    "Learning Rate": 5.135135135135135e-5,
    Epoch: 0.02,
  },
  {
    "Training Loss": 0.12282,
    "Learning Rate": 8.378378378378379e-5,
    Epoch: 0.03,
  },
  {
    "Training Loss": 0.11882,
    "Learning Rate": 0.00011621621621621621,
    Epoch: 0.04,
  },
  {
    "Training Loss": 0.12282,
    "Learning Rate": 0.00014864864864864866,
    Epoch: 0.05,
  },
  {
    "Training Loss": 0.10732,
    "Learning Rate": 0.0001837837837837838,
    Epoch: 0.06,
  },
  {
    "Training Loss": 0.09426999999999999,
    "Learning Rate": 0.00019999683184397752,
    Epoch: 0.07,
  },
  {
    "Training Loss": 0.08884,
    "Learning Rate": 0.00019997148780025027,
    Epoch: 0.08,
  },
  {
    "Training Loss": 0.08724,
    "Learning Rate": 0.00019992080613620485,
    Epoch: 0.09,
  },
  {
    "Training Loss": 0.08543999999999999,
    "Learning Rate": 0.00019984479969703127,
    Epoch: 0.1,
  },
  {
    "Training Loss": 0.07887999999999999,
    "Learning Rate": 0.00019973390358007603,
    Epoch: 0.11,
  },
  {
    "Training Loss": 0.07491999999999999,
    "Learning Rate": 0.0001996052065025402,
    Epoch: 0.12,
  },
  {
    "Training Loss": 0.07492,
    "Learning Rate": 0.00019945126463811948,
    Epoch: 0.13,
  },
  {
    "Training Loss": 0.07243,
    "Learning Rate": 0.0001992721170031435,
    Epoch: 0.14,
  },
  {
    "Training Loss": 0.07272999999999999,
    "Learning Rate": 0.0001990496492020252,
    Epoch: 0.15,
  },
  {
    "Training Loss": 0.07443999999999999,
    "Learning Rate": 0.00019881814277179248,
    Epoch: 0.16,
  },
  {
    "Training Loss": 0.08315999999999998,
    "Learning Rate": 0.00019856159103477086,
    Epoch: 0.17,
  },
  {
    "Training Loss": 0.07984999999999999,
    "Learning Rate": 0.00019828005901360475,
    Epoch: 0.18,
  },
  {
    "Training Loss": 0.08539,
    "Learning Rate": 0.00019797361806215332,
    Epoch: 0.19,
  },
  {
    "Training Loss": 0.09194,
    "Evaluation Loss": 0.09627792205623546,
    "Learning Rate": 0.0001976136215606197,
    Epoch: 0.2,
  },
  {
    "Training Loss": 0.08626999999999999,
    "Learning Rate": 0.00019725554375442364,
    Epoch: 0.21,
  },
  {
    "Training Loss": 0.08681000000000001,
    "Learning Rate": 0.00019687281667976403,
    Epoch: 0.22,
  },
  {
    "Training Loss": 0.08556,
    "Learning Rate": 0.00019646553733823117,
    Epoch: 0.23,
  },
  {
    "Training Loss": 0.08836000000000002,
    "Learning Rate": 0.0001960338089541498,
    Epoch: 0.24,
  },
  {
    "Training Loss": 0.08915000000000001,
    "Learning Rate": 0.00019553864019693652,
    Epoch: 0.25,
  },
  {
    "Training Loss": 0.08918000000000001,
    "Learning Rate": 0.00019505633490022546,
    Epoch: 0.26,
  },
  {
    "Training Loss": 0.08076000000000001,
    "Learning Rate": 0.00019454993772116336,
    Epoch: 0.27,
  },
  {
    "Training Loss": 0.08826,
    "Learning Rate": 0.00019401957700533888,
    Epoch: 0.28,
  },
  {
    "Training Loss": 0.07919999999999999,
    "Learning Rate": 0.0001934653871718624,
    Epoch: 0.29,
  },
  {
    "Training Loss": 0.07127999999999998,
    "Learning Rate": 0.00019283828740661017,
    Epoch: 0.3,
  },
  {
    "Training Loss": 0.07518,
    "Learning Rate": 0.00019223491166611259,
    Epoch: 0.31,
  },
  {
    "Training Loss": 0.07610999999999998,
    "Learning Rate": 0.0001916081591286572,
    Epoch: 0.32,
  },
  {
    "Training Loss": 0.07618,
    "Learning Rate": 0.00019095818864371368,
    Epoch: 0.33,
  },
  {
    "Training Loss": 0.07315999999999999,
    "Learning Rate": 0.00019022804434585852,
    Epoch: 0.34,
  },
  {
    "Training Loss": 0.06620999999999999,
    "Learning Rate": 0.00018953023900618397,
    Epoch: 0.35,
  },
  {
    "Training Loss": 0.06475,
    "Learning Rate": 0.0001888097423646907,
    Epoch: 0.36,
  },
  {
    "Training Loss": 0.0683,
    "Learning Rate": 0.00018806673703014804,
    Epoch: 0.37,
  },
  {
    "Training Loss": 0.05764999999999999,
    "Learning Rate": 0.00018730141131611882,
    Epoch: 0.38,
  },
  {
    "Training Loss": 0.05680999999999999,
    "Learning Rate": 0.0001864473456374965,
    Epoch: 0.39,
  },
  {
    "Training Loss": 0.06172999999999999,
    "Evaluation Loss": 0.06240823142099246,
    "Learning Rate": 0.00018563614863175023,
    Epoch: 0.4,
  },
  {
    "Training Loss": 0.07078999999999999,
    "Learning Rate": 0.00018480324727542072,
    Epoch: 0.41,
  },
  {
    "Training Loss": 0.06897,
    "Learning Rate": 0.00018394885266607983,
    Epoch: 0.42,
  },
  {
    "Training Loss": 0.0712,
    "Learning Rate": 0.0001830731813487314,
    Epoch: 0.43,
  },
  {
    "Training Loss": 0.06756000000000001,
    "Learning Rate": 0.00018210078478414894,
    Epoch: 0.44,
  },
  {
    "Training Loss": 0.08216000000000001,
    "Learning Rate": 0.00018118150599980397,
    Epoch: 0.45,
  },
  {
    "Training Loss": 0.07980000000000001,
    "Learning Rate": 0.00018024165188722151,
    Epoch: 0.46,
  },
  {
    "Training Loss": 0.07804,
    "Learning Rate": 0.0001792814606509855,
    Epoch: 0.47,
  },
  {
    "Training Loss": 0.08309,
    "Learning Rate": 0.0001783011756500927,
    Epoch: 0.48,
  },
  {
    "Training Loss": 0.08385000000000001,
    "Learning Rate": 0.00017721681325797665,
    Epoch: 0.49,
  },
  {
    "Training Loss": 0.07897000000000001,
    "Learning Rate": 0.00017619547005809854,
    Epoch: 0.5,
  },
  {
    "Training Loss": 0.07217,
    "Learning Rate": 0.00017515481523314828,
    Epoch: 0.51,
  },
  {
    "Training Loss": 0.07144,
    "Learning Rate": 0.00017409511253549593,
    Epoch: 0.52,
  },
  {
    "Training Loss": 0.06684,
    "Learning Rate": 0.00017292591790390665,
    Epoch: 0.53,
  },
  {
    "Training Loss": 0.07213,
    "Learning Rate": 0.0001718274002835229,
    Epoch: 0.54,
  },
  {
    "Training Loss": 0.059169999999999986,
    "Learning Rate": 0.00017071067811865476,
    Epoch: 0.55,
  },
  {
    "Training Loss": 0.0652,
    "Learning Rate": 0.00016957603444082295,
    Epoch: 0.56,
  },
  {
    "Training Loss": 0.07344999999999999,
    "Learning Rate": 0.00016842375682372805,
    Epoch: 0.57,
  },
  {
    "Training Loss": 0.07032,
    "Learning Rate": 0.00016715589548470185,
    Epoch: 0.58,
  },
  {
    "Training Loss": 0.07766999999999999,
    "Learning Rate": 0.00016596782357537155,
    Epoch: 0.59,
  },
  {
    "Training Loss": 0.08223,
    "Evaluation Loss": 0.0853598791825737,
    "Learning Rate": 0.0001647630322222903,
    Epoch: 0.6,
  },
  {
    "Training Loss": 0.08223,
    "Learning Rate": 0.0001635418267779796,
    Epoch: 0.61,
  },
  {
    "Training Loss": 0.08618,
    "Learning Rate": 0.00016230451675508696,
    Epoch: 0.62,
  },
  {
    "Training Loss": 0.08878,
    "Learning Rate": 0.00016094628780365743,
    Epoch: 0.63,
  },
  {
    "Training Loss": 0.08735000000000001,
    "Learning Rate": 0.0001596764384192787,
    Epoch: 0.64,
  },
  {
    "Training Loss": 0.08618,
    "Learning Rate": 0.00015839146413307165,
    Epoch: 0.65,
  },
  {
    "Training Loss": 0.08082,
    "Learning Rate": 0.00015709169061979913,
    Epoch: 0.66,
  },
  {
    "Training Loss": 0.07036,
    "Learning Rate": 0.00015577744730506545,
    Epoch: 0.67,
  },
  {
    "Training Loss": 0.07200999999999999,
    "Learning Rate": 0.00015433774135947156,
    Epoch: 0.68,
  },
  {
    "Training Loss": 0.07371000000000001,
    "Learning Rate": 0.0001529944266083128,
    Epoch: 0.69,
  },
  {
    "Training Loss": 0.06776,
    "Learning Rate": 0.0001516376805009751,
    Epoch: 0.7,
  },
  {
    "Training Loss": 0.06812,
    "Learning Rate": 0.0001502678469026794,
    Epoch: 0.71,
  },
  {
    "Training Loss": 0.0645,
    "Learning Rate": 0.00014876949438136347,
    Epoch: 0.72,
  },
  {
    "Training Loss": 0.0618,
    "Learning Rate": 0.00014737351400163128,
    Epoch: 0.73,
  },
  {
    "Training Loss": 0.061849999999999995,
    "Learning Rate": 0.00014596552687740302,
    Epoch: 0.74,
  },
  {
    "Training Loss": 0.06305000000000001,
    "Learning Rate": 0.00014454588986085676,
    Epoch: 0.75,
  },
  {
    "Training Loss": 0.07315,
    "Learning Rate": 0.00014311496275681783,
    Epoch: 0.76,
  },
  {
    "Training Loss": 0.07408000000000001,
    "Learning Rate": 0.00014155247187177432,
    Epoch: 0.77,
  },
  {
    "Training Loss": 0.0732,
    "Learning Rate": 0.00014009919177562845,
    Epoch: 0.78,
  },
  {
    "Training Loss": 0.06641,
    "Learning Rate": 0.00013863574860080248,
    Epoch: 0.79,
  },
  {
    "Training Loss": 0.06779,
    "Evaluation Loss": 0.0729181471332676,
    "Learning Rate": 0.00013716251325472553,
    Epoch: 0.8,
  },
  {
    "Training Loss": 0.06334000000000001,
    "Learning Rate": 0.00013567985912663747,
    Epoch: 0.81,
  },
  {
    "Training Loss": 0.06346,
    "Learning Rate": 0.00013406345754231588,
    Epoch: 0.82,
  },
  {
    "Training Loss": 0.06416,
    "Learning Rate": 0.00013256239052840155,
    Epoch: 0.83,
  },
  {
    "Training Loss": 0.061959999999999994,
    "Learning Rate": 0.00013105307062652872,
    Epoch: 0.84,
  },
  {
    "Training Loss": 0.062339999999999986,
    "Learning Rate": 0.0001295358803715126,
    Epoch: 0.85,
  },
  {
    "Training Loss": 0.05201,
    "Learning Rate": 0.0001280112042928973,
    Epoch: 0.86,
  },
  {
    "Training Loss": 0.05228000000000001,
    "Learning Rate": 0.00012635147255935295,
    Epoch: 0.87,
  },
  {
    "Training Loss": 0.05288,
    "Learning Rate": 0.00012481244422721867,
    Epoch: 0.88,
  },
  {
    "Training Loss": 0.0512,
    "Learning Rate": 0.00012326712721913457,
    Epoch: 0.89,
  },
  {
    "Training Loss": 0.050949999999999995,
    "Learning Rate": 0.00012171591319332727,
    Epoch: 0.9,
  },
  {
    "Training Loss": 0.055189999999999996,
    "Learning Rate": 0.00012002923273146794,
    Epoch: 0.91,
  },
  {
    "Training Loss": 0.05735999999999999,
    "Learning Rate": 0.0001184669975979413,
    Epoch: 0.92,
  },
  {
    "Training Loss": 0.05959,
    "Learning Rate": 0.00011690008203218493,
    Epoch: 0.93,
  },
  {
    "Training Loss": 0.059829999999999994,
    "Learning Rate": 0.00011532888316654675,
    Epoch: 0.94,
  },
  {
    "Training Loss": 0.05754,
    "Learning Rate": 0.00011375379921897051,
    Epoch: 0.95,
  },
  {
    "Training Loss": 0.05392,
    "Learning Rate": 0.0001120435370931547,
    Epoch: 0.96,
  },
  {
    "Training Loss": 0.052469999999999996,
    "Learning Rate": 0.00011046164240828026,
    Epoch: 0.97,
  },
  {
    "Training Loss": 0.05177999999999999,
    "Learning Rate": 0.00010887709623617602,
    Epoch: 0.98,
  },
  {
    "Training Loss": 0.05153,
    "Learning Rate": 0.00010729030017764005,
    Epoch: 0.99,
  },
  {
    "Training Loss": 0.049769999999999995,
    "Evaluation Loss": 0.052790820611801,
    "Learning Rate": 0.00010570165640370073,
    Epoch: 1.0,
  },
  {
    "Training Loss": 0.04513,
    "Learning Rate": 0.00010397900755100678,
    Epoch: 1.01,
  },
  {
    "Training Loss": 0.04249,
    "Learning Rate": 0.00010238780799152938,
    Epoch: 1.02,
  },
  {
    "Training Loss": 0.040290000000000006,
    "Learning Rate": 0.00010079600324577722,
    Epoch: 1.03,
  },
  {
    "Training Loss": 0.03737,
    "Learning Rate": 9.92039967542228e-5,
    Epoch: 1.04,
  },
  {
    "Training Loss": 0.03741,
    "Learning Rate": 9.761219200847065e-5,
    Epoch: 1.05,
  },
  {
    "Training Loss": 0.04067,
    "Learning Rate": 9.588843244631299e-5,
    Epoch: 1.06,
  },
  {
    "Training Loss": 0.04201,
    "Learning Rate": 9.429834359629929e-5,
    Epoch: 1.07,
  },
  {
    "Training Loss": 0.03747,
    "Learning Rate": 9.270969982235998e-5,
    Epoch: 1.08,
  },
  {
    "Training Loss": 0.03807,
    "Learning Rate": 9.112290376382399e-5,
    Epoch: 1.09,
  },
  {
    "Training Loss": 0.040889999999999996,
    "Learning Rate": 8.953835759171978e-5,
    Epoch: 1.1,
  },
  {
    "Training Loss": 0.03979,
    "Learning Rate": 8.782477060793211e-5,
    Epoch: 1.11,
  },
  {
    "Training Loss": 0.039779999999999996,
    "Learning Rate": 8.624620078102951e-5,
    Epoch: 1.12,
  },
  {
    "Training Loss": 0.04134,
    "Learning Rate": 8.467111683345326e-5,
    Epoch: 1.13,
  },
  {
    "Training Loss": 0.041,
    "Learning Rate": 8.309991796781511e-5,
    Epoch: 1.14,
  },
  {
    "Training Loss": 0.04426,
    "Learning Rate": 8.140263190524406e-5,
    Epoch: 1.15,
  },
  {
    "Training Loss": 0.04239,
    "Learning Rate": 7.984080469738655e-5,
    Epoch: 1.16,
  },
  {
    "Training Loss": 0.04123,
    "Learning Rate": 7.828408680667276e-5,
    Epoch: 1.17,
  },
  {
    "Training Loss": 0.04217,
    "Learning Rate": 7.673287278086544e-5,
    Epoch: 1.18,
  },
  {
    "Training Loss": 0.04267,
    "Learning Rate": 7.518755577278136e-5,
    Epoch: 1.19,
  },
  {
    "Training Loss": 0.04109,
    "Evaluation Loss": 0.047124556910494074,
    "Learning Rate": 7.352057118250246e-5,
    Epoch: 1.2,
  },
  {
    "Training Loss": 0.04026,
    "Learning Rate": 7.198879570710272e-5,
    Epoch: 1.21,
  },
  {
    "Training Loss": 0.03751,
    "Learning Rate": 7.046411962848744e-5,
    Epoch: 1.22,
  },
  {
    "Training Loss": 0.035429999999999996,
    "Learning Rate": 6.894692937347127e-5,
    Epoch: 1.23,
  },
  {
    "Training Loss": 0.03586,
    "Learning Rate": 6.743760947159846e-5,
    Epoch: 1.24,
  },
  {
    "Training Loss": 0.03409000000000001,
    "Learning Rate": 6.581183800704605e-5,
    Epoch: 1.25,
  },
  {
    "Training Loss": 0.034640000000000004,
    "Learning Rate": 6.432014087336256e-5,
    Epoch: 1.26,
  },
  {
    "Training Loss": 0.03451,
    "Learning Rate": 6.283748674527449e-5,
    Epoch: 1.27,
  },
  {
    "Training Loss": 0.038610000000000005,
    "Learning Rate": 6.136425139919755e-5,
    Epoch: 1.28,
  },
  {
    "Training Loss": 0.036250000000000004,
    "Learning Rate": 5.99008082243716e-5,
    Epoch: 1.29,
  },
  {
    "Training Loss": 0.03606,
    "Learning Rate": 5.832689176843291e-5,
    Epoch: 1.3,
  },
  {
    "Training Loss": 0.03579999999999999,
    "Learning Rate": 5.688503724318217e-5,
    Epoch: 1.31,
  },
  {
    "Training Loss": 0.03926999999999999,
    "Learning Rate": 5.545411013914329e-5,
    Epoch: 1.32,
  },
  {
    "Training Loss": 0.03963,
    "Learning Rate": 5.403447312259702e-5,
    Epoch: 1.33,
  },
  {
    "Training Loss": 0.0407,
    "Learning Rate": 5.250969080887271e-5,
    Epoch: 1.34,
  },
  {
    "Training Loss": 0.038680000000000006,
    "Learning Rate": 5.111472700434894e-5,
    Epoch: 1.35,
  },
  {
    "Training Loss": 0.03847000000000001,
    "Learning Rate": 4.97321530973206e-5,
    Epoch: 1.36,
  },
  {
    "Training Loss": 0.0381,
    "Learning Rate": 4.8362319499024875e-5,
    Epoch: 1.37,
  },
  {
    "Training Loss": 0.03397,
    "Learning Rate": 4.700557339168722e-5,
    Epoch: 1.38,
  },
  {
    "Training Loss": 0.033139999999999996,
    "Learning Rate": 4.555093271817616e-5,
    Epoch: 1.39,
  },
  {
    "Training Loss": 0.03204,
    "Evaluation Loss": 0.039536339988585474,
    "Learning Rate": 4.422255269493455e-5,
    Epoch: 1.4,
  },
  {
    "Training Loss": 0.03392,
    "Learning Rate": 4.290830938020087e-5,
    Epoch: 1.41,
  },
  {
    "Training Loss": 0.03203,
    "Learning Rate": 4.160853586692839e-5,
    Epoch: 1.42,
  },
  {
    "Training Loss": 0.03171,
    "Learning Rate": 4.032356158072131e-5,
    Epoch: 1.43,
  },
  {
    "Training Loss": 0.0313,
    "Learning Rate": 3.8948584252059093e-5,
    Epoch: 1.44,
  },
  {
    "Training Loss": 0.0318,
    "Learning Rate": 3.769548324491304e-5,
    Epoch: 1.45,
  },
  {
    "Training Loss": 0.032100000000000004,
    "Learning Rate": 3.645817322202045e-5,
    Epoch: 1.46,
  },
  {
    "Training Loss": 0.033949999999999994,
    "Learning Rate": 3.5236967777709695e-5,
    Epoch: 1.47,
  },
  {
    "Training Loss": 0.03411,
    "Learning Rate": 3.403217642462849e-5,
    Epoch: 1.48,
  },
  {
    "Training Loss": 0.03759,
    "Learning Rate": 3.274586268963443e-5,
    Epoch: 1.49,
  },
  {
    "Training Loss": 0.03852,
    "Learning Rate": 3.157624317627195e-5,
    Epoch: 1.5,
  },
  {
    "Training Loss": 0.03957000000000001,
    "Learning Rate": 3.042396555917707e-5,
    Epoch: 1.51,
  },
  {
    "Training Loss": 0.03747,
    "Learning Rate": 2.9289321881345254e-5,
    Epoch: 1.52,
  },
  {
    "Training Loss": 0.038279999999999995,
    "Learning Rate": 2.8080357398235856e-5,
    Epoch: 1.53,
  },
  {
    "Training Loss": 0.03840999999999999,
    "Learning Rate": 2.6983369454834495e-5,
    Epoch: 1.54,
  },
  {
    "Training Loss": 0.03675,
    "Learning Rate": 2.5904887464504114e-5,
    Epoch: 1.55,
  },
  {
    "Training Loss": 0.03704,
    "Learning Rate": 2.4845184766851724e-5,
    Epoch: 1.56,
  },
  {
    "Training Loss": 0.03351,
    "Learning Rate": 2.38045299419015e-5,
    Epoch: 1.57,
  },
  {
    "Training Loss": 0.03273,
    "Learning Rate": 2.26989546637263e-5,
    Epoch: 1.58,
  },
  {
    "Training Loss": 0.03049,
    "Learning Rate": 2.1698824349907344e-5,
    Epoch: 1.59,
  },
  {
    "Training Loss": 0.029259999999999998,
    "Evaluation Loss": 0.035756741146039474,
    "Learning Rate": 2.0718539349014544e-5,
    Epoch: 1.6,
  },
  {
    "Training Loss": 0.02939,
    "Learning Rate": 1.97583481127785e-5,
    Epoch: 1.61,
  },
  {
    "Training Loss": 0.03139,
    "Learning Rate": 1.881849400019602e-5,
    Epoch: 1.62,
  },
  {
    "Training Loss": 0.03307,
    "Learning Rate": 1.7823544739071585e-5,
    Epoch: 1.63,
  },
  {
    "Training Loss": 0.034269999999999995,
    "Learning Rate": 1.692681865126857e-5,
    Epoch: 1.64,
  },
  {
    "Training Loss": 0.03547,
    "Learning Rate": 1.6051147333920193e-5,
    Epoch: 1.65,
  },
  {
    "Training Loss": 0.03506,
    "Learning Rate": 1.51967527245793e-5,
    Epoch: 1.66,
  },
  {
    "Training Loss": 0.03643999999999999,
    "Learning Rate": 1.4363851368249804e-5,
    Epoch: 1.67,
  },
  {
    "Training Loss": 0.03703999999999999,
    "Learning Rate": 1.3486040806767996e-5,
    Epoch: 1.68,
  },
  {
    "Training Loss": 0.03604,
    "Learning Rate": 1.2698588683881186e-5,
    Epoch: 1.69,
  },
  {
    "Training Loss": 0.037559999999999996,
    "Learning Rate": 1.1933262969851988e-5,
    Epoch: 1.7,
  },
  {
    "Training Loss": 0.03454999999999999,
    "Learning Rate": 1.1190257635309275e-5,
    Epoch: 1.71,
  },
  {
    "Training Loss": 0.03412,
    "Learning Rate": 1.0410741389744127e-5,
    Epoch: 1.72,
  },
  {
    "Training Loss": 0.03154,
    "Learning Rate": 9.714835054694948e-6,
    Epoch: 1.73,
  },
  {
    "Training Loss": 0.030760000000000003,
    "Learning Rate": 9.041811356286322e-6,
    Epoch: 1.74,
  },
  {
    "Training Loss": 0.031080000000000003,
    "Learning Rate": 8.391840871342838e-6,
    Epoch: 1.75,
  },
  {
    "Training Loss": 0.029859999999999998,
    "Learning Rate": 7.765088333887416e-6,
    Epoch: 1.76,
  },
  {
    "Training Loss": 0.0306,
    "Learning Rate": 7.112491320702441e-6,
    Epoch: 1.77,
  },
  {
    "Training Loss": 0.030970000000000004,
    "Learning Rate": 6.5346128281376204e-6,
    Epoch: 1.78,
  },
  {
    "Training Loss": 0.03392,
    "Learning Rate": 5.980422994661139e-6,
    Epoch: 1.79,
  },
  {
    "Training Loss": 0.03272,
    "Evaluation Loss": 0.039884922715166124,
    "Learning Rate": 5.450062278836677e-6,
    Epoch: 1.8,
  },
  {
    "Training Loss": 0.03305,
    "Learning Rate": 4.943665099774553e-6,
    Epoch: 1.81,
  },
  {
    "Training Loss": 0.034640000000000004,
    "Learning Rate": 4.422259051583055e-6,
    Epoch: 1.82,
  },
  {
    "Training Loss": 0.03488,
    "Learning Rate": 3.96619104585022e-6,
    Epoch: 1.83,
  },
  {
    "Training Loss": 0.03543,
    "Learning Rate": 3.534462661768856e-6,
    Epoch: 1.84,
  },
  {
    "Training Loss": 0.03559,
    "Learning Rate": 3.1271833202359935e-6,
    Epoch: 1.85,
  },
  {
    "Training Loss": 0.037380000000000004,
    "Learning Rate": 2.744456245576388e-6,
    Epoch: 1.86,
  },
  {
    "Training Loss": 0.0364,
    "Learning Rate": 2.357654152594113e-6,
    Epoch: 1.87,
  },
  {
    "Training Loss": 0.03669,
    "Learning Rate": 2.0263819378466884e-6,
    Epoch: 1.88,
  },
  {
    "Training Loss": 0.033979999999999996,
    "Learning Rate": 1.7199409863952521e-6,
    Epoch: 1.89,
  },
  {
    "Training Loss": 0.03703,
    "Learning Rate": 1.4384089652291543e-6,
    Epoch: 1.9,
  },
  {
    "Training Loss": 0.03572,
    "Learning Rate": 1.1616075828445372e-6,
    Epoch: 1.91,
  },
  {
    "Training Loss": 0.03587,
    "Learning Rate": 9.321909976943933e-7,
    Epoch: 1.92,
  },
  {
    "Training Loss": 0.03598,
    "Learning Rate": 7.278829968565326e-7,
    Epoch: 1.93,
  },
  {
    "Training Loss": 0.03562,
    "Learning Rate": 5.487353618805369e-7,
    Epoch: 1.94,
  },
  {
    "Training Loss": 0.035210000000000005,
    "Learning Rate": 3.947934974598333e-7,
    Epoch: 1.95,
  },
  {
    "Training Loss": 0.03369,
    "Learning Rate": 2.5651225355497464e-7,
    Epoch: 1.96,
  },
  {
    "Training Loss": 0.03369,
    "Learning Rate": 1.5520030296873877e-7,
    Epoch: 1.97,
  },
  {
    "Training Loss": 0.03369,
    "Learning Rate": 7.919386379515726e-8,
    Epoch: 1.98,
  },
  {
    "Training Loss": 0.03369,
    "Learning Rate": 2.8512199749730628e-8,
    Epoch: 1.99,
  },
  {
    "Training Loss": 0.03369,
    "Evaluation Loss": 0.041203686842363654,
    "Learning Rate": 3.1681560225038654e-9,
    Epoch: 2.0,
  },
];

const parameters = {
  base_model: "OpenGVLab/InternVL2-8B",
  training_epoch: 2,
  lora_r: 32,
  lora_alpha: 64,
  lora_dropout: 0.05,
  learning_rate: 0.0002,
  weight_decay: 0.0,
  bf16: true,
  eval_interval: 0.1,
  target_image_ratio: {
    w: 2,
    h: 2,
  },
  image_size: 448,
};

export default function TrainingJobDetailsPage() {
  return (
    <DashboardContentLayout
      subNavSection="Overview"
      mainTitle="Training Jobs"
      mainTitleHelperText="View and manage training jobs."
      sectionTitle={
        <Heading as="h2" size={"md"} fontWeight="500">
          reimbursement-assistant-08-04
        </Heading>
      }
      sectionTitleHelperText={
        <Text>
          Automatically scheduled training job for task:{" "}
          <Link textDecoration="none">Reimbursement Assistant</Link>
        </Text>
      }
    >
      <Stack spacing={5}>
        <Card variant="information">
          <CardHeader>Job Details</CardHeader>
          <CardBody>
            <SimpleGrid spacing={3} columns={[1, null, 2]}>
              <Box>
                <Stack>
                  <Flex align="center" gap={2}>
                    <MdOutlineDashboardCustomize fill="gray" />
                    <Text as="b">Model</Text>
                  </Flex>
                  <Link textDecoration="none" pl={6} fontSize="sm">
                    reimbursement-assistant-08-04-0e2jfiENL1
                  </Link>
                </Stack>
              </Box>
              <Box>
                <Flex gap={1} align="baseline">
                  <Stack>
                    <Flex align="center" gap={2}>
                      <FaRegUser color="gray" />
                      <Text as="b">Training Data:</Text>
                    </Flex>
                    <Text pl={6}>
                      <Link textDecoration="none">6,000 requests</Link>{" "}
                      (36,132,293 tokens)
                    </Text>
                  </Stack>
                </Flex>
              </Box>
              <Box>
                <Flex gap={1} align="baseline">
                  <Stack>
                    <Flex align="center" gap={2}>
                      <LuLayers color="gray" />
                      <Text as="b">Base Model:</Text>
                    </Flex>
                    <Text pl={6}>
                      <Link textDecoration="none">OpenGVLab/InternVL2-8B</Link>{" "}
                      (Vision Language Model)
                    </Text>
                  </Stack>
                </Flex>
              </Box>
              <Box>
                <Flex gap={1} align="baseline">
                  <Stack>
                    <Flex align="center" gap={2}>
                      <MdAvTimer color="gray" />
                      <Text as="b">Completed at:</Text>
                    </Flex>
                    <Text pl={6}>2024-08-04 00:32 (America/Los_Angeles)</Text>
                  </Stack>
                </Flex>
              </Box>
              <Box>
                <Flex gap={1} align="baseline">
                  <Stack>
                    <Flex align="center" gap={2}>
                      <MdDriveFileRenameOutline fill="gray" />
                      <Text as="b">Status:</Text>
                    </Flex>
                    <Badge colorScheme="green" ml={6}>
                      Completed
                    </Badge>
                  </Stack>
                </Flex>
              </Box>
              <Box>
                <Flex gap={1} align="baseline">
                  <Stack>
                    <Flex align="center" gap={2}>
                      <PiExam fill="gray" />
                      <Text as="b">Evaluation Result:</Text>
                    </Flex>
                    <Text pl={6}>
                      93%{" "}
                      <Link fontSize="sm" textDecoration="none">
                        (view details)
                      </Link>
                    </Text>
                  </Stack>
                </Flex>
              </Box>
            </SimpleGrid>
          </CardBody>
        </Card>
        <Heading as="h2" size={"md"} fontWeight="500" pt={5}>
          Training Details
        </Heading>
        <Grid templateColumns="repeat(3, 1fr)" gap={5}>
          <GridItem colSpan={1}>
            <Card variant="information" h="full">
              <CardHeader>Parameters</CardHeader>
              <CardBody>
                <SyntaxHighlighter
                  language={"yaml"}
                  style={vs}
                  customStyle={{
                    padding: "0",
                  }}
                  wrapLines={true}
                  wrapLongLines={true}
                >
                  {yaml.dump(parameters)}
                </SyntaxHighlighter>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem colSpan={2}>
            <Card variant="information" h="full">
              <CardHeader>Metrics </CardHeader>
              <CardBody>
                <Stack>
                  <Flex justifyContent="space-between">
                    <Text>Duration: 6 hours 10 minutes 13 seconds</Text>
                    <Link textDecoration="none">
                      <Text>View full details</Text>
                    </Link>
                  </Flex>
                  <Text>Average Loss: 0.0502</Text>
                  <Divider />
                  <Flex gap={2}>
                    <Stack w="100%">
                      <LineChart
                        className="h-80"
                        data={stats}
                        index="Epoch"
                        categories={["Training Loss", "Evaluation Loss"]}
                        colors={["indigo", "rose"]}
                        // valueFormatter={dataFormatter}
                        yAxisWidth={60}
                        title="Loss"
                        connectNulls={true}
                        showXAxis={true}
                      />
                      <Flex justifyContent="center">
                        <Text fontSize="lg">Loss</Text>
                      </Flex>
                    </Stack>
                    <Stack w="100%">
                      <LineChart
                        className="h-80"
                        data={stats}
                        index="Epoch"
                        categories={["Learning Rate"]}
                        colors={["indigo", "rose"]}
                        // valueFormatter={dataFormatter}
                        yAxisWidth={60}
                        title="Loss"
                        showXAxis={true}
                      />
                      <Flex justifyContent="center">
                        <Text fontSize="lg">Learning Rate</Text>
                      </Flex>
                    </Stack>
                  </Flex>
                </Stack>
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </Stack>
    </DashboardContentLayout>
  );
}
