import { Box } from "@chakra-ui/react";
import { RiRobot2Line, RiUser2Line } from "react-icons/ri";

export default function ChatAvatar({ role }: { role: string }) {
  if (role === "user") {
    return (
      <Box
        backgroundColor="highlightBg"
        className="flex h-8 w-8 shrink-0 selec-none items-center justify-center border"
      >
        <RiUser2Line className="h-4 w-4" />
      </Box>
    );
  }

  return (
    <Box
      backgroundColor="highlightBg"
      className="flex h-8 w-8 shrink-0 select-none items-center justify-center border"
    >
      <RiRobot2Line className="h-4 w-4" />
    </Box>
  );
}
