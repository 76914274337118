import { Container } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

export default function RootLayout() {
  return (
    <Container minH="100vh" minW="100vw" p={0} backgroundColor="highlightBg">
      <Outlet />
    </Container>
  );
}
