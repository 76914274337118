import {
  Badge,
  Button,
  Divider,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect } from "react";
import { CiMenuBurger } from "react-icons/ci";
import { GrDeploy } from "react-icons/gr";
import { NavLink, useNavigate } from "react-router-dom";
import Actor from "../components/actor";
import { ContactSupportLink } from "../components/contact-support-link";
import { DataTable } from "../components/data-table";
import { DeleteConfirmationDialog } from "../components/deletion-confirmation-dialog";
import DeployLoRAModal from "../components/model/deploy-lora-modal";
import QueryModelModal from "../components/model/query-model-modal";
import { ActorType } from "../interfaces/actor";
import { LoRAResponse, ModelType } from "../interfaces/model";
import DashboardContentLayout from "../layouts/dashboard-content-layout";
import { getApi } from "../services/api/api-service";
import { useBilling } from "../services/billing/billing-context";
import { useGlobalAlert } from "../services/global-alert/global-alert-context";
import { formatTimestampToLocal } from "../utils";

const columnHelper = createColumnHelper<LoRAResponse>();

export default function LoRAsPage() {
  const { data, isLoading, error, refetch } = getApi<LoRAResponse[]>("/lora");
  const { addAlert } = useGlobalAlert();
  const { currentPlan } = useBilling();

  useEffect(() => {
    if (error) {
      addAlert({
        type: "error",
        body: (
          <Text>
            Unexpected error happened when fetching LoRAs, please retry and{" "}
            <ContactSupportLink /> if the issue persists.
          </Text>
        ),
      });
    }
  }, [error, addAlert]);

  const navigate = useNavigate();

  const columns = [
    columnHelper.display({
      id: "model",
      header: "LORA",
      cell: ({ row }) => {
        const name = row.original.name;
        const modelName = row.original.activeDeployment?.modelName;

        let content = name;
        if (
          name !== modelName &&
          row.original.activeDeployment?.creator.actorType !==
            ActorType.OFFICIAL
        ) {
          content = `${name} (Model: ${modelName})`;
        }
        return (
          <Flex gap={2} align="center">
            {content}
            {row.original.toolsEnabled && (
              <Badge colorScheme="messenger">Tools</Badge>
            )}
          </Flex>
        );
      },
    }),
    columnHelper.accessor("activeDeployment.baseModelName", {
      header: "Base Model",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("activeDeployment.creator", {
      header: "Deployed By",
      cell: (info) => <Actor actor={info.getValue()} />,
    }),
    columnHelper.accessor("unitPrice", {
      header: "Price 1M Tokens",
      cell: (info) => `$${(info.getValue() / 100).toFixed(2)}`,
    }),
    columnHelper.accessor("activeDeployment.createdAt", {
      header: "Deployed At",
      cell: (info) =>
        info.row.original.activeDeployment?.creator.actorType !==
        ActorType.OFFICIAL
          ? formatTimestampToLocal(info.getValue())
          : "",
    }),
    columnHelper.display({
      id: "action",
      header: "",
      meta: {
        isActionColumn: true,
      },
      cell: ({ row }) => (
        <Menu>
          <MenuButton zIndex={100}>
            <CiMenuBurger fill="gray" />
          </MenuButton>
          <MenuList>
            <QueryModelModal
              modelType={ModelType.LORA}
              name={row.original.name}
            >
              <MenuItem fontSize="sm" color="gray.600">
                Query
              </MenuItem>
            </QueryModelModal>
            <MenuItem
              fontSize="sm"
              onClick={() => navigate(encodeURIComponent(row.original.name))}
            >
              Details
            </MenuItem>
            {row.original.activeDeployment?.creator.actorType !==
              ActorType.OFFICIAL && (
              <>
                <Divider />
                <DeleteConfirmationDialog
                  entityName="LoRA"
                  deletingName={row.original.name}
                  deletingKey={row.original.name}
                  deletionApiPath="/lora"
                  description={
                    <>
                      LoRA <b>{row.original.name}</b> will be permanently
                      deleted and will no longer be queryable.
                    </>
                  }
                  onDeleted={async () => {
                    await refetch();
                  }}
                >
                  <MenuItem fontSize="sm" color="red">
                    Delete
                  </MenuItem>
                </DeleteConfirmationDialog>
              </>
            )}
          </MenuList>
        </Menu>
      ),
    }),
  ];

  const numDeployedLoRAs = data?.filter(
    (d) => d.activeDeployment?.creator.actorType !== ActorType.OFFICIAL
  ).length;
  return (
    <DashboardContentLayout
      subNavSection="Overview"
      mainTitle="LoRAs"
      mainTitleHelperText={
        <Text color="gray.600" fontSize="sm" pt={2}>
          Low rank adapter for Large Language Models (LLMs). LoRAs needs to be
          trained based on of of the{" "}
          <Link as={NavLink} to="/base-model">
            base models
          </Link>{" "}
          and can be queried directly.
        </Text>
      }
      sectionTitle="Manage LoRAs"
      sectionTitleHelperText={
        <Text color="gray.600" fontSize="sm" pt={2}>
          {numDeployedLoRAs} LoRAs deployed (
          {(currentPlan?.numModels ?? 0) - (numDeployedLoRAs ?? 0)} more
          deployable).{" "}
          {numDeployedLoRAs === 0
            ? "Deploy your first LoRA or try the sample LoRAs!"
            : ""}
        </Text>
      }
      sectionTitleCallToAction={
        <DeployLoRAModal
          onDeployed={async () => {
            await refetch();
          }}
        >
          <Button variant="cta" leftIcon={<GrDeploy />}>
            Deploy LoRA
          </Button>
        </DeployLoRAModal>
      }
    >
      <DataTable
        columns={columns}
        data={data!}
        isLoading={isLoading}
        defaultSorting={[
          {
            id: "activeDeployment_createdAt",
            desc: true,
          },
        ]}
        onRowClick={(row) => {
          navigate(`/lora/${encodeURIComponent(row.original.name)}`);
        }}
      />
    </DashboardContentLayout>
  );
}
