import {
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { ReactElement, useEffect, useState } from "react";
import { ApiKeyResponse } from "../../interfaces/api-key";
import { ModelType } from "../../interfaces/model";
import { useApiClient } from "../../services/api/api-client-context";
import { getApi } from "../../services/api/api-service";
import CodeBlock from "../code-block";

interface QueryModelModalProps {
  children: ReactElement<{
    onClick: () => void;
  }>;
  name: string;
  modelType: ModelType;
}

export default function QueryModelModal({
  children,
  name,
  modelType,
}: QueryModelModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const apiClient = useApiClient();

  const { data, isLoading, error } = getApi<ApiKeyResponse[]>("/api-key");

  // @TODO(daiyi): integrate with the api key and update the name and endpoint
  const [apiKey, setApiKey] = useState<string>("");

  useEffect(() => {
    if (data?.length) {
      setApiKey(data[0].apiKey);
    }
  }, [data, setApiKey]);

  function renderApiKeySelector() {
    return (
      <>
        <Text>Select an api key:</Text>
        {isLoading && <Skeleton height="30px" />}
        {error && (
          <Text>
            Error happened when fetching api keys, please retry and contact
            support if the issue persists.
          </Text>
        )}
        {data?.length ? (
          <Select
            size="md"
            onChange={(e) => {
              setApiKey(e.currentTarget.value);
            }}
            boxShadow="md"
          >
            {data?.map((apiKey) => (
              <option value={apiKey.apiKey}>{apiKey.name}</option>
            ))}
          </Select>
        ) : (
          <Text>
            You don't have any api key yet, click{" "}
            <Link href="/api-key">here</Link> to create a new api key.
          </Text>
        )}
      </>
    );
  }

  const messages = `[
    {"role": "user", "content": "Who won the world series in 2020?"},
    {"role": "assistant", "content": "The Los Angeles Dodgers won the World Series in 2020."},
    {"role": "user", "content": "Where was it played?"}
]`;

  return (
    <>
      {React.cloneElement(children, {
        onClick: onOpen,
      })}
      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="800px">
          <ModalHeader>
            Query {modelType === ModelType.LORA ? "LoRA" : "Base Model"}
            <Text fontSize="sm" color="gray.500">
              Instructions to query the{" "}
              {modelType === ModelType.LORA ? "LoRA" : "base model"}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="md">
              Empower is fully compatible with the OpenAI Completion API and
              SDK. Please refer to the following instructions on how to query
              the {modelType === ModelType.LORA ? "LoRA" : "base model"}:
            </Text>
            <Tabs pt={5}>
              <TabList>
                <Tab>Curl</Tab>
                <Tab>Node</Tab>
                <Tab>Python</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Stack spacing={4}>
                    {renderApiKeySelector()}
                    <Text>Sample code: </Text>
                    <CodeBlock
                      code={getCurlCodeBlock(
                        apiKey,
                        name,
                        apiClient.baseUrl,
                        messages
                      )}
                      language="bash"
                    />
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <Stack spacing={4}>
                    {renderApiKeySelector()}
                    <Text>Install the OpenAI node sdk: </Text>
                    <CodeBlock
                      code="npm install --save openai"
                      language="bash"
                    />
                    <Text>Sample code:</Text>
                    <CodeBlock
                      code={getNodeCodeBlock(
                        apiKey,
                        name,
                        apiClient.baseUrl,
                        messages
                      )}
                      language="javascript"
                    />
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <Stack spacing={4}>
                    {renderApiKeySelector()}
                    <Text>Install the OpenAI python sdk: </Text>
                    <CodeBlock
                      code="pip install --upgrade openai"
                      language="bash"
                    />
                    <Text>Sample code:</Text>
                    <CodeBlock
                      code={getPythonCodeBlock(
                        apiKey,
                        name,
                        apiClient.baseUrl,
                        messages
                      )}
                      language="python"
                    />
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function getCurlCodeBlock(
  apiKey: string,
  name: string,
  baseUrl: string,
  messages: string
) {
  return `export API_KEY=${apiKey}
curl "${baseUrl}/v1/chat/completions" \\
-H "Content-Type: application/json" \\
-H "Authorization: Bearer $API_KEY" \\
-d '{
    "model": "${name}",
    "messages": ${messages},
    "temperature": 0.7
}'
`;
}

function getPythonCodeBlock(
  apiKey: string,
  name: string,
  baseUrl: string,
  messages: string
) {
  return `import openai

client = openai.OpenAI(
    base_url = "${baseUrl}/v1",
    api_key = "${apiKey}"
)

chat_completion = client.chat.completions.create(
    model="${name}",
    messages=${messages},
    temperature=0.7
)

print(chat_completion.model_dump())
`;
}

function getNodeCodeBlock(
  apiKey: string,
  name: string,
  baseUrl: string,
  messages: string
) {
  return `const { OpenAI } = require("openai");

const empower = new OpenAI({
    baseURL: "${baseUrl}/v1",
    apiKey: "${apiKey}"
});

const completion = await empower.chat.completions.create({
    model: "${name}",
    messages: ${messages},
    temperature: 0.7
});

console.log(completion);
`;
}
