import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { useForm } from "react-hook-form";
import { GrDeploy } from "react-icons/gr";
import { NavLink } from "react-router-dom";

import { LoRADeploymentResponse, ModelSource } from "../../interfaces/model";
import { postApiMutation } from "../../services/api/api-service";
import { useGlobalAlert } from "../../services/global-alert/global-alert-context";
import ExternalLink from "../external-link";

interface DeployLoRARequestInput {
  name: string;
  modelName: string;
  hfRevision: string;
  hfToken: string;
  modelSource: ModelSource;
}

interface DeployLoRAModalProps {
  onDeployed: () => Promise<void>;
  children: ReactElement<{
    onClick: () => void;
  }>;
}

export default function DeployLoRAModal({
  onDeployed,
  children,
}: DeployLoRAModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, register, formState, setError, setValue, reset } =
    useForm<DeployLoRARequestInput>({
      mode: "all",
    });

  const { isPending, mutate } = postApiMutation<
    DeployLoRARequestInput,
    LoRADeploymentResponse
  >("/lora");

  const { addAlert } = useGlobalAlert();

  async function onSubmit(input: DeployLoRARequestInput) {
    mutate(
      { ...input, modelSource: ModelSource.HF_HUB },
      {
        onError: (error) => {
          setError("root", {
            type: error.errorCode,
            message: error.message,
          });
        },
        onSuccess: async () => {
          addAlert({
            type: "success",
            body: (
              <Text>
                Successfully deploy LoRA <b>{input.name}</b>!
              </Text>
            ),
          });

          reset();
          onClose();
          await onDeployed();
        },
      }
    );
  }

  function syncModelName(e: React.FormEvent<HTMLInputElement>) {
    if (!formState.dirtyFields.name) {
      setValue("name", e.currentTarget.value);
    }
  }

  return (
    <>
      {React.cloneElement(children, {
        onClick: onOpen,
      })}
      <Modal
        closeOnOverlayClick={!isPending}
        size="xl"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent maxW="800px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>
              Deploy LoRA{" "}
              <Text fontSize="sm" color="gray.500">
                Deploy a Hugging Face model
              </Text>
            </ModalHeader>
            <ModalCloseButton isDisabled={isPending} />
            <ModalBody>
              <Text color="gray.500">
                Please ensure the LoRA is trained with the{" "}
                <ExternalLink
                  href="https://huggingface.co/docs/peft/index"
                  text="Hugging Face PEFT"
                />{" "}
                and is based on one of the{" "}
                <Link target="_blank" as={NavLink} to="/base-model">
                  supported base models
                </Link>
                .
              </Text>
              <FormControl isInvalid={Boolean(formState.errors.root)}>
                <FormErrorMessage>
                  Error: {formState.errors.root?.message}
                </FormErrorMessage>
              </FormControl>
              <Flex direction="column" className="gap-y-3" pt={5}>
                <FormControl
                  isInvalid={Boolean(formState.errors.modelName)}
                  isDisabled={isPending}
                >
                  <FormLabel>
                    <Flex align="center" className="gap-1">
                      <Text>Hugging Face Repository ID</Text>
                      <Text color="red">*</Text>
                    </Flex>
                  </FormLabel>
                  <Input
                    id="modelName"
                    {...register("modelName", {
                      required: "Hugging Face repository ID is required",
                      pattern: {
                        message:
                          "Please enter a valid Hugging Face repository ID. Only regular alphanumeric characters, '-', '.' and '_' are supported.",
                        value: /^[A-Za-z0-9-_.]+\/[A-Za-z0-9-_.]+$/,
                      },
                      onChange: syncModelName,
                    })}
                  />
                  <FormErrorMessage>
                    {formState.errors.modelName &&
                      formState.errors.modelName.message}
                  </FormErrorMessage>
                  <FormHelperText>
                    Hugging Face repository ID, e.g.,
                    FlagAlpha/Llama2-Chinese-7b-Chat-LoRA.
                  </FormHelperText>
                </FormControl>
                <Divider />
                <FormControl
                  isInvalid={Boolean(formState.errors.name)}
                  isDisabled={isPending}
                >
                  <FormLabel>
                    <Flex align="center" className="gap-1">
                      <Text>Name</Text>
                      <Text color="red">*</Text>
                    </Flex>
                  </FormLabel>
                  <Input
                    id="name"
                    {...register("name", {
                      required: "Name is required",
                      pattern: {
                        message:
                          "Please enter a valid name. Only regular alphanumeric characters, '-', '.' and '_' are supported.",
                        value: /^[A-Za-z0-9-_.\/]+$/i,
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {formState.errors.name && formState.errors.name.message}
                  </FormErrorMessage>
                  <FormHelperText>
                    Specify the name of the repository if you want it to differ
                    from the Hugging Face repository ID. For instance, you might
                    want a permanent endpoint and keep the underlying LoRA
                    transparent. Name is unique across the organization, using
                    an existing name here will replace the existing model.
                  </FormHelperText>
                </FormControl>
                <Divider />
                <FormControl isDisabled={isPending}>
                  <FormLabel>Revision</FormLabel>
                  <Input id="hfRevision" {...register("hfRevision", {})} />
                  <FormHelperText>
                    (Optional) Revision of the Hugging Face repository is
                    necessary if it differs from the current head.
                  </FormHelperText>
                </FormControl>
                <Divider />
                <FormControl isDisabled={isPending}>
                  <FormLabel>Hugging Face Token</FormLabel>
                  <Input type="hfToken" {...register("hfToken", {})} />
                  <FormHelperText>
                    (Optional) Hugging Face access token to fetch the model if
                    the repository is private. It will only be used to fetch the
                    model and we will not persist it.
                  </FormHelperText>
                </FormControl>
              </Flex>
            </ModalBody>

            <ModalFooter className="gap-1">
              <Button
                variant="cta"
                leftIcon={<GrDeploy />}
                type="submit"
                isLoading={isPending}
                loadingText="Deploying"
                isDisabled={!formState.isValid}
              >
                Deploy
              </Button>
              <Button
                variant="outline"
                onClick={onClose}
                isDisabled={isPending}
              >
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}
