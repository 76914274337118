import { Avatar, Badge, Flex, Text } from "@chakra-ui/react";
import { ActorResponse, ActorType } from "../interfaces/actor";

interface ActorProps {
  actor: ActorResponse;
}
export default function Actor({ actor }: ActorProps) {
  const user = actor.user!;
  switch (actor.actorType) {
    case ActorType.USER:
      return (
        <Flex align="center" className="gap-2">
          <Avatar
            size="sm"
            name={`${user.firstName} ${user.lastName}`}
            src={user.profileImageUrl}
          />
          <Text fontSize="sm">{`${user.firstName} ${user.lastName}`}</Text>
        </Flex>
      );
    case ActorType.API_KEY:
      return (
        <Flex className="gap-2">
          <Badge colorScheme="green">Api Key</Badge>
          <Text fontSize="sm">{actor.apiKey?.name}</Text>
        </Flex>
      );
    case ActorType.OFFICIAL:
      return <Badge colorScheme="messenger">Official</Badge>;
  }
}
