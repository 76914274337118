import { Flex, List, Text, VStack } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { default as SidenavItemComponent } from "./sidenav-item";
import { default as SidenavItemGroupComponent } from "./sidenav-item-group";

export interface SidenavItem {
  icon: IconType;
  label: string;
  to: string;
}

export interface SidenavItemGroup {
  label: string;
  icon: IconType;
  sidenavItems: SidenavItem[];
}

export interface SidenavItemsProps {
  navItems: (SidenavItem | SidenavItemGroup)[];
}

function isSidenavItemGroup(
  item: SidenavItem | SidenavItemGroup
): item is SidenavItemGroup {
  return (item as SidenavItemGroup).sidenavItems !== undefined;
}

export function SidenavItems({ navItems }: SidenavItemsProps) {
  const sidebarItem = (item: SidenavItem | SidenavItemGroup, index: number) => {
    if (isSidenavItemGroup(item)) {
      return (
        <SidenavItemGroupComponent
          icon={item.icon}
          label={item.label}
          sidenavItems={item.sidenavItems}
          key={`group-${index}`}
        />
      );
    } else {
      return (
        <SidenavItemComponent
          to={item.to}
          label={item.label}
          icon={item.icon}
          key={`item-${index}`}
        />
      );
    }
  };

  return (
    <VStack spacing={0} w="full">
      <Flex justifyContent={"left"} justifyItems={"left"} w="full">
        <Text ml={4} fontSize="sm" fontWeight={600}>
          Menu
        </Text>
      </Flex>
      <List spacing={1} w="full" p={3}>
        {navItems.map((item, index) => sidebarItem(item, index))}
      </List>
    </VStack>
  );
}

export default SidenavItems;
