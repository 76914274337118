import { useAuth } from "@clerk/clerk-react";
import { PlaygroundModule } from "../components/chat/playground-module";
import LoadingIndicator from "../components/loading-indicator";
import AnonymousDashboardLayout from "../layouts/anonymous-dashboard-layout";
import DashboardLayout from "../layouts/dashboard-layout";

export default function PlaygroundPage() {
  const { userId, isLoaded } = useAuth();
  if (!isLoaded) {
    return <LoadingIndicator />;
  }

  if (userId) {
    return (
      <DashboardLayout>
        <PlaygroundModule />
      </DashboardLayout>
    );
  }

  return (
    <AnonymousDashboardLayout>
      <PlaygroundModule />
    </AnonymousDashboardLayout>
  );
}
