import { Link } from "@chakra-ui/react";
import { FiExternalLink } from "react-icons/fi";

interface ExternalLinkProps {
  href: string;
  text: string;
}
export default function ExternalLink({ href, text }: ExternalLinkProps) {
  return (
    <Link alignItems="center" gap={1} href={href} isExternal>
      {text}
      <FiExternalLink className="inline-flex mb-1 ml-1" />
    </Link>
  );
}
