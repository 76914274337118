import {
  Button,
  Link,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { ReactElement } from "react";

import { FaRegCalendar } from "react-icons/fa";
import { FaDiscord, FaRegEnvelope } from "react-icons/fa6";

import {
  bookAMeetingUrl,
  contactEmailUrl,
  discordInvitationUrl,
} from "../utils";

interface ContactModalProps {
  children: ReactElement<{
    onClick: () => void;
  }>;
}
export default function ContactModal({ children }: ContactModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      {React.cloneElement(children, {
        onClick: onOpen,
      })}
      <Modal isOpen={isOpen} isCentered size="xl" onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Support</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            We are here to support, please contact us via the methods below:
            <List pl={1} pt={2}>
              <ListItem>
                <ListIcon as={FaDiscord} />
                <Link
                  href={discordInvitationUrl}
                  target="_blank"
                  title="Join discord"
                  textDecoration="none"
                >
                  Discord
                </Link>
              </ListItem>
              <ListItem>
                <ListIcon as={FaRegCalendar} />
                <Link
                  href={bookAMeetingUrl}
                  target="_blank"
                  title="Book a meeting"
                  textDecoration="none"
                >
                  Book a meeting
                </Link>
              </ListItem>
              <ListItem>
                <ListIcon as={FaRegEnvelope} />
                <Link
                  href={contactEmailUrl}
                  title={contactEmailUrl}
                  textDecoration="none"
                >
                  Email
                </Link>
              </ListItem>
            </List>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose} variant="cta">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
