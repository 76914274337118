import OpenAI from "openai";
import { v4 as uuidv4 } from "uuid";
import { getBaseApiUrl } from "../../../utils";

const openai = new OpenAI({
  baseURL: `${getBaseApiUrl()}/v1/functions`,
  apiKey: "",
  dangerouslyAllowBrowser: true,
});
const model = "empower-functions-medium";

export const tools = [
  {
    type: "function",
    function: {
      name: "verify_patient_identity",
      description:
        "Verify the identity of a patient based on their name and date of birth",
      parameters: {
        type: "object",
        properties: {
          message: {
            type: "string",
            description:
              "The message you send to the caller while the caller is waiting for the function response",
          },
          name: {
            type: "string",
            description: "The patient's full name",
          },
          date_of_birth: {
            type: "string",
            description:
              "The patient's date of birth, in the format of YYYY-MM-DD. Make sure it's a valid date between 1920 and 2024.",
          },
        },
        required: ["message", "name", "date_of_birth"],
      },
    },
  },
  {
    type: "function",
    function: {
      name: "find_departments",
      description:
        "Find the departments relevant to the patient's stated reason for the appointment",
      parameters: {
        type: "object",
        properties: {
          message: {
            type: "string",
            description:
              "The message you send to the caller while the caller is waiting for the function response",
          },
          reason_for_appointment: {
            type: "string",
            description: "The reason the patient is seeking an appointment",
          },
        },
        required: ["message", "reason_for_appointment"],
      },
    },
  },
  {
    type: "function",
    function: {
      name: "find_availability_by_department",
      description:
        "Find available appointment times and doctors for a specific department",
      parameters: {
        type: "object",
        properties: {
          message: {
            type: "string",
            description:
              "The message you send to the caller while the caller is waiting for the function response",
          },
          department_id: {
            type: "string",
            description:
              "The ID of the department for which to find available appointments",
          },
        },
        required: ["message", "department_id"],
      },
    },
  },
  {
    type: "function",
    function: {
      name: "find_availability_by_doctor",
      description: "Find available appointment times for a specific doctor",
      parameters: {
        type: "object",
        properties: {
          message: {
            type: "string",
            description:
              "The message you send to the caller while the caller is waiting for the function response",
          },
          doctor_name: {
            type: "string",
            description:
              "The name of the doctor for which to find available appointments",
          },
        },
        required: ["message", "doctor_name"],
      },
    },
  },
  {
    type: "function",
    function: {
      name: "book_appointment",
      description: "Book an appointment for the patient",
      parameters: {
        type: "object",
        properties: {
          message: {
            type: "string",
            description:
              "The message you send to the caller while the caller is waiting for the function response",
          },
          patient_id: {
            type: "string",
            description: "The patient's unique identifier",
          },
          appointment_time: {
            type: "string",
            description: "The chosen time slot for the appointment",
          },
          doctor_name: {
            type: "string",
            description:
              "The ID of the doctor with whom the appointment will be booked",
          },
        },
        required: ["message", "patient_id", "appointment_time", "doctor_name"],
      },
    },
  },
  {
    type: "function",
    function: {
      name: "transfer_to_customer_service",
      description: "Transfer the patient to a customer service representative",
      parameters: {
        type: "object",
        properties: {
          message: {
            type: "string",
            description:
              "The message you send to the caller while the caller is waiting for the function response",
          },
        },
        required: ["message"],
      },
    },
  },
  {
    type: "function",
    function: {
      name: "hang_up",
      description: "End the call with the patient",
      parameters: {
        type: "object",
        properties: {
          message: {
            type: "string",
            description:
              "The message you send to the caller while the caller is waiting for the function response",
          },
        },
        required: ["message"],
      },
    },
  },
];

const allDepartments: { [key: string]: string[] } = {
  chinese: [
    "神经学",
    "心脏病学",
    "肿瘤学",
    "骨科",
    "胃肠病学",
    "产科和妇科",
    "儿科",
    "皮肤病学",
    "精神病学",
    "肺病学",
    "内分泌学",
    "肾脏病学",
    "泌尿科",
    "风湿病学",
    "眼科",
    "耳鼻喉科",
    "传染病科",
    "血液病学",
    "急诊医学",
    "麻醉学",
    "外科",
    "整形外科",
    "放射科",
    "病理科",
    "物理医学与康复",
    "重症医学",
    "神经外科",
    "心胸外科",
    "血管外科",
    "老年医学",
  ],
  english: [
    "Neurology",
    "Cardiology",
    "Oncology",
    "Orthopedics",
    "Gastroenterology",
    "Obstetrics and Gynecology",
    "Pediatrics",
    "Dermatology",
    "Psychiatry",
    "Pulmonology",
    "Endocrinology",
    "Nephrology",
    "Urology",
    "Rheumatology",
    "Ophthalmology",
    "Otolaryngology (ENT)",
    "Infectious Disease",
    "Hematology",
    "Emergency Medicine",
    "Anesthesiology",
    "Surgery",
    "Plastic Surgery",
    "Radiology",
    "Pathology",
    "Physical Medicine and Rehabilitation",
    "Critical Care Medicine",
    "Neurosurgery",
    "Cardiothoracic Surgery",
    "Vascular Surgery",
    "Geriatrics",
  ],
};

const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = Math.random() < 0.5 ? "00" : "30";
  const ampm = date.getHours() < 12 ? "AM" : "PM";

  return `${year}-${month}-${day} ${hour}:${minute} ${ampm}`;
};

const randomDate = () => {
  const currentDate = new Date();
  const futureDate = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000);

  let randomDate1: Date;
  do {
    randomDate1 = new Date(
      currentDate.getTime() +
        Math.random() * (futureDate.getTime() - currentDate.getTime())
    );
  } while (
    randomDate1.getDay() === 0 || // Sunday
    randomDate1.getDay() === 6 || // Saturday
    randomDate1.getHours() < 9 || // Before 9 AM
    randomDate1.getHours() >= 17 // After 5 PM
  );

  return randomDate1;
};

const randomDates = () => {
  const date1 = randomDate();
  const date2 = randomDate();
  return [date1, date2]
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    .map(formatDate);
};
const findDepartment = async (message: string, language: string) => {
  const response = await openai.chat.completions.create({
    model: model,
    stream: false,
    messages: [
      {
        role: "user",
        content: `Pick the best department from the list: ${allDepartments[language].join(",")} to book appointment for the following reason: ${message}? Return the department name ONLY, nothing else`,
      },
    ],
  });

  return {
    department: response.choices[0].message.content,
  };
};

export const getToolResponse = async (
  toolName: string,
  deserializedArguments: { [key: string]: any },
  message: string,
  language: string
) => {
  switch (toolName) {
    case "verifyPatientIdentity":
      if (deserializedArguments["date_of_birth"] != null) {
        const dob = new Date(deserializedArguments["date_of_birth"]);
        const year = dob.getFullYear();

        if (isNaN(year) || year < 1900 || year > 2024) {
          return {
            error:
              "Invalid date of birth, please provide a valid date of birth.",
          };
        }
      }
      return {
        patient_id: uuidv4().toString(),
      };
    case "findDepartments":
      return await findDepartment(message, language);
    case "findAvailabilityByDepartment":
      return randomDates();
    case "findAvailabilityByDoctor":
      return randomDates();
    case "bookAppointment":
      return {
        appointment_id: uuidv4().toString(),
      };
    case "transferToCustomerService":
      return {
        success: true,
      };
    case "hangUp":
      return {
        success: true,
      };
    default:
      return {};
  }
};
