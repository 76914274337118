import { ChatScrollAnchor } from "./ChatScrollAnchor";
import ChatActions from "./chat-actions";
import ChatMessage from "./chat-message";
import { ChatMessage as ChatMessageInterface } from "./common/types";

export default function ChatMessages(props: {
  messages: ChatMessageInterface[];
  isLoading: boolean;
  reload?: () => void;
  stop?: () => void;
  children?: React.ReactNode;
  trigger: number;
}) {
  const messageLength = props.messages.length;
  const lastMessage = props.messages[messageLength - 1];

  const isLastMessageFromAssistant =
    messageLength > 0 && lastMessage?.role !== "user";
  const showReload =
    props.reload && !props.isLoading && isLastMessageFromAssistant;
  const showStop = props.stop && props.isLoading;

  return (
    <div className="w-full overflow-y-auto flex-1">
      <div className="flex flex-col gap-2 divide-y">
        {props.messages
          .filter((m) => m.metadata?.hide !== true)
          .map((m) => (
            <ChatMessage key={m.id} {...m} />
          ))}
        {props.children}
      </div>
      <div className="flex justify-end pb-4">
        <ChatActions
          reload={props.reload}
          stop={props.stop}
          showReload={showReload}
          showStop={showStop}
        />
      </div>
      <ChatScrollAnchor
        trackVisibility={props.isLoading}
        trigger={props.trigger}
      />
    </div>
  );
}
