// import { loadStripe } from "@stripe/stripe-js";
import { Button, Flex, Skeleton } from "@chakra-ui/react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { FormEvent, useState } from "react";
import { postApiMutation, queryApi } from "../../services/api/api-service";
import { useBilling } from "../../services/billing/billing-context";
import { useGlobalAlert } from "../../services/global-alert/global-alert-context";
import { ContactSupportLink } from "../contact-support-link";

type Props = {
  onFinished: () => void;
};

export default function AddCreditCardComponent(props: Props) {
  const { onFinished } = props;
  const { addAlert } = useGlobalAlert();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { mutate: addPaymentMutate } = postApiMutation<object, object>(
    `/billing/payment_methods`
  );

  const { isLoading, error, data } = queryApi<string>(
    async (client) => client.post("/v1/billing/create_setup_intent", {}),
    ["stripe-client-secret"]
  );
  const stripe = useStripe();
  const elements = useElements();
  const { setHasPayment } = useBilling();

  if (isLoading) {
    return <Skeleton height="40px" w="400px" />;
  } else if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!data) {
    return (
      <div>
        Backend error, please retry and <ContactSupportLink /> if the issue
        persists.
      </div>
    );
  }

  if (!stripe || !elements) {
    return <div>Stripe initializing...</div>;
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      addAlert({
        type: "error",
        body: <div>Failed to create payment method: {error.message}</div>,
      });
    } else {
      const { setupIntent, error } = await stripe.confirmCardSetup(data, {
        payment_method: paymentMethod!.id,
      });

      if (setupIntent) {
        addPaymentMutate(
          {
            paymentMethodId: paymentMethod!.id,
            isDefault: false,
          },
          {
            onSuccess: async () => {
              onFinished();
              addAlert({
                type: "success",
                body: <div>Successfully added payment method</div>,
              });
              setHasPayment(true);
            },
            onError: () => {
              addAlert({
                type: "error",
                body: <div>Failed to add payment method</div>,
              });
            },
          }
        );
      } else if (error) {
        addAlert({
          type: "error",
          body: <div>Failed to confirm card setup: {error.message}</div>,
        });
      }
    }

    setIsSubmitting(false);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Flex gap={2} align="center">
        <CardElement className="w-[350px]" />
        <Button
          variant="cta"
          type="submit"
          isDisabled={!stripe}
          isLoading={isSubmitting}
          loadingText="Add Card"
        >
          Add Card
        </Button>
      </Flex>
    </form>
  );
}
