import {
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Grid,
  GridItem,
  Skeleton,
  Stack,
  Stat,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
} from "@chakra-ui/react";
import { BarChart, ProgressBar } from "@tremor/react";
import "chart.js/auto";
import { useEffect } from "react";
import { MonthlySpent } from "../interfaces/billing";
import { getApi } from "../services/api/api-service";
import { useGlobalAlert } from "../services/global-alert/global-alert-context";
import {
  chartValueFormatter,
  createDateWithLastSecondOfTheDay,
  createDateWithMidnightTime,
  formatNumber,
  generateAllRequestTimeBetweenDates,
  getCurrentTimezone,
} from "../utils";
import { ContactSupportLink } from "./contact-support-link";

export default function MonthlySpentOverview() {
  const startTimestamp = Math.floor(
    createDateWithMidnightTime((date) => date.setDate(1)).getTime() / 1000
  );
  const endTimestamp = Math.floor(
    createDateWithLastSecondOfTheDay((date) =>
      date.setDate(date.getDate())
    ).getTime() / 1000
  );
  const granularity = "DAY";

  const monthlySpent = getApi<MonthlySpent>("/billing/monthly_spent");

  const usageStats = getApi<UsageStatsResponse>("/usage-stats", {
    startTimestamp,
    endTimestamp,
    granularity,
    timezone: getCurrentTimezone(),
  });

  const { addAlert } = useGlobalAlert();
  useEffect(() => {
    if (monthlySpent.error || usageStats.error) {
      addAlert({
        type: "error",
        body: (
          <Text>
            Unexpected error happened when loading cost summary, please retry
            and <ContactSupportLink /> if the issue persists.
          </Text>
        ),
      });
    }
  }, [usageStats.error, monthlySpent.error, addAlert]);

  if (
    usageStats.isLoading ||
    !usageStats.data ||
    monthlySpent.isLoading ||
    !monthlySpent.data
  ) {
    return (
      <Grid templateColumns="repeat(4, 1fr)" gap={5}>
        <GridItem colSpan={1}>
          <Skeleton h="450px" />
        </GridItem>
        <GridItem colSpan={3}>
          <Skeleton h="450px" />
        </GridItem>
      </Grid>
    );
  }

  const labels = generateAllRequestTimeBetweenDates(
    new Date(startTimestamp * 1000),
    new Date(endTimestamp * 1000),
    granularity
  );

  const usageCharge = labels.map((label) => {
    const rows = usageStats.data?.rows.filter(
      (row) => row.requestTime === label
    );
    return rows?.reduce(
      (acc, row) =>
        acc +
        (row.unitPrice / 1000 / 1000000) *
          (row.numInputTokens + row.numOutputTokens),
      0
    );
  });
  const usageChargeChartData = labels.map((label, index) => {
    return {
      name: label,
      "Model Usage Cost": usageCharge[index],
    };
  });

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={5}>
      <GridItem colSpan={1}>
        <Card variant="information" h="full">
          <CardHeader>Overview</CardHeader>
          <CardBody>
            <Stack h="full">
              <Stat>
                <StatLabel>Current Billing Cycle:</StatLabel>
                <StatNumber>
                  <Flex align="center" h="55px">
                    {getFirstAndLastDayOfMonth()}
                  </Flex>
                </StatNumber>
              </Stat>
              <Divider pt={0} />
              <Stat>
                <StatLabel>Estimated Billing Model Usage Charge:</StatLabel>
                <StatNumber>
                  <Flex align="center" h="55px">
                    ${formatNumber(monthlySpent.data.totalToCharge)}
                  </Flex>
                </StatNumber>
              </Stat>
              <Divider pt={0} />
              <Stat>
                <StatLabel>Remaining Monthly Credit:</StatLabel>
                <Flex justify="space-between" align="baseline">
                  <StatNumber>
                    ${formatNumber(monthlySpent.data.monthlyCreditLeft)}
                  </StatNumber>
                  <StatHelpText>
                    ${formatNumber(monthlySpent.data.totalMonthlyCredit)} total
                  </StatHelpText>
                </Flex>
                <ProgressBar
                  value={
                    (monthlySpent.data.monthlyCreditLeft /
                      monthlySpent.data.totalMonthlyCredit) *
                    100.0
                  }
                  // @ts-expect-error: actually works
                  color="#596aff"
                />
              </Stat>
              <Divider />
              <Stat>
                <StatLabel>Remaining Lifetime Credit:</StatLabel>
                <Flex justify="space-between" align="baseline">
                  <StatNumber>
                    ${formatNumber(monthlySpent.data.lifetimeCreditLeft)}
                  </StatNumber>
                  <StatHelpText>
                    ${formatNumber(monthlySpent.data.totalLifetimeCredit)} total
                  </StatHelpText>
                </Flex>
                <ProgressBar
                  value={
                    (monthlySpent.data.lifetimeCreditLeft /
                      monthlySpent.data.totalLifetimeCredit) *
                    100.0
                  }
                  // @ts-expect-error: actually works
                  color="#596aff"
                />
              </Stat>
            </Stack>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem colSpan={3}>
        <Card variant="information">
          <CardHeader>Daily model usage cost</CardHeader>
          <CardBody>
            <StatGroup>
              <Stat>
                <StatLabel>Total</StatLabel>
                <StatNumber>
                  $
                  {formatNumber(
                    usageCharge.reduce((sum, current) => sum + current, 0)
                  )}
                </StatNumber>
              </Stat>
            </StatGroup>
            <BarChart
              data={usageChargeChartData}
              index="name"
              categories={["Model Usage Cost"]}
              colors={["#596aff"]}
              valueFormatter={(n) =>
                `$${chartValueFormatter(Number(n.toFixed(3)))}`
              }
              yAxisWidth={48}
            />
          </CardBody>
        </Card>
      </GridItem>
    </Grid>
  );
}

function getFirstAndLastDayOfMonth(): string {
  const currentDate = new Date();

  const firstDayCurrentMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  const lastDayCurrentMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  const formatDate = (date: Date) =>
    date.toLocaleDateString("en-US", { month: "short", day: "numeric" });

  const firstDayFormatted = formatDate(firstDayCurrentMonth);
  const lastDayFormatted = formatDate(lastDayCurrentMonth);

  return `${firstDayFormatted} - ${lastDayFormatted}`;
}
