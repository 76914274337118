import {
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect } from "react";
import { CiMenuBurger } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import Actor from "../components/actor";
import ContactModal from "../components/contact-modal";
import { ContactSupportLink } from "../components/contact-support-link";
import { DataTable } from "../components/data-table";
import QueryModelModal from "../components/model/query-model-modal";
import {
  BaseModelResponse,
  LoRAResponse,
  ModelType,
} from "../interfaces/model";
import DashboardContentLayout from "../layouts/dashboard-content-layout";
import { getApi } from "../services/api/api-service";
import { useGlobalAlert } from "../services/global-alert/global-alert-context";

const columnHelper = createColumnHelper<BaseModelResponse>();

export default function BaseModelsPage() {
  const { data, isLoading, error } = getApi<LoRAResponse[]>("/base-model");
  const { addAlert } = useGlobalAlert();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      addAlert({
        type: "error",
        body: (
          <Text>
            Unexpected error happened when fetching base models, please retry{" "}
            and <ContactSupportLink /> if the issue persists.
          </Text>
        ),
      });
    }
  }, [error, addAlert]);

  const columns = [
    columnHelper.accessor("activeDeployment.modelName", {
      id: "modelName",
      header: "Model",
      cell: (info) => info.getValue(),
    }),
    // hide for now, should not show deploy time til we have the custom base model
    // columnHelper.accessor("activeDeployment.createdAt", {
    //   header: "Deployed At",
    //   cell: (info) => formatISOToLocal(info.getValue()),
    // }),
    columnHelper.accessor("activeDeployment.creator", {
      header: "Deployed By",
      cell: (info) => <Actor actor={info.getValue()} />,
    }),
    columnHelper.accessor("unitPrice", {
      header: "Price 1M Tokens",
      cell: (info) => `${(info.getValue() / 100).toFixed(2)} USD`,
    }),
    columnHelper.display({
      id: "action",
      header: "",
      meta: {
        isActionColumn: true,
      },
      cell: ({ row }) => (
        <Menu>
          <MenuButton>
            <CiMenuBurger fill="gray" />
          </MenuButton>
          <MenuList>
            <QueryModelModal
              modelType={ModelType.BASE_MODE}
              name={row.original.name}
            >
              <MenuItem fontSize="sm" color="gray.600">
                Query
              </MenuItem>
            </QueryModelModal>
            <MenuItem
              fontSize="sm"
              color="gray.600"
              onClick={() => navigate(encodeURIComponent(row.original.name))}
            >
              Details
            </MenuItem>
          </MenuList>
        </Menu>
      ),
    }),
  ];

  return (
    <DashboardContentLayout
      subNavSection="Overview"
      mainTitle="Base Models"
      mainTitleHelperText="Base models are Large Language Models (LLMs) capable of running independently. These base models can either be foundational for LoRAs or directly queried."
      sectionTitle="Manage Base Models"
      sectionTitleHelperText={
        <Text color="gray.600" fontSize="sm" pt={2}>
          Below are base models we provide and support, please{" "}
          <ContactModal>
            <Link>contact us</Link>
          </ContactModal>{" "}
          if you have the need of custom base models.
        </Text>
      }
    >
      <DataTable
        columns={columns}
        data={data!}
        isLoading={isLoading}
        defaultSorting={[
          {
            id: "modelName",
            desc: true,
          },
        ]}
        onRowClick={(row) => {
          navigate(`/base-model/${encodeURIComponent(row.original.name)}`);
        }}
      />
      <></>
    </DashboardContentLayout>
  );
}
