import { AbsoluteCenter } from "@chakra-ui/react";
import { SignUp, useAuth } from "@clerk/clerk-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingIndicator from "../components/loading-indicator";

export default function SignUpPage() {
  const { userId, isLoaded } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      navigate("/");
    }
  }, [userId, navigate]);

  if (!isLoaded) {
    return <LoadingIndicator />;
  }
  return (
    <AbsoluteCenter>
      <SignUp afterSignUpUrl="/select-org" signInUrl="/sign-in" />
    </AbsoluteCenter>
  );
}
