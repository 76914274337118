import { ChatMessage as ChatMessageInterface } from "./common/types";
import { cn } from "./lib/utils";

import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { vs } from "react-syntax-highlighter/dist/esm/styles/hljs";

import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import {
  ChatCompletionContentPart,
  ChatCompletionContentPartImage,
  ChatCompletionContentPartText,
} from "openai/resources/index.mjs";
import ChatImage from "../chat-image";
import ChatAvatar from "./chat-avatar";

export function extractThinkingIfPresent(content: string) {
  if (content) {
    const thinkingTag = "</thinking>";
    const tagPosition = content.indexOf(thinkingTag);

    if (tagPosition !== -1) {
      const part1 = content.slice("<thinking>".length, tagPosition);
      const part2 = content.slice(tagPosition + thinkingTag.length);
      return [part1, part2];
    } else if (content.startsWith("<thinking>")) {
      return [content.slice("<thinking>".length), null];
    } else {
      return [null, content];
    }
  }

  return [null, null];
}

export default function ChatMessage(chatMessage: ChatMessageInterface) {
  let functionCallContent, functionResponseContent;

  if (chatMessage.toolCalls) {
    functionCallContent = chatMessage.toolCalls
      .map((toolCall) => {
        try {
          const deserializedArguments = JSON.parse(toolCall.function.arguments);
          return JSON.stringify({
            name: toolCall.function.name,
            arguments: deserializedArguments,
          })
            .replace(/:/g, ": ")
            .replace(/,/g, ", ");
        } catch (e) {
          return JSON.stringify(toolCall.function);
        }
      })
      .join("\n");
  }
  if (chatMessage.metadata?.functionResponse) {
    functionResponseContent = chatMessage.metadata.functionResponse
      .replace(/:/g, ": ")
      .replace(/,/g, ", ");
  }

  function renderUserMessageContent(
    content: string | Array<ChatCompletionContentPart>
  ) {
    if (typeof content === "string") {
      return content;
    }

    const textContent: ChatCompletionContentPartText = content.find(
      (part) => part.type === "text"
    )! as ChatCompletionContentPartText;
    const imageContent: ChatCompletionContentPartImage[] = content
      .filter((part) => part.type === "image_url")
      .map((part) => part as ChatCompletionContentPartImage);

    return (
      <Stack>
        {imageContent.length > 0 && (
          <Flex>
            {imageContent.map((image, index) => (
              <ChatImage key={index} imageBase64={image.image_url.url} />
            ))}
          </Flex>
        )}
        <Text fontSize="md">{textContent.text}</Text>
      </Stack>
    );
  }

  return (
    <div
      className={cn(
        "flex items-start gap-4 pr-5 pt-2",
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
        chatMessage.metadata?.used === false ? "opacity-40" : undefined
      )}
    >
      <ChatAvatar role={chatMessage.role} />
      <div className="group flex flex-1 justify-between gap-2 items-center">
        <div className="flex-1">
          {functionResponseContent && (
            <Box
              className="p-1 mb-2"
              maxW={{ base: "70vw", md: "100%" }}
              bgColor="highlightBg"
            >
              <Flex className="flex items-center gap-2" flexWrap="wrap">
                <div
                  style={{
                    textWrap: "nowrap",
                  }}
                >
                  Function execution result:
                </div>
                <SyntaxHighlighter
                  language={"json"}
                  style={vs}
                  customStyle={{
                    padding: "0",
                    backgroundColor: "rgba(237, 239, 253)",
                    borderBottomLeftRadius: "0.5em",
                    borderBottomRightRadius: "0.5em",
                  }}
                  wrapLines={true}
                  wrapLongLines={true}
                >
                  {functionResponseContent!}
                </SyntaxHighlighter>
              </Flex>
            </Box>
          )}
          {chatMessage.role === "assistant" &&
          chatMessage.metadata?.loading === true ? (
            <div className="flex justify-start items-center">
              <div className="flex space-x-1 h-6 ">
                <span className="typing-dots animate-loader"></span>
                <span className="typing-dots animate-loader animation-delay-200"></span>
                <span className="typing-dots animate-loader animation-delay-400"></span>
              </div>
            </div>
          ) : null}
          {chatMessage.role === "assistant" &&
            chatMessage.content.startsWith("<thinking>") && (
              <Box
                className="p-1 mb-2"
                maxW={{ base: "70vw", md: "100%" }}
                bgColor="highlightBg"
              >
                <Flex className="flex items-center gap-2" flexWrap="wrap">
                  <div
                    style={{
                      textWrap: "nowrap",
                    }}
                  >
                    Thinking:
                  </div>
                  <Text
                    className="prose break-words dark:prose-invert prose-p:leading-relaxed prose-pre:p-0 pt-1"
                    fontSize="md"
                  >
                    {extractThinkingIfPresent(chatMessage.content)[0]}
                  </Text>
                </Flex>
              </Box>
            )}
          {functionCallContent && (
            <Box
              className="p-1 mb-2"
              maxW={{ base: "70vw", md: "100%" }}
              bgColor="highlightBg"
            >
              <Flex className="flex items-center gap-2" flexWrap="wrap">
                <div
                  style={{
                    textWrap: "nowrap",
                  }}
                >
                  Function call response:
                </div>
                <SyntaxHighlighter
                  language={"json"}
                  style={vs}
                  customStyle={{
                    padding: "0",
                    backgroundColor: "rgba(237, 239, 253)",
                    borderBottomLeftRadius: "0.5em",
                    borderBottomRightRadius: "0.5em",
                  }}
                  wrapLines={true}
                  wrapLongLines={true}
                >
                  {functionCallContent}
                </SyntaxHighlighter>
              </Flex>
            </Box>
          )}
          <Text
            className="prose break-words dark:prose-invert prose-p:leading-relaxed prose-pre:p-0 pt-1"
            fontSize="md"
          >
            {chatMessage.role !== "assistant"
              ? renderUserMessageContent(chatMessage.content)
              : extractThinkingIfPresent(chatMessage.content)[1]}
          </Text>
          {chatMessage?.metadata?.averageTokenTime ? (
            <div className="flex justify-start md:justify-end items-center mt-1">
              <span className="text-xs text-zinc-400">
                {chatMessage.metadata.firstTokenTime?.toFixed(0) || "-- "}ms
                initial latency{" | "}
                {chatMessage.metadata.averageTokenTime.toFixed(2)} tokens/s
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
