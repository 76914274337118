import { useNavigate, useParams } from "react-router-dom";
import { ModelType } from "../interfaces/model";

import ModelDetails from "../components/model/model-details";

export default function BaseModelDetailsPage() {
  const { baseModelName } = useParams();

  const navigate = useNavigate();
  if (!baseModelName) {
    navigate("/base-model");
    return <></>;
  }

  return (
    <ModelDetails
      modelName={baseModelName}
      modelType={ModelType.BASE_MODE}
      mainTitle="Base Models"
      mainTitleHelperText="Base models are Large Language Models (LLMs) capable of running independently. These base models can either be foundational for LoRAs or directly queried."
    />
  );
}
