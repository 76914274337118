import { RedirectToSignIn, SignedIn, SignedOut } from "@clerk/clerk-react";
import DashboardLayout from "../layouts/dashboard-layout";
import { DashboardHomePage } from "./dashboard-home-page";

export default function IndexPage() {
  return (
    <>
      <SignedIn>
        <DashboardLayout>
          <DashboardHomePage />
        </DashboardLayout>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  );
}
