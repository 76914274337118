import { Alert, AlertIcon, CloseButton, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useGlobalAlert } from "../services/global-alert/global-alert-context";

export function GlobalAlert() {
  const { currentAlert, clear } = useGlobalAlert();

  // State to store the current window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth((window.innerWidth - 250) * 0.75);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Flex h={"60px"} align="center">
      {currentAlert && (
        <Alert
          position="fixed"
          zIndex="1000"
          status={currentAlert.type}
          maxH="full-content"
          h="fit-content"
          variant="left-accent"
          w={windowWidth}
        >
          <Flex justify="space-between" w="full">
            <Flex align="center">
              <AlertIcon />
              {currentAlert.body}
            </Flex>
            <CloseButton onClick={clear} />
          </Flex>
        </Alert>
      )}
    </Flex>
  );
}
