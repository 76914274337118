import {
  Box,
  Card,
  Divider,
  Heading,
  Icon,
  Link,
  SimpleGrid,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useClerk, useUser } from "@clerk/clerk-react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement } from "react";
import { IconType } from "react-icons";
import { GrDeploy } from "react-icons/gr";
import { LuBookOpen } from "react-icons/lu";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { RiUserAddLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { ContactSupportLink } from "../components/contact-support-link";
import DeployLoRAModal from "../components/model/deploy-lora-modal";
import MonthlySpentOverview from "../components/monthly-spent-overview";
import { SectionTitle } from "../components/section-title";
import UsageStats from "../components/usage-stats/usage-stats";
import DashboardContentLayout from "../layouts/dashboard-content-layout";
import { documentationsUrl } from "../utils";

function renderCTA(
  content: ReactElement,
  icon: IconType,
  onClick: () => void = () => {}
) {
  return (
    <Card
      h="150px"
      px={3}
      justify="center"
      align="center"
      onClick={onClick}
      cursor="pointer"
      textAlign="center"
      _hover={{ background: "highlightBg" }}
    >
      <Box pb={2}>
        <Icon boxSize="5" as={icon} color="#596aff" />
      </Box>
      {content}
    </Card>
  );
}

export function DashboardHomePage() {
  const user = useUser();
  const navigate = useNavigate();
  const { openOrganizationProfile } = useClerk();
  const queryClient = useQueryClient();
  return (
    <DashboardContentLayout
      subNavSection="Overview"
      mainTitle="Home"
      mainTitleHelperText={
        <Text color="gray.600" fontSize="sm" pt={2}>
          Thank you for using Empower! We are currently in the beta phase and
          are dedicated to improving your experience. Please{" "}
          <ContactSupportLink /> if you encounter any issues or have
          suggestions. Your feedback is invaluable to us, and we're here to
          help. Thank you for your support and understanding.
        </Text>
      }
      sectionTitle={`Welcome ${user.user?.firstName}`}
    >
      <Stack spacing={8} pt={2}>
        <SimpleGrid columns={[2, 0, 4]} spacing={4}>
          <DeployLoRAModal onDeployed={async () => {}}>
            {renderCTA(<Text as="b">Deploy a new LoRA</Text>, GrDeploy)}
          </DeployLoRAModal>
          {renderCTA(
            <Text as="b">Manage existing LoRAs</Text>,
            MdOutlineDashboardCustomize,
            () => navigate("/lora")
          )}
          {renderCTA(<Text as="b">Invite teammates</Text>, RiUserAddLine, () =>
            openOrganizationProfile()
          )}
          {renderCTA(
            <Text as="b">Learn more about Empower</Text>,
            LuBookOpen,
            () => window.open(documentationsUrl, "_blank")
          )}
        </SimpleGrid>
        <Divider />
        <Stack spacing={5}>
          <SectionTitle
            mainTitle={
              <Heading as="h2" size={"md"} fontWeight="500">
                Usage{" "}
                <Link
                  textDecoration="none"
                  fontSize="xx-large"
                  _hover={{ textDecoration: "none" }}
                  onClick={() =>
                    queryClient.resetQueries({
                      predicate: (query) => {
                        const queryKey = String(query.queryKey[0]);

                        return (
                          queryKey.includes("billing") ||
                          queryKey.includes("usage")
                        );
                      },
                    })
                  }
                >
                  ⟳
                </Link>
              </Heading>
            }
            helperText="Overview of platform usage"
          />
          <Tabs variant="enclosed">
            <TabList>
              <Tab
                _selected={{
                  borderColor: "inherit",
                  borderBottomColor: "white",
                  color: "action",
                }}
              >
                Activity
              </Tab>
              <Tab
                _selected={{
                  borderColor: "inherit",
                  borderBottomColor: "white",
                  color: "action",
                }}
              >
                Cost
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Spacer h="10px" />
                <UsageStats />
              </TabPanel>
              <TabPanel>
                <Spacer h="10px" />
                <MonthlySpentOverview />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
      </Stack>
    </DashboardContentLayout>
  );
}
