import { AbsoluteCenter, Text } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { ReactNode } from "react";
import { AiOutlineExperiment } from "react-icons/ai";
import { FiHome } from "react-icons/fi";
import { IoChatboxOutline } from "react-icons/io5";
import { Outlet } from "react-router-dom";
import { ContactSupportLink } from "../components/contact-support-link";
import { Sidenav, SidenavContainer, SidenavItem } from "../components/sidenav";
import { SidenavItemGroup } from "../components/sidenav/sidenav-items";

interface AnonymousDashboardLayoutProps {
  children?: ReactNode;
}

export default function AnonymousDashboardLayout({
  children,
}: AnonymousDashboardLayoutProps) {
  const mainNavItems: (SidenavItem | SidenavItemGroup)[] = [
    {
      icon: IoChatboxOutline,
      label: "Empower Functions Chat Demo",
      to: "/chat-demo",
    },
    { icon: AiOutlineExperiment, label: "Playground", to: "/playground" },
    { icon: FiHome, label: "Sign-in", to: "/sign-in" },
  ];
  return (
    <SidenavContainer
      sidenav={<Sidenav mainNavItems={mainNavItems} isAnonymous={true} />}
    >
      <Sentry.ErrorBoundary
        fallback={
          <AbsoluteCenter>
            <Text fontSize="xl">
              Unexpected errors happened, please retry and{" "}
              <ContactSupportLink /> if the issue persists.
            </Text>
          </AbsoluteCenter>
        }
      >
        {children}
        <Outlet />
      </Sentry.ErrorBoundary>
    </SidenavContainer>
  );
}
