import {
  Badge,
  Box,
  Divider,
  Flex,
  Grid,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect } from "react";
import { CiMenuBurger } from "react-icons/ci";
import { PaymentMethodResponse } from "../../interfaces/billing";
import { getApi, postApiMutation } from "../../services/api/api-service";
import { useBilling } from "../../services/billing/billing-context";
import { useGlobalAlert } from "../../services/global-alert/global-alert-context";
import { ContactSupportLink } from "../contact-support-link";
import { DataTable } from "../data-table";
import { DeleteConfirmationDialog } from "../deletion-confirmation-dialog";
import { SectionTitle } from "../section-title";
import AddCreditCardComponent from "./add-credit-card";

const columnHelper = createColumnHelper<PaymentMethodResponse>();

export default function PaymentMethods() {
  const { addAlert } = useGlobalAlert();
  const { setHasPayment } = useBilling();
  const queryClient = useQueryClient();

  const { data, isLoading, isRefetching, error, refetch } = getApi<
    Array<PaymentMethodResponse>
  >("billing/payment_methods");

  const { mutate } = postApiMutation<object, object>(
    `/billing/payment_methods`
  );

  useEffect(() => {
    if (error) {
      addAlert({
        type: "error",
        body: (
          <Text>
            Error fetching payment methods, please retry and{" "}
            <ContactSupportLink /> if the issue persists.
          </Text>
        ),
      });
    }
  }, [error, addAlert]);

  async function onSetDefault(paymentMethodId: string) {
    mutate(
      {
        paymentMethodId,
        isDefault: true,
      },
      {
        onError: () => {
          addAlert({
            type: "error",
            body: <Text>Failed to change default payment method</Text>,
          });
        },
        onSuccess: async () => {
          addAlert({
            type: "success",
            body: <Text>Successfully changed default payment method</Text>,
          });
          queryClient.resetQueries();
        },
      }
    );
  }

  const columns = [
    columnHelper.accessor("cardDetails.last4", {
      header: "Card",
      cell: (info) => `**** **** **** ${info.getValue()}`,
    }),
    columnHelper.accessor("cardDetails", {
      header: "Expires",
      cell: (info) =>
        `${info.getValue().expMonth} / ${info.getValue().expYear}`,
    }),
    columnHelper.accessor("isDefault", {
      header: "",
      cell: (info) =>
        info.getValue() ? <Badge colorScheme="green">Default</Badge> : <></>,
    }),
    columnHelper.display({
      id: "action",
      header: "",
      meta: {
        isActionColumn: true,
      },
      cell: ({ row }) => {
        return (
          <Menu>
            <MenuButton zIndex={100}>
              <CiMenuBurger fill="gray" />
            </MenuButton>
            <MenuList>
              <MenuItem
                fontSize="sm"
                onClick={async () => await onSetDefault(row.original.id)}
                key="set-default"
                isDisabled={row.original.isDefault}
              >
                Set default
              </MenuItem>
              <Divider />
              <DeleteConfirmationDialog
                entityName="Credit card"
                deletingName={` with last 4 digits ${row.original.cardDetails.last4}`}
                deletingKey={row.original.id}
                deletionApiPath="/billing/payment_methods"
                onDeleted={async () => {
                  await refetch();
                }}
              >
                <MenuItem
                  fontSize="sm"
                  color="red"
                  isDisabled={row.original.isDefault}
                  key="delete"
                >
                  Delete
                </MenuItem>
              </DeleteConfirmationDialog>
            </MenuList>
          </Menu>
        );
      },
    }),
  ];

  return (
    <>
      <Flex w="100%" align="center" justify="space-between">
        <SectionTitle
          mainTitle="Payment Methods"
          helperText="Manage payment methods."
        />{" "}
        <AddCreditCardComponent
          onFinished={() => {
            refetch();
            setHasPayment(true);
          }}
        />
      </Flex>
      <Spacer py={2} />
      <DataTable
        columns={columns}
        isLoading={isLoading || isRefetching}
        data={data!}
        emptyView={
          <Flex justify="center" align="center" h="60px">
            <Text>
              No payment method available, please add your payment method to
              unlock the premium product plans.
            </Text>
          </Flex>
        }
      />
      <Grid placeItems="center">
        <Box w="4xl" p={8} textAlign="center"></Box>
      </Grid>
    </>
  );
}
