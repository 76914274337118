import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import React, { ReactElement } from "react";
import CodeBlock from "../code-block";

interface SystemPromptAndFunctionsPromptModalProps {
  children: ReactElement<{
    onClick: () => void;
  }>;
  systemPrompt: string;
  functionSpecs: string;
}

export default function SystemPromptAndFunctionsPromptModal({
  children,
  systemPrompt,
  functionSpecs,
}: SystemPromptAndFunctionsPromptModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {React.cloneElement(children, {
        onClick: onOpen,
      })}
      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="800px">
          <ModalHeader>System Prompt and Function Definitions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs>
              <TabList>
                <Tab>System Prompt</Tab>
                <Tab>Function Definitions</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <CodeBlock code={systemPrompt} language="text" />
                </TabPanel>
                <TabPanel>
                  <CodeBlock code={functionSpecs} language="json" />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
