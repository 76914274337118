import {
  Divider,
  Flex,
  Icon,
  Image,
  Link,
  List,
  ListItem,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { OrganizationSwitcher, UserButton } from "@clerk/clerk-react";
import { AiOutlineQuestionCircle } from "react-icons/ai";

import { LuBookOpen } from "react-icons/lu";
import {
  AFTER_SIGN_IN_REDIRECTION_TARGET,
  documentationsUrl,
} from "../../utils";
import ContactModal from "../contact-modal";
import SidenavItems, { SidenavItem, SidenavItemGroup } from "./sidenav-items";

export interface SidenavProps {
  mainNavItems: (SidenavItem | SidenavItemGroup)[];
  isAnonymous: boolean;
}

export function Sidenav({ isAnonymous, mainNavItems }: SidenavProps) {
  return (
    <>
      <VStack
        as="nav"
        spacing="0"
        h="full"
        display={{ base: "none", md: "flex" }}
      >
        <Image
          src="/logo.png"
          w="full"
          objectFit="scale-down"
          pt="20px"
          pb="50px"
          px={3}
        />
        <SidenavItems navItems={mainNavItems} />
        <Spacer />
        <VStack spacing={0} w="full" p={0}>
          <Flex justifyContent={"left"} justifyItems={"left"} w="full">
            <Text ml={4} fontSize="sm" fontWeight={600}>
              Support
            </Text>
          </Flex>
          <List spacing={1} w="full" p={3}>
            <ListItem key="document">
              <Link
                display="block"
                variant="sideNavItem"
                href={documentationsUrl}
                target="_blank"
              >
                <Flex alignItems="center" pt={2} pb={2} pl={4}>
                  <Icon boxSize="5" as={LuBookOpen} />
                  <Text ml={3} fontSize="sm">
                    Documentation
                  </Text>
                </Flex>
              </Link>
            </ListItem>
            <ListItem key="document">
              <ContactModal>
                <Link display="block" variant="sideNavItem" href="#">
                  <Flex alignItems="center" pt={2} pb={2} pl={4}>
                    <Icon boxSize="5" as={AiOutlineQuestionCircle} />
                    <Text ml={3} fontSize="sm">
                      Support
                    </Text>
                  </Flex>
                </Link>
              </ContactModal>
            </ListItem>
          </List>
        </VStack>
        {!isAnonymous && (
          <VStack w="full" pb={2}>
            <Divider size="2" mb={2} />
            <OrganizationSwitcher
              hidePersonal
              afterLeaveOrganizationUrl="/select-org"
              afterSelectOrganizationUrl={AFTER_SIGN_IN_REDIRECTION_TARGET}
            />
            <Divider size="2" />
            <UserButton
              afterSignOutUrl="/sign-in"
              afterSwitchSessionUrl="/sign-in"
              showName={true}
            />
          </VStack>
        )}
      </VStack>
    </>
  );
}

export default Sidenav;
