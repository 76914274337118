import { Button, Flex, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { PiKey } from "react-icons/pi";
import Actor from "../components/actor";
import ApiKeyContainer from "../components/api-key/api-key-container";
import CreateApiKeyModal from "../components/api-key/create-api-key-modal";
import { ContactSupportLink } from "../components/contact-support-link";
import { DataTable } from "../components/data-table";
import { DeleteConfirmationDialog } from "../components/deletion-confirmation-dialog";
import { ApiKeyResponse } from "../interfaces/api-key";
import DashboardContentLayout from "../layouts/dashboard-content-layout";
import { getApi } from "../services/api/api-service";
import { useGlobalAlert } from "../services/global-alert/global-alert-context";
import { formatISOToLocal } from "../utils";

const columnHelper = createColumnHelper<ApiKeyResponse>();

export default function ApiKeysPage() {
  const { data, isLoading, error, refetch } =
    getApi<ApiKeyResponse[]>("/api-key");

  const { addAlert } = useGlobalAlert();

  useEffect(() => {
    if (error) {
      addAlert({
        type: "error",
        body: (
          <Text>
            Unexpected error happened when fetching api keys, please retry{" "}
            <ContactSupportLink /> if the issue persists.
          </Text>
        ),
      });
    }
  }, [error]);

  const columns = [
    columnHelper.accessor("name", {
      header: "Name",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("apiKey", {
      header: "Api Key",
      cell: (info) => <ApiKeyContainer apiKey={info.getValue()} />,
    }),
    columnHelper.accessor("createdAt", {
      header: "Created At",
      cell: (info) => formatISOToLocal(info.getValue()),
    }),
    columnHelper.accessor("creator", {
      header: "Created By",
      cell: (info) => <Actor actor={info.getValue()} />,
    }),
    columnHelper.display({
      id: "action",
      header: "",
      cell: ({ row }) => (
        <DeleteConfirmationDialog
          title="Delete Aip Key"
          entityName="Api key"
          deletingName={row.original.name}
          deletingKey={row.original.apiKey}
          deletionApiPath="/api-key"
          onDeleted={async () => {
            await refetch();
          }}
        >
          <Button>
            <MdDeleteOutline />
          </Button>
        </DeleteConfirmationDialog>
      ),
    }),
  ];

  return (
    <DashboardContentLayout
      subNavSection="Overview"
      mainTitle="Api Keys"
      mainTitleHelperText="API keys are used to programmatically authenticate against an 
      organization and are shared throughout the organization."
      sectionTitle="Manage Api Keys"
      sectionTitleHelperText="Create new api keys and manage existing api keys."
      sectionTitleCallToAction={
        <CreateApiKeyModal
          onCreated={async () => {
            await refetch();
          }}
        >
          <Button variant="cta" leftIcon={<PiKey />}>
            New Api Key
          </Button>
        </CreateApiKeyModal>
      }
    >
      <DataTable
        columns={columns}
        data={data!}
        isLoading={isLoading}
        defaultSorting={[
          {
            id: "createdAt",
            desc: true,
          },
        ]}
        emptyView={
          <Flex justify="center" align="center" h="60px">
            <Text>
              No api key, click the "New Api Key" to create an api key to use
              the api.
            </Text>
          </Flex>
        }
      />
    </DashboardContentLayout>
  );
}
