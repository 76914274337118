import {
  Badge,
  Button,
  Flex,
  Heading,
  HStack,
  Input,
  Link,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { createColumnHelper, Row } from "@tanstack/react-table";
import {
  DateRangePicker,
  DateRangePickerItem,
  ProgressBar,
} from "@tremor/react";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { vs } from "react-syntax-highlighter/dist/esm/styles/hljs";
import ChatImage from "../components/chat-image";
import { DataTable } from "../components/data-table";
import DashboardContentLayout from "../layouts/dashboard-content-layout";
import {
  createDateWithLastSecondOfTheDay,
  createDateWithMidnightTime,
  formatNumber,
  formatTimestampToLocal,
  generateRandomNumber,
  getRandomElement,
} from "../utils";
interface DatasetRow {
  id: string;
  capturedAt: number;
  status: string;
  inputTokens: number;
  outputTokens: number;
  requestedModel: string;
  prompt: string;
  response: string;
}

function generateRandomString(length: number = 29): string {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

const presetPeriods = [
  {
    key: "today",
    label: "Today",
    fromDateGenerator: () => createDateWithMidnightTime(),
  },
  {
    key: "l7d",
    label: "Last 7 days",
    fromDateGenerator: () =>
      createDateWithMidnightTime((date) => date.setDate(date.getDate() - 6)),
  },
  {
    key: "l30d",
    label: "Last 30 days",
    fromDateGenerator: () =>
      createDateWithMidnightTime((date) => date.setDate(date.getDate() - 29)),
  },
  {
    key: "mtd",
    label: "Month to Date",
    fromDateGenerator: () =>
      createDateWithMidnightTime((date) => date.setDate(1)),
  },
  {
    key: "ytd",
    label: "Year to Date",
    fromDateGenerator: () =>
      createDateWithMidnightTime((date) =>
        date.setFullYear(date.getFullYear(), 0, 1)
      ),
  },
];

function generateRandomRow() {
  const inputToken = generateRandomNumber(3498, 11492);
  return {
    id: `chatcmpl-${generateRandomString()}`,
    capturedAt: new Date().getTime() / 1000 - generateRandomNumber(0, 3600),
    status: getRandomElement(["verified", "rejected", "pending"]),
    inputTokens: inputToken,
    outputTokens: Math.floor(inputToken / 5) - generateRandomNumber(0, 100),
    requestedModel: "gpt-4o",
    prompt: "",
    response: "",
  };
}

const dataset: DatasetRow[] = [
  {
    id: `chatcmpl-${generateRandomString()}`,
    capturedAt: new Date().getTime() / 1000,
    status: "verified",
    inputTokens: 2621,
    outputTokens: 586,
    requestedModel: "gpt-4o",
    prompt:
      'Extract information from three receipts and represent it in a structured JSON format. The JSON should include details such as the name of the items, the price, the quantity, any subtotals, discounts, tax amounts, total amounts, and payment methods (cash, credit card). If there are any sub-items or additional details associated with a primary item, include them as nested structures.\n\nUse the following JSON schema as guide:\n[{\n  "menu": {\n    "nm": "string", \n    "num": "string", \n    "cnt": "string", \n    "price": "string", \n    "itemsubtotal": "string" \n  }, \n  "sub_total": { \n    "subtotal_price": "string", \n    "discount_price": "string", \n    "tax_price": "string" \n  }, \n  "total": {    "total_price": "string", \n    "creditcardprice": "string", \n    "cashprice": "string", \n    "changeprice": "string", // Change returned (if applicable) \n    "menuqty_cnt": "string" \n  } \n }]\'',
    response:
      '[\n  {\n    "menu": [\n      {\n        "nm": "TICKET CP",\n        "num": "901016",\n        "cnt": "2",\n        "price": "60,000",\n        "itemsubtotal": "60,000"\n      }\n    ],\n    "sub_total": {\n      "subtotal_price": "60,000",\n      "discount_price": "-0,000",\n      "tax_price": "5,455"\n    },\n    "total": {\n      "total_price": "60,000",\n      "creditcardprice": "60,000",\n      "cashprice": "0",\n      "changeprice": "0",\n      "menuqty_cnt": "2"\n    }\n  },\n  {\n    "menu": [\n      {\n        "nm": "J.STB PROMO",\n        "num": "",\n        "cnt": "1",\n        "price": "17,500",\n        "itemsubtotal": "17,500"\n      },\n      {\n        "nm": "Y.B.BAT",\n        "num": "",\n        "cnt": "1",\n        "price": "46,000",\n        "itemsubtotal": "46,000"\n      },\n      {\n        "nm": "Y.BASO PROM",\n        "num": "",\n        "cnt": "1",\n        "price": "27,500",\n        "itemsubtotal": "27,500"\n      }\n    ],\n    "sub_total": {\n      "subtotal_price": "91,000",\n      "discount_price": "0",\n      "tax_price": "0"\n    },\n    "total": {\n      "total_price": "91,000",\n      "creditcardprice": "0",\n      "cashprice": "91,000",\n      "changeprice": "0",\n      "menuqty_cnt": "3"\n    }\n  },\n  {\n    "menu": [\n      {\n        "nm": "JASMINE MT (L)",\n        "num": "",\n        "cnt": "1",\n        "price": "24,000",\n        "itemsubtotal": "24,000"\n      },\n      {\n        "nm": "COCONUT JELLY (L)",\n        "num": "",\n        "cnt": "1",\n        "price": "4,000",\n        "itemsubtotal": "4,000"\n      }\n    ],\n    "sub_total": {\n      "subtotal_price": "28,000",\n      "discount_price": "0",\n      "tax_price": "0"\n    },\n    "total": {\n      "total_price": "28,000",\n      "creditcardprice": "0",\n      "cashprice": "100,000",\n      "changeprice": "72,000",\n      "menuqty_cnt": "2"\n    }\n  }\n]\n```',
  },
  generateRandomRow(),
  generateRandomRow(),
  generateRandomRow(),
  generateRandomRow(),
  generateRandomRow(),
  generateRandomRow(),
  generateRandomRow(),
  generateRandomRow(),
  generateRandomRow(),
];

const columnHelper = createColumnHelper<DatasetRow>();

const columns = [
  columnHelper.accessor("id", {
    header: "ID",
    id: "id",
    enableSorting: false,
    cell: (info) => (
      <Link
        textDecoration="none"
        fontSize="medium"
        onClick={() => info.row.toggleExpanded()}
      >
        {info.getValue()}
      </Link>
    ),
  }),
  columnHelper.accessor("capturedAt", {
    header: "Captured At",
    id: "capturedAt",
    enableSorting: true,
    cell: (info) => (
      <Text fontSize="medium">{formatTimestampToLocal(info.getValue())}</Text>
    ),
  }),
  columnHelper.accessor("inputTokens", {
    header: "Input",
    id: "inputTokens",
    enableSorting: true,
    cell: (info) => (
      <Text fontSize={"medium"}>{formatNumber(info.getValue())}</Text>
    ),
  }),
  columnHelper.accessor("outputTokens", {
    header: "Output",
    id: "outputTokens",
    enableSorting: true,
    cell: (info) => (
      <Text fontSize={"medium"}>{formatNumber(info.getValue())}</Text>
    ),
  }),
  columnHelper.accessor("requestedModel", {
    header: "Model",
    id: "requestedModel",
    enableSorting: true,
    cell: (info) => <Text fontSize={"medium"}>{info.getValue()}</Text>,
  }),
  columnHelper.accessor("status", {
    header: "Status",
    id: "status",
    enableSorting: true,
    cell: (info) => {
      if (info.getValue() === "verified") {
        return <Badge colorScheme="green">Verified</Badge>;
      } else if (info.getValue() === "rejected") {
        return <Badge colorScheme="red">Rejected</Badge>;
      } else {
        return <Badge colorScheme="gray">Pending</Badge>;
      }
    },
  }),
];

function expandedRowRenderer(row: Row<DatasetRow>) {
  return (
    <HStack w="100%" gap={10} h="300px" pl={4}>
      <Flex verticalAlign="top" h="100%">
        <Stack>
          <Text>
            <b>Prompt</b>
          </Text>
          <Flex
            w="550px" // or a specific width like "300px"
            h="200px"
            // overflowY="scroll" // enables vertical scrolling
            // m={1} // padding inside the box
            flex={1} // take up all available space
            flexDir="column-reverse"
          >
            <SyntaxHighlighter
              language={"json"}
              style={vs}
              customStyle={{
                padding: "0",
                fontSize: 12,
              }}
              wrapLines={true}
              wrapLongLines={true}
            >
              {row.original.prompt}
            </SyntaxHighlighter>
          </Flex>
        </Stack>
      </Flex>
      <Flex verticalAlign="top" h="100%">
        <Stack>
          <Text>
            <b>Images</b>
          </Text>
          <Stack overflow="hidden">
            <ChatImage imageBase64="http://localhost:3000/files/demo/0.png" />
            <ChatImage imageBase64="http://localhost:3000/files/demo/1.png" />
            <ChatImage imageBase64="http://localhost:3000/files/demo/2.png" />
          </Stack>
        </Stack>
      </Flex>
      <Flex verticalAlign="top" h="100%">
        <Stack>
          <Text>
            <b>Response</b>
          </Text>
          <Flex
            w="350px" // or a specific width like "300px"
            h="250px" // height of the scrollable area
            // overflowY="scroll" // enables vertical scrolling
            // p={4} // padding inside the box
            flex={1} // take up all available space
            flexDir="column-reverse"
          >
            <SyntaxHighlighter
              language={"json"}
              style={vs}
              customStyle={{
                padding: "0",
                fontSize: 12,
              }}
              wrapLines={true}
              wrapLongLines={true}
            >
              {row.original.response}
            </SyntaxHighlighter>
          </Flex>
        </Stack>
      </Flex>
      {/* <Flex verticalAlign="top" h="100%">
        <Stack>
          <Text>
            <b>Parameters</b>
          </Text>
          <Flex
            w="300px" // or a specific width like "300px"
            h="300px" // height of the scrollable area
            // overflowY="scroll" // enables vertical scrolling
            // p={4} // padding inside the box
            flex={1} // take up all available space
            // flexDir="column-reverse"
          >
            <List>
              <ListItem py={1}>temperature: 0.1</ListItem>
              <ListItem py={1}>
                response_format: {'{ "type": "json_object" }'}
              </ListItem>
            </List>
          </Flex>
        </Stack>
      </Flex> */}
    </HStack>
  );
}

export const datasetTotal = 4201;
export const datasetTotalVerified = 3102;

export default function DatasetDetailPage() {
  return (
    <DashboardContentLayout
      subNavSection="Overview"
      mainTitle="Dataset"
      mainTitleHelperText="View and manage collected data."
      sectionTitle={
        <Heading as="h3" fontSize={16} fontWeight="500">
          Collected Requests for Task:{" "}
          <Link textDecoration="none"> Receipt Items Extraction</Link>
        </Heading>
      }
      sectionTitleHelperText={
        <Flex w="1000px" align="center" gap={3}>
          <Text pt={2} fontSize="14">
            <b>Total:</b> {formatNumber(datasetTotal)} | <b>Verified:</b>{" "}
            {formatNumber(datasetTotalVerified)} |
          </Text>
          <ProgressBar
            value={(datasetTotalVerified / 5000) * 100.0}
            className="w-[200px] pt-2"
            // @ts-expect-error: actually works
            color="#596aff"
          />
          <Text pt={2} fontSize="14">
            {formatNumber(5000 - datasetTotalVerified)} more verified requests
            needed to start training
          </Text>
        </Flex>
      }
    >
      <Tabs variant="enclosed">
        <TabList>
          <Tab
            _selected={{
              borderColor: "inherit",
              borderBottomColor: "white",
              color: "action",
            }}
          >
            Data
          </Tab>
          <Tab
            _selected={{
              borderColor: "inherit",
              borderBottomColor: "white",
              color: "action",
            }}
          >
            Activity
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Stack>
              <Spacer h="5px" />
              <Flex gap={2} justifyContent="between" pb={2}>
                <Flex w="100%" justify="begin" align="center" gap={2}>
                  <Text>Date Range:</Text>
                  <DateRangePicker
                    className="justify-content-center flex"
                    value={{
                      from: createDateWithMidnightTime((date) =>
                        date.setDate(date.getDate() - 6)
                      ),
                      to: createDateWithLastSecondOfTheDay((date) =>
                        date.setDate(date.getDate())
                      ),
                      selectValue: "l7d",
                    }}
                    onValueChange={() => {}}
                    enableClear={false}
                  >
                    {presetPeriods.map((period) => (
                      <DateRangePickerItem
                        className="cursor-pointer"
                        key={period.key}
                        value={period.key}
                        from={period.fromDateGenerator()}
                        to={new Date()}
                      >
                        {period.label}
                      </DateRangePickerItem>
                    ))}
                  </DateRangePicker>
                </Flex>
                <Input
                  type="text"
                  placeholder="Type content to search..."
                  maxW="25%"
                />
                <Button variant="cta">Search</Button>
              </Flex>
              <DataTable
                columns={columns}
                data={dataset}
                isLoading={false}
                expandedRowRenderer={expandedRowRenderer}
                totalRows={datasetTotal}
                defaultSorting={[
                  {
                    id: "capturedAt",
                    desc: true,
                  },
                ]}
              />
            </Stack>
          </TabPanel>
          <TabPanel>
            <Spacer h="10px" />
            <></>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </DashboardContentLayout>
  );
}
