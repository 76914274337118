import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { useForm } from "react-hook-form";
import { PiKey } from "react-icons/pi";
import { ApiKeyResponse, CreateApiKeyRequest } from "../../interfaces/api-key";
import { ApiErrorCode, postApiMutation } from "../../services/api/api-service";
import { useGlobalAlert } from "../../services/global-alert/global-alert-context";

interface CreateApiModalProps {
  children: ReactElement<{
    onClick: () => void;
  }>;
  onCreated: () => Promise<void>;
}
export default function CreateApiKeyModal({
  children,
  onCreated,
}: CreateApiModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { handleSubmit, register, formState, setError, reset } =
    useForm<CreateApiKeyRequest>({
      mode: "all",
    });

  const { isPending, mutate } = postApiMutation<
    CreateApiKeyRequest,
    ApiKeyResponse
  >("/api-key");

  const { addAlert } = useGlobalAlert();

  async function onSubmit(input: CreateApiKeyRequest) {
    mutate(input, {
      onError: (error) => {
        if (error.errorCode === ApiErrorCode.EXISTING_API_KEY_NAME) {
          setError("name", {
            type: error.errorCode,
            message: error.message,
          });
        } else {
          addAlert({
            type: "error",
            body: (
              <Text>
                Error happened when creating the api key <b>{input.name}</b>,
                please retry or contact the support.
              </Text>
            ),
          });
          reset();
          onClose();
        }
      },
      onSuccess: async () => {
        addAlert({
          type: "success",
          body: (
            <Text>
              Successfully created api key <b>{input.name}</b>!
            </Text>
          ),
        });

        reset();
        onClose();
        await onCreated();
      },
    });
  }

  return (
    <>
      {React.cloneElement(children, {
        onClick: onOpen,
      })}
      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="800px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>Create Api Key</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl
                isInvalid={Boolean(formState.errors.name)}
                isDisabled={isPending}
              >
                <FormLabel>
                  <Flex align="center" className="gap-1">
                    <Text>Name</Text>
                    <Text color="red">*</Text>
                  </Flex>
                </FormLabel>
                <Input
                  id="name"
                  {...register("name", {
                    required: "Name of the api key is required.",
                    pattern: {
                      message:
                        "Please enter a valid api key name. Only regular alphanumeric characters, '-', '.' and '_' are supported.",
                      value: /^[A-Za-z0-9-_.]+$/,
                    },
                  })}
                />
                <FormErrorMessage>
                  Error: {formState.errors.name?.message}
                </FormErrorMessage>
                <FormHelperText>
                  Please name the API key to reflect its use case, for example,
                  'production'.
                </FormHelperText>
              </FormControl>
            </ModalBody>
            <ModalFooter className="gap-1">
              <Button
                variant="cta"
                leftIcon={<PiKey />}
                type="submit"
                isLoading={isPending}
                loadingText="Creating"
                isDisabled={!formState.isValid}
              >
                Create
              </Button>
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}
