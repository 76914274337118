import {
  Alert,
  AlertDescription,
  Box,
  Divider,
  Flex,
  Link,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { ReactElement, ReactNode, isValidElement } from "react";
import { MdChevronRight } from "react-icons/md";
import { GlobalAlert } from "../components/global-alert";
import { PageTitle } from "../components/page-title";
import { SectionTitle } from "../components/section-title";
import { useBilling } from "../services/billing/billing-context";

interface DashboardContentLayoutProps {
  rootNavSection?: string | ReactElement;
  subNavSection: string | ReactElement;
  mainTitle: string;
  mainTitleHelperText?: string | ReactElement;
  sectionTitle?: string | ReactElement;
  sectionTitleHelperText?: string | ReactElement;
  sectionTitleCallToAction?: ReactElement;
  children: ReactNode;
}

export default function DashboardContentLayout({
  rootNavSection,
  subNavSection,
  mainTitle,
  mainTitleHelperText,
  sectionTitle,
  sectionTitleHelperText,
  sectionTitleCallToAction,
  children,
}: DashboardContentLayoutProps) {
  const { hasPayment } = useBilling();
  function getRootNavSection() {
    if (!rootNavSection) {
      return (
        <Text fontSize="sm" mr={3}>
          {mainTitle}
        </Text>
      );
    } else if (isValidElement(rootNavSection)) {
      return rootNavSection;
    } else {
      return (
        <Text fontSize="sm" mr={3}>
          {rootNavSection}
        </Text>
      );
    }
  }
  return (
    <Box maxW={{ md: "75%", base: "95%" }} minW={{ base: "75%", md: "95%" }}>
      {/* {!hasPayment && (
        <Flex marginBottom={4}>
          <Alert status="warning">
            <AlertIcon />
            <AlertTitle whiteSpace="nowrap">You are on free tier</AlertTitle>
            <AlertDescription>
              Thanks for using Empower! You are currently on the free tier,
              follow our{" "}
              <Link
                href="https://docs.empower.dev/quickstart/deploy-your-first-lora"
                target="_blank"
              >
                quick start guide
              </Link>{" "}
              to deploy your first LoRA. Please <ContactSupportLink /> for
              anything we can help with.
            </AlertDescription>
          </Alert>
        </Flex>
      )} */}
      {!hasPayment && (
        <Flex marginBottom={4}>
          <Alert status="info">
            🚀
            <AlertDescription pl={2}>
              Introducing the new{" "}
              <Link
                href="https://www.empower.dev/article/introduce-the-empower-auto-fine-tuning-platform"
                target="_blank"
                textDecoration="none"
                fontSize="md"
              >
                <b>Empower Auto Fine-Tuning Platform</b>
              </Link>
              , save up to 80% on LLM bills with just 5 lines of code change!
              Read more about the product {""}
              <Link
                href="https://www.empower.dev/article/introduce-the-empower-auto-fine-tuning-platform"
                target="_blank"
                textDecoration="none"
              >
                here
              </Link>
              .{" "}
            </AlertDescription>
          </Alert>
        </Flex>
      )}
      <Flex justifyItems="center" alignItems="center">
        {getRootNavSection()}
        <MdChevronRight />
        {isValidElement(subNavSection) ? (
          subNavSection
        ) : (
          <Text fontSize="sm" ml={3} color="gray.500">
            {subNavSection}
          </Text>
        )}
      </Flex>
      <GlobalAlert />
      <PageTitle mainTitle={mainTitle} helperText={mainTitleHelperText} />
      {sectionTitle && (
        <>
          <Spacer h="30px" />
          <Flex justifyContent="space-between" align="center">
            <SectionTitle
              mainTitle={sectionTitle}
              helperText={sectionTitleHelperText}
            />
            {sectionTitleCallToAction}
          </Flex>
        </>
      )}
      <Divider mt={5} mb={5} />
      {children}
    </Box>
  );
}
