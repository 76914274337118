import {
  Badge,
  Button,
  Flex,
  Link,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { createColumnHelper, Row } from "@tanstack/react-table";
import { ProgressBar, SparkAreaChart } from "@tremor/react";
import { CiMenuBurger } from "react-icons/ci";
import { FaTasks } from "react-icons/fa";
import { DataTable } from "../components/data-table";
import DashboardContentLayout from "../layouts/dashboard-content-layout";
import {
  formatNumber,
  formatTimestampToLocal,
  generateRandomNumber,
} from "../utils";
import { datasetTotal, datasetTotalVerified } from "./dataset-detail-page";

interface Task {
  name: string;
  modelName: string;
  status: string;
  targetExternalModel: string;
  modelTrafficPercentage: number;
  stats: {
    total: number;
    verified: number;
    rejected: number;
    pending: number;
  };
  models: string[];
  last7DaysModelTraffic: {
    day: string;
    total: number;
    verified: number;
  }[];
}

const tasks = [
  {
    name: "Reimbursement Assistant",
    modelName: "reimbursement-assistant",
    status: "running",
    targetExternalModel: "sonnet-3.5",
    modelTrafficPercentage: 0.85,
    stats: { total: 322425, verified: 281920, rejected: 25, pending: 25 },
    lastRequestReceivedAt: new Date(),
    models: [
      "reimbursement-assistant-08-04",
      "reimbursement-assistant-07-28",
      "reimbursement-assistant-07-14",
    ],
    last7DaysModelTraffic: [
      {
        day: "1",
        total: 5321,
        verified: 4221,
      },
      {
        day: "2",
        total: 4910,
        verified: 4181,
      },
      {
        day: "3",
        total: 5617,
        verified: 4115,
      },
      {
        day: "4",
        total: 4462,
        verified: 3982,
      },
      {
        day: "5",
        total: 4281,
        verified: 3451,
      },
      {
        day: "6",
        total: 5089,
        verified: 4532,
      },
      {
        day: "7",
        total: 6145,
        verified: 5562,
      },
    ],
  },
  {
    name: "Receipt Items Extraction",
    modelName: "-",
    status: "capturing",
    targetExternalModel: "gpt-4o",
    modelTrafficPercentage: 0.5,
    stats: {
      total: datasetTotal,
      verified: datasetTotalVerified,
      rejected: datasetTotal - datasetTotalVerified - 289,
      pending: 289,
    },
    models: [],
    last7DaysModelTraffic: [
      {
        day: "1",
        total: 0,
        verified: 0,
      },
      {
        day: "2",
        total: 0,
        verified: 0,
      },
      {
        day: "3",
        total: 0,
        verified: 0,
      },
      {
        day: "4",
        total: 0,
        verified: 0,
      },
      {
        day: "5",
        total: 351,
        verified: 281,
      },
      {
        day: "6",
        total: 1526,
        verified: 1211,
      },
      {
        day: "7",
        total: 2324,
        verified: 1610,
      },
    ],
  },
];

const columnHelper = createColumnHelper<Task>();

const columns = [
  columnHelper.accessor("name", {
    header: "Name",
    id: "name",
    enableSorting: false,
    cell: (info) => (
      <Link
        fontSize="md"
        textDecoration="none"
        onClick={() => info.row.toggleExpanded()}
      >
        {info.getValue()}
      </Link>
    ),
  }),
  columnHelper.accessor("modelName", {
    header: "Fine-tuned Model",
    id: "modelName",
    enableSorting: false,
    cell: (info) =>
      info.getValue() === "-" ? (
        <Text fontSize="md">-</Text>
      ) : (
        <Link
          textDecoration="none"
          fontSize="md"
          onClick={() => info.row.toggleExpanded()}
        >
          {info.getValue()}
        </Link>
      ),
  }),
  columnHelper.display({
    id: "requests",
    header: "Requests",
    cell: ({ row }) => (
      <Flex gap={2}>
        <Text fontSize="md">
          Total: {formatNumber(row.original.stats.total)}
        </Text>
        <Text fontSize="md">|</Text>
        <Text fontSize="md">
          Verified: {formatNumber(row.original.stats.verified)}
        </Text>
      </Flex>
    ),
  }),
  columnHelper.accessor("targetExternalModel", {
    header: "Target Model",
    id: "targetExternalModel",
    enableSorting: false,
    cell: (info) => <Text fontSize="md">{info.getValue()}</Text>,
  }),
  columnHelper.accessor("status", {
    header: "Status",
    id: "status",
    enableSorting: true,
    cell: (info) => {
      if (info.getValue() === "running") {
        return <Badge colorScheme="green">Model Running</Badge>;
      } else if (info.getValue() === "capturing") {
        return <Badge colorScheme="yellow">Capturing Data</Badge>;
      } else {
        return <Badge colorScheme="gray">Pending</Badge>;
      }
    },
  }),
  columnHelper.display({
    id: "action",
    header: "",
    meta: {
      isActionColumn: true,
    },
    cell: () => (
      <Menu>
        <MenuButton zIndex={100}>
          <CiMenuBurger fill="gray" />
        </MenuButton>
        <MenuList>
          <MenuItem fontSize="sm" color="gray.600">
            Dummy
          </MenuItem>
        </MenuList>
      </Menu>
    ),
  }),
];

const randomMins = [generateRandomNumber(2, 10), generateRandomNumber(2, 20)];

function expandedRowRenderer(row: Row<Task>) {
  return (
    <Flex w="70%" h="150px" gap={1} mb={8} pl={8}>
      <Flex verticalAlign="top" h="100%">
        <Stack>
          <Text>
            <b>Task Details </b>
          </Text>
          <List pt={3} lineHeight={2} w={300}>
            <ListItem>
              <Text>Update Cadence: Every Sunday</Text>
            </ListItem>
            <ListItem>
              <Text>
                Last Requested:{" "}
                {formatTimestampToLocal(
                  new Date().getTime() / 1000 -
                    60 * randomMins[row.index] -
                    generateRandomNumber(1, 59)
                )}{" "}
                {/* ({randomMins[row.index]} minutes ago) */}
              </Text>
              {/* <ListItem>
                <Flex align="center" gap={2}>
                  <Text>Last Updated By:</Text>
                  <Actor
                    actor={{
                      actorType: ActorType.USER,
                      user: {
                        id: 1,
                        firstName: "Daiyi",
                        lastName: "Yang",
                        profileImageUrl:
                          "https://img.clerk.com/eyJ0eXBlIjoicHJveHkiLCJzcmMiOiJodHRwczovL2ltYWdlcy5jbGVyay5kZXYvdXBsb2FkZWQvaW1nXzJjTXlldUVPVjdIUWJkSGg3SDBPYXB0RzNmMSJ9",
                      },
                    }}
                  />
                </Flex>
              </ListItem> */}
              <ListItem>
                <Text>
                  Last Updated:{" "}
                  {formatTimestampToLocal(
                    new Date().getTime() / 1000 -
                      3600 *
                        (24 * generateRandomNumber(1, 23) +
                          generateRandomNumber(1, 23)) -
                      60 * generateRandomNumber(5, 32) -
                      generateRandomNumber(1, 59)
                  )}
                </Text>
              </ListItem>
            </ListItem>{" "}
          </List>
        </Stack>
      </Flex>
      <Flex verticalAlign="top" h="100%">
        <Stack>
          <Text>
            <b>Last 7 Days Model Traffic </b>
          </Text>
          <Flex gap={1} w={450}>
            <Stack pt={5}>
              <Text color="#3b82f6">
                Total:{" "}
                {formatNumber(
                  row.original.last7DaysModelTraffic
                    .map((t) => t.total)
                    .reduce((a, b) => a + b)
                )}
              </Text>
              <Text color="#10b981">
                Verified:{" "}
                {formatNumber(
                  row.original.last7DaysModelTraffic
                    .map((t) => t.verified)
                    .reduce((a, b) => a + b)
                )}
              </Text>
            </Stack>
            <SparkAreaChart
              data={row.original.last7DaysModelTraffic}
              index="date"
              categories={["total", "verified"]}
              className="w-[300px] h-[80px]"
            />
          </Flex>
        </Stack>
      </Flex>
      <Flex verticalAlign="top" h="100%" w="300px">
        <Stack>
          <Text>
            <b>Traffic Split </b>
          </Text>
          <List pt={3} w={240}>
            {row.original.models.length > 0 && (
              <>
                <ListItem>
                  <Flex>
                    <Link textDecoration="none" fontSize="small">
                      reimbursement-assistant
                    </Link>
                    <Text>: 85%</Text>
                  </Flex>
                </ListItem>
                <ListItem>
                  <Text>sonnet-3.5: 15%</Text>
                </ListItem>
              </>
            )}
            {row.original.models.length == 0 && (
              <>
                <ListItem>
                  <Text>gpt-4o: 100%</Text>
                </ListItem>
              </>
            )}
          </List>
        </Stack>
      </Flex>
      <Flex verticalAlign="top" h="100%">
        <Stack>
          <Text>
            <b>Training Jobs</b>
          </Text>
          <List pt={3} w={200}>
            {row.original.models.map((model) => (
              <ListItem>
                <Link fontSize="small" textDecoration="none">
                  {model}
                </Link>
              </ListItem>
            ))}
            {row.original.models.length > 0 && (
              <ListItem>
                <Link fontSize="small" textDecoration="none">
                  ...more
                </Link>
              </ListItem>
            )}
            {row.original.models.length == 0 && (
              <ListItem>
                <Stack gap={3}>
                  <ProgressBar
                    value={(datasetTotalVerified / 5000) * 100.0}
                    className="w-[200px] pt-2"
                    // @ts-expect-error: actually works
                    color="#596aff"
                  />
                  <Text pt={2}>
                    {formatNumber(5000 - datasetTotalVerified)} more verified
                    requests needed to start training
                  </Text>
                </Stack>
              </ListItem>
            )}
          </List>
        </Stack>
      </Flex>
    </Flex>
  );
}

export default function TasksPage() {
  return (
    <DashboardContentLayout
      subNavSection="Overview"
      mainTitle="Tasks"
      mainTitleHelperText="Tasks serve as the unit for categorizing LLM requests. Task-specific models can be trained based on captured requests to replace the costly, generic LLM."
      sectionTitleCallToAction={
        <Button variant="cta" leftIcon={<FaTasks />}>
          Create New Task
        </Button>
      }
      sectionTitle="Manage Tasks"
      sectionTitleHelperText="View and manage tasks."
    >
      <DataTable
        columns={columns}
        data={tasks}
        isLoading={false}
        expandedRowRenderer={expandedRowRenderer}
        // defaultSorting={[
        //   {
        //     id: "capturedAt",
        //     desc: true,
        //   },
        // ]}
      />
    </DashboardContentLayout>
  );
}
