import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Flex,
  Link,
  List,
  ListItem,
  OrderedList,
  Progress,
  Stack,
  Text,
  UnorderedList,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { AxiosError } from "axios";
import { ChatCompletionMessage } from "openai/resources/index.mjs";
import { useEffect, useRef, useState } from "react";
import { GrDeploy } from "react-icons/gr";
import { DataTable } from "../components/data-table";
import NewEvaluationModal from "../components/evaluation/new-evaluation-modal";
import LoadingIndicator from "../components/loading-indicator";
import {
  ChatCompletion,
  EvaluationDataRow,
  EvaluationResponse,
  EvaluationResponseRow,
  EvaluationResponseStats,
  Message,
  StreamingEvaluationResponse,
} from "../interfaces/evaluation";
import DashboardContentLayout from "../layouts/dashboard-content-layout";
import { useApiClient } from "../services/api/api-client-context";
import ApiClient, {
  postApiMutation,
  postStreamingApi,
} from "../services/api/api-service";
import { getQueryParameters, md5, stringToBoolean } from "../utils";

const columnHelper = createColumnHelper<EvaluationResponseRow>();

function extractThinkingIfPresent(message: ChatCompletionMessage) {
  if (message.content) {
    const thinkingTag = "</thinking>";
    const tagPosition = message.content.indexOf(thinkingTag);

    if (tagPosition !== -1) {
      const part1 = message.content.slice("<thinking>".length, tagPosition);
      const part2 = message.content.slice(tagPosition + thinkingTag.length);
      return [part1, part2];
    } else {
      return [null, message.content];
    }
  }

  return [null, null];
}

interface SkyvernResponse {
  id: string;
  action_type: string;
  reasoning: string;
}

function formatChatCompletionResponse(
  response: ChatCompletion,
  expectedResponse: string | undefined,
  isVlm: boolean
) {
  const choice = response.choices[0];

  if (choice.message.tool_calls != null) {
    const [thinking] = extractThinkingIfPresent(choice.message);
    return (
      <List>
        {choice.message.tool_calls.map((toolCall) => (
          <ListItem>
            {thinking && (
              <Text>
                <b>Thinking:</b> {thinking}
              </Text>
            )}
            <Text>
              <b>Function:</b> {toolCall.function.name}
            </Text>
            <Text>
              <b>Arguments:</b> {toolCall.function.arguments}
            </Text>
          </ListItem>
        ))}
      </List>
    );
  } else {
    if (isVlm) {
      const expectedResponseJson = JSON.parse(expectedResponse || "[]")
        .actions as Array<SkyvernResponse>;
      return renderVlmContent(expectedResponseJson, choice.message.content!);
    }
    const [thinking, content] = extractThinkingIfPresent(choice.message);
    return (
      <Text>
        {thinking && (
          <Text>
            <b>Thinking:</b> {thinking}
          </Text>
        )}
        <b>Text:</b> {content}
      </Text>
    );
  }
}

function renderVlmContent(
  expected: Array<SkyvernResponse> | undefined,
  content: string
) {
  if (!content) {
    return <Text>No content</Text>;
  }

  let actions: Array<SkyvernResponse> = [];
  try {
    if (content.startsWith("```json") && content.endsWith("```")) {
      actions = JSON.parse(content.slice(8, -3))
        .actions as Array<SkyvernResponse>;
    } else {
      actions = JSON.parse(content).actions as Array<SkyvernResponse>;
    }
  } catch (e) {
    return <Text>{content}</Text>;
  }

  if (!actions) {
    return <Text>{content}</Text>;
  }

  actions.sort((a, b) => {
    if (a.id == null) return 1; // `a` is null, move it to the end
    if (b.id == null) return -1; // `b` is null, move it to the end
    return a.id.localeCompare(b.id); // both are non-null, compare normally
  });

  let expectedOnly: Array<SkyvernResponse> = [];
  let actionsOnly: Array<SkyvernResponse> = [];

  if (expected) {
    const expectedMap = new Map<string, SkyvernResponse>();
    const actionsMap = new Map<string, SkyvernResponse>();

    expected.forEach((item) => expectedMap.set(item.id, item));
    actions.forEach((item) => actionsMap.set(item.id, item));

    expectedOnly = expected.filter((item) => !actionsMap.has(item.id));
    actionsOnly = actions.filter((item) => !expectedMap.has(item.id));
  }

  const actionItems = actions.map((item: SkyvernResponse) => (
    <ListItem
      key={item.id}
      color={actionsOnly.find((x) => x.id == item.id) ? "blue" : "black"}
    >
      <Text>
        <b>{actionsOnly.find((x) => x.id == item.id) ? "New" : ""} id:</b>
        {item.id}
      </Text>
      <Text>
        <b>reasoning:</b>
        {item.reasoning}
      </Text>
      <Text>
        <b>action type:</b>
        {item.action_type}
      </Text>
      {/* <Text>{JSON.stringify(item)}</Text> */}
    </ListItem>
  ));
  const missedItems = expectedOnly.map((item: SkyvernResponse) => (
    <ListItem key={item.id} color={"red"}>
      <Text>
        <b>Missing id:</b>
        {item.id}
      </Text>
      <Text>
        <b>reasoning:</b>
        {item.reasoning}
      </Text>
      <Text>
        <b>action type:</b>
        {item.action_type}
      </Text>
      {/* <Text>{JSON.stringify(item)}</Text> */}
    </ListItem>
  ));

  return <List>{[...actionItems, ...missedItems]}</List>;
}

function formatExpectedOutput(message: Message, isVlm: boolean) {
  if (message.function_call != null) {
    return (
      <>
        <Text>
          <b>Function:</b> {message.function_call.name}
        </Text>
        <Text>
          <b>Arguments:</b> {message.function_call.arguments}
        </Text>
      </>
    );
  } else if (message.tool_calls != null) {
    return (
      <List>
        {message.tool_calls.map((toolCall) => (
          <ListItem>
            <Text>
              <b>Function:</b> {toolCall.function.name}
            </Text>
            <Text>
              <b>Arguments:</b> {toolCall.function.arguments}
            </Text>
          </ListItem>
        ))}
      </List>
    );
  } else {
    if (isVlm) {
      return renderVlmContent(undefined, message.content!);
    }
    return (
      <Text>
        <b>Text:</b> {message.content}
      </Text>
    );
  }
}

function formatImages(input: EvaluationDataRow) {
  return (
    <Flex
      w={120}
      p={4} // padding inside the box
      flex={1} // take up all available space
      flexDir="column-reverse"
    >
      <OrderedList>
        {(input.images || []).map((image, index) => (
          <ListItem>
            <Link href={"http://localhost:3000/files/vlm/skyvern/" + image}>
              {"Image " + (index + 1)}
            </Link>
          </ListItem>
        ))}
      </OrderedList>
    </Flex>
  );
}

function formatTools(input: EvaluationDataRow) {
  return (
    <Flex
      w="400px" // or a specific width like "300px"
      h="300px" // height of the scrollable area
      overflowY="scroll" // enables vertical scrolling
      p={4} // padding inside the box
      flex={1} // take up all available space
      flexDir="column-reverse"
    >
      <OrderedList>
        {(input.functions || []).map((func) => (
          <ListItem>
            <Text color={"blue"}>
              <b>{func.name}</b>
            </Text>
            <Text>
              <b>Description: </b>
              {func.description}
            </Text>
            <Text>
              <b>Parameters:</b>
              {func.parameters && func.parameters["properties"] && (
                <UnorderedList>
                  {Object.entries(func.parameters["properties"]).map(
                    (entry) => {
                      const [key, value] = entry;
                      return (
                        <ListItem>
                          <Text>
                            <b>{key}</b>: {JSON.stringify(value)}
                          </Text>
                        </ListItem>
                      );
                    }
                  )}
                </UnorderedList>
              )}
            </Text>
          </ListItem>
        ))}
      </OrderedList>
    </Flex>
  );
}

function renderMessageBody(message: Message) {
  if (message.content) {
    return (
      <Text>
        <b>Text:</b> {message.content}
      </Text>
    );
  } else if (message.function_call) {
    return (
      <List>
        <ListItem>
          <Text>
            <b>Function:</b> {message.function_call.name}
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>Arguments:</b> {message.function_call.arguments}
          </Text>
        </ListItem>
      </List>
    );
  } else if (message.tool_calls) {
    return message.tool_calls.map((toolCall) => (
      <List>
        <ListItem>
          <Text>
            <b>Function:</b> {toolCall.function.name}
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>Arguments:</b> {toolCall.function.arguments}
          </Text>
        </ListItem>
      </List>
    ));
  }
}

function formatInput(input: EvaluationDataRow) {
  return (
    <Flex
      w="400px" // or a specific width like "300px"
      h="300px" // height of the scrollable area
      overflowY="scroll" // enables vertical scrolling
      p={4} // padding inside the box
      flex={1} // take up all available space
      flexDir="column-reverse"
    >
      <List>
        {input.messages.slice(0, input.messages.length - 1).map((message) => (
          <ListItem>
            <Text color={"blue"}>
              <b>{message.role}</b>
            </Text>
            {renderMessageBody(message)}
          </ListItem>
        ))}
      </List>
    </Flex>
  );
}

function checkResultToOrder(checkResult: string) {
  switch (checkResult) {
    case "correct":
      return 1;
    case "unsure":
      return 2;
    default:
      return 3;
  }
}

export function EvaluationResultPage() {
  const { onCopy, setValue, value: valueToCopy } = useClipboard("");
  const toast = useToast();

  useEffect(() => {
    if (valueToCopy != "") {
      onCopy();
    }
  }, [valueToCopy]);

  function getLLMDataToCopy(
    data: EvaluationDataRow,
    excludeLast: boolean,
    modelName?: string
  ) {
    return {
      model: modelName ?? "",
      messages: data.messages.slice(
        0,
        excludeLast ? data.messages.length - 1 : data.messages.length
      ),
      tools: (data.functions || []).map((f) => ({
        type: "function",
        function: f,
      })),
      temperature: 0,
    };
  }

  const getImagesBase64 = async (images: Array<string>) => {
    const imagesBase64 = await Promise.all(
      images?.map(async (image) => {
        const response = await fetch(image);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      })
    );
    return imagesBase64;
  };

  async function getVlmDataToCopy(data: EvaluationDataRow, modelName?: string) {
    console.log("get vlm data to copy");
    const userMessage = data.messages[0].content;
    const images = data.images?.map((image) => {
      return "http://localhost:3000/files/vlm/skyvern/" + image;
    });

    // load all images to base64.
    const imagesBase64 = await getImagesBase64(images!);

    // console.log(imagesBase64);

    // console.log(images);

    const d = {
      model: modelName ?? "",
      messages: [
        {
          role: "user",
          content: [
            {
              type: "text",
              text: userMessage,
            },
            ...imagesBase64.map((image) => ({
              type: "image_url",
              image_url: {
                url: image,
              },
            })),
          ],
        },
      ],
      temperature: 0,
    };

    console.log(d);

    return d;
  }

  async function getDataToCopy(
    data: EvaluationDataRow,
    excludeLast: boolean,
    modelName?: string
  ) {
    if (data.images) {
      return await getVlmDataToCopy(data, modelName);
    } else {
      return getLLMDataToCopy(data, excludeLast, modelName);
    }
  }

  // async function isCopied(
  //   data: EvaluationDataRow,
  //   excludeLast: boolean,
  //   modelName?: string
  // ) {
  //   const dataToCopy = await getDataToCopy(data, excludeLast, modelName);

  //   return hasCopied && valueToCopy === JSON.stringify(dataToCopy);
  // }

  function renderStats(
    stats: EvaluationResponseStats | undefined,
    modelName: string
  ) {
    if (stats) {
      return `${modelName} (Correct: ${stats.correct}, Incorrect: ${stats.incorrect}, Unsure: ${stats.unsure} ${stats.error > 0 ? `, Error: ${stats.error}` : ""})`;
    }
    return `${modelName} Running...`;
  }

  function renderProgressBar() {
    const numFinishedRows = data!.rows.filter(
      (row) => row.model_responses
    ).length;
    const numTotalRows = data!.total_rows;
    const percentage = Math.floor((numFinishedRows / numTotalRows) * 100);

    return (
      <Stack>
        <Flex justify="center">
          {numFinishedRows}/{numTotalRows} ({percentage}%)
        </Flex>
        <Progress hasStripe value={percentage} />
      </Stack>
    );
  }

  async function copyInputData(
    data: EvaluationDataRow,
    excludeLast: boolean,
    modelName?: string
  ) {
    console.log("copy input data");
    setValue(JSON.stringify(await getDataToCopy(data, excludeLast, modelName)));

    // show a notification that the data has been copied
    toast({
      title: "Data copied",
      status: "success",
      duration: 1000,
      isClosable: true,
    });
  }
  const {
    datasetName,
    modelNames,
    compareFunctionNameOnly,
    checkParameterInExample,
    isVlm,
  } = getQueryParameters();

  const [data, setData] = useState<EvaluationResponse>();
  const [error, setError] = useState<string>();
  const initialized = useRef(false);

  const apiClient = useApiClient();
  const [isLoading, setIsLoading] = useState(false);

  async function fetchData(apiClient: ApiClient, refreshCache: boolean) {
    try {
      await postStreamingApi<StreamingEvaluationResponse>(
        apiClient,
        "eval_streaming",
        {
          model_names: modelNames.split(","),
          file_name: datasetName,
          compare_function_name_only: stringToBoolean(compareFunctionNameOnly),
          check_parameter_in_example: stringToBoolean(checkParameterInExample),
          is_vlm: stringToBoolean(isVlm),
          refresh_cache: refreshCache,
        },
        "v1",
        (_, chunks) => {
          setIsLoading(true);
          const beginning_chunk = chunks.find(
            (chunk) => chunk.type === "beginning"
          );

          if (!beginning_chunk) {
            return;
          }

          const all_rows = beginning_chunk.data_rows;
          const finished_rows_map = new Map<string, EvaluationResponseRow>();
          chunks.forEach((chunk) => {
            if (chunk.type === "row") {
              const eval_response_row = chunk.eval_response_row!;
              finished_rows_map.set(
                md5(JSON.stringify(eval_response_row.data)),
                eval_response_row
              );
            }
          });

          setData({
            rows: all_rows.map((row, index) => ({
              index: index + 1,
              data: row,
              model_responses: finished_rows_map.get(md5(JSON.stringify(row))!)
                ?.model_responses,
            })),
            stats: chunks.find((chunk) => chunk.type === "end")?.stats,
            model_names: chunks[0].model_names,
            total_rows: chunks[0].total_rows,
          });
        },
        () => setIsLoading(false),
        true
      );
    } catch (e) {
      if (e instanceof AxiosError) {
        console.error("Error fetching data");
        console.error(e.message);
        setError(e.message);
      } else {
        setError("Unknown error occurred. Please try again.");
      }
    }
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetchData(apiClient, false);
    }
  }, []);

  const { mutate: downloadCSV } = postApiMutation<object, string>("/eval_csv");

  const [showInput, setShowInput] = useState(false);

  if (!datasetName || !modelNames) {
    return <Text>No dataset or models selected</Text>;
  }

  if (error) {
    return <Text>{error}</Text>;
  }
  if (!data) {
    return <LoadingIndicator />;
  }

  const inputColumns = [
    columnHelper.accessor("data", {
      header: "Tools",
      id: "tools",
      enableSorting: false,
      cell: (info) => formatTools(info.getValue()),
    }),

    columnHelper.accessor("data", {
      header: "Input",
      id: "input",
      enableSorting: false,
      cell: (info) => formatInput(info.getValue()),
    }),
  ];

  const columns = [
    columnHelper.accessor("index", {
      header: "row #",
      id: "index",
      enableSorting: false,
      cell: (info) => <>{info.getValue()}</>,
    }),
    columnHelper.accessor("data", {
      header: "Actions",
      id: "action",
      enableSorting: false,
      cell: (info) => (
        <Stack>
          <Link onClick={() => copyInputData(info.getValue(), true)}>
            Copy as Curl
            {/* {(await isCopied(info.getValue(), true))
                ? "Copied"
                : "Copy as curl"} */}
          </Link>
          <Link onClick={() => copyInputData(info.getValue(), false)}>
            {/* {(await isCopied(info.getValue(), false)) ? "Copied" : "Copy all"} */}
            Copy All
          </Link>
        </Stack>
      ),
    }),
    ...(showInput ? inputColumns : []),
    columnHelper.accessor("data", {
      header: "Images",
      id: "images",
      enableSorting: false,
      cell: (info) => <Box maxW={75}>{formatImages(info.getValue())}</Box>,
    }),
    columnHelper.accessor("data", {
      header: "Expected Output",
      id: "output",
      enableSorting: false,
      cell: (info) => {
        const messages = info.getValue().messages;
        return (
          <Box maxW={600}>
            {formatExpectedOutput(
              messages[messages.length - 1],
              stringToBoolean(isVlm)
            )}
          </Box>
        );
      },
    }),
    ...data!.model_names.map((modelName) => {
      const stats = data.stats?.[modelName];
      return columnHelper.accessor("data", {
        id: modelName,
        enableSorting: true,
        sortingFn: (a, b) => {
          return (
            checkResultToOrder(
              a.original.model_responses?.[modelName]?.check_result ?? ""
            ) -
            checkResultToOrder(
              b.original.model_responses?.[modelName]?.check_result ?? ""
            )
          );
        },
        header: renderStats(stats, modelName),
        cell: ({ row }) => {
          if (!row.original.model_responses) {
            return (
              <Flex align="center" justify="center">
                <CircularProgress isIndeterminate size={5} />
              </Flex>
            );
          }
          const modelResponse = row.original.model_responses[modelName];
          const expectedResponse =
            row.original.data.messages[row.original.data.messages.length - 1]
              .content;
          const checkResult = modelResponse.check_result;
          let backgroundColor = "white";
          if (!isVlm) {
            switch (checkResult) {
              case "correct":
                backgroundColor = "green.100";
                break;
              case "error":
                backgroundColor = "gray.200";
                break;
              case "incorrect":
                backgroundColor = "red.100";
                break;
              case "unsure":
                backgroundColor = "yellow.100";
                break;
            }
          }
          return (
            <Box maxW={600}>
              <Stack gap={2}>
                <Link
                  onClick={async () =>
                    copyInputData(row.original.data, true, modelName)
                  }
                >
                  Copy
                </Link>
                {modelResponse!.response && (
                  <Box backgroundColor={backgroundColor}>
                    {formatChatCompletionResponse(
                      modelResponse!.response,
                      expectedResponse,
                      stringToBoolean(isVlm)
                    )}
                  </Box>
                )}
                {modelResponse!.error_message && (
                  <Box backgroundColor={backgroundColor}>
                    {modelResponse!.error_message}
                  </Box>
                )}
              </Stack>
            </Box>
          );
        },
      });
    }),
  ];

  return (
    <DashboardContentLayout
      subNavSection="Overview"
      mainTitle="Evaluations"
      mainTitleHelperText="Evaluate model performance with datasets."
      sectionTitle="Evaluation Result"
      sectionTitleHelperText={
        <Text>
          Dataset: <b>{datasetName}</b> Models: <b>{modelNames}</b>
        </Text>
      }
      sectionTitleCallToAction={
        <NewEvaluationModal>
          <Button variant="cta" leftIcon={<GrDeploy />}>
            Run new evaluation
          </Button>
        </NewEvaluationModal>
      }
    >
      <Stack overflowY="scroll">
        <Flex gap={4}>
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              downloadCSV(
                {
                  modelNames: modelNames.split(","),
                  fileName: datasetName,
                  compareFunctionNameOnly: stringToBoolean(
                    compareFunctionNameOnly
                  ),
                  checkParameterInExample: stringToBoolean(
                    checkParameterInExample
                  ),
                  isVlm: stringToBoolean(isVlm),
                },
                {
                  onSuccess: (data) => {
                    const blob = new Blob([data], { type: "text/plain" });

                    const fileUrl = URL.createObjectURL(blob);

                    const a = document.createElement("a");
                    a.href = fileUrl;
                    a.download = "eval_result.csv";

                    document.body.appendChild(a); // Necessary for Firefox
                    a.click();

                    URL.revokeObjectURL(fileUrl);
                    document.body.removeChild(a);
                  },
                }
              );
            }}
          >
            Export as CSV
          </Link>
          <Link
            onClick={async () => {
              setData(undefined);
              fetchData(apiClient, true);
            }}
          >
            Update Cache
          </Link>
        </Flex>
        {isLoading && renderProgressBar()}
        <Divider />
        <Flex>
          {showInput ? (
            <Button variant="link" onClick={() => setShowInput(false)}>
              Hide Input
            </Button>
          ) : (
            <Button variant="link" onClick={() => setShowInput(true)}>
              Show Input
            </Button>
          )}
        </Flex>
        <DataTable columns={columns} data={data!.rows} isLoading={false} />
      </Stack>
    </DashboardContentLayout>
  );
}
