import moment from "moment";
import { Md5 } from "ts-md5";

export const AFTER_SIGN_IN_REDIRECTION_TARGET = "/";

export function formatISOToLocal(isoString: string): string {
  const date = moment(isoString);
  return date.format("YYYY-MM-DD HH:mm:ss");
}

export function formatTimestampToLocal(timestamp: number): string {
  const date = moment.unix(timestamp);
  return date.format("YYYY-MM-DD HH:mm:ss");
}

export function formatNumber(num: number, digits: number = 2) {
  if (Number.isInteger(num)) {
    return num.toLocaleString(undefined);
  }

  return num.toLocaleString(undefined, {
    style: "decimal",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
}

export function createDateWithMidnightTime(
  manipulator: (date: Date) => void = () => {}
) {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  manipulator(date);
  return date;
}

export function createDateWithLastSecondOfTheDay(
  manipulator: (date: Date) => void = () => {}
) {
  const date = new Date();
  date.setHours(23, 59, 59, 999);
  manipulator(date);
  return date;
}

export function tryUrlEncode(str: string) {
  try {
    const decoded = decodeURIComponent(str);
    if (str !== decoded) {
      return str;
    }
    return encodeURIComponent(str);
  } catch (e) {
    return encodeURIComponent(str);
  }
}

export async function retryWithExponentialBackoff<T>(
  task: () => Promise<T>,
  maxAttempts: number = 5,
  initialDelay: number = 500
): Promise<T> {
  let attempts = 0;
  let delay = initialDelay;

  while (attempts < maxAttempts) {
    try {
      return await task();
    } catch (error) {
      if (attempts === maxAttempts - 1) throw error;

      await new Promise((resolve) => setTimeout(resolve, delay));

      delay *= 2;
      attempts++;
    }
  }

  throw new Error("Unexpected error happened");
}

export function generateAllRequestTimeBetweenDates(
  startDate: Date,
  endDate: Date,
  granularity: "HOUR" | "DAY"
): string[] {
  const dateArray: string[] = [];
  const currentMoment = moment(startDate);

  const endMoment = moment(endDate);

  // Define the format based on granularity
  let format = "YYYY-MM-DD";
  if (granularity === "HOUR") {
    format = "YYYY-MM-DDTHH:00:00";
  }

  while (currentMoment <= endMoment) {
    // Push the formatted date string based on granularity
    dateArray.push(currentMoment.format(format));

    // Increment currentMoment by the specified granularity
    if (granularity === "HOUR") {
      currentMoment.add(1, "hours");
    } else if (granularity === "DAY") {
      currentMoment.add(1, "days");
    } else if (granularity === "WEEK") {
      currentMoment.add(1, "weeks");
    }
  }

  return dateArray;
}

export function chartValueFormatter(number: number) {
  return `${new Intl.NumberFormat("us").format(number).toString()}`;
}

export function getCurrentTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function kebabToCamelCase(input: string): string {
  return input
    .replace(/\./g, "_")
    .replace(/-(\d)/g, "*$1")
    .replace(/_(\d)/g, "*$1")
    .split(/[-\\/_]/)
    .map((word, index) => {
      // If it's the first word, return as is; otherwise, capitalize the first letter
      return index === 0 ? word : word[0].toUpperCase() + word.slice(1);
    })
    .join("")
    .replace(/\*/g, "_");
}

export function getQueryParameters() {
  const searchParams = new URLSearchParams(window.location.search);
  const params: { [key: string]: string } = {};

  for (const [key, value] of searchParams) {
    params[key] = value;
  }

  return params;
}

export function stringToBoolean(str: string): boolean {
  // Define strings that are considered true
  const trueValues = ["true", "yes", "1"];
  // Define strings that are considered false
  const falseValues = ["false", "no", "0"];

  // Normalize the string to lowercase for case-insensitive comparison
  const normalizedStr = str.trim().toLowerCase();

  // Check if the string is in the list of true values
  if (trueValues.includes(normalizedStr)) {
    return true;
  }
  // Check if the string is in the list of false values
  else if (falseValues.includes(normalizedStr)) {
    return false;
  }
  // Throw an error or return a default value for strings that don't match
  else {
    throw new Error(`The string "${str}" cannot be converted to a boolean.`);
    // Or, you could return a default value, like false, instead of throwing an error
    // return false;
  }
}

function toCamelCase(s: string): string {
  return s.replace(/(_\w)/g, (m) => m[1].toUpperCase());
}

export function convertObjectKeysToCamelCase(obj: any): any {
  const result: { [key: string]: any } = {};

  Object.keys(obj).forEach((key) => {
    const camelCaseKey = toCamelCase(key);
    if (
      obj[key] !== null &&
      typeof obj[key] === "object" &&
      !Array.isArray(obj[key])
    ) {
      // Recursive call if the value is an object (but not an array)
      result[camelCaseKey] = convertObjectKeysToCamelCase(obj[key]);
    } else {
      result[camelCaseKey] = obj[key];
    }
  });

  return result;
}

export function md5(content: string): string {
  return Md5.hashStr(content);
}

export function getBaseApiUrl() {
  let port = window.location.port;
  if (import.meta.env.MODE === "development") {
    port = "3000";
  }

  return `${window.location.protocol}//${window.location.hostname}${port !== "" ? `:${port}` : ""}/api`;
}

export function extractThinkingIfPresent(content: string) {
  if (content) {
    const thinkingTag = "</thinking>";
    const tagPosition = content.indexOf(thinkingTag);

    if (tagPosition !== -1) {
      const part1 = content.slice("<thinking>".length, tagPosition);
      const part2 = content.slice(tagPosition + thinkingTag.length);
      return [part1, part2];
    } else {
      return [null, content];
    }
  }

  return [null, null];
}

export function isHalfHourTimezone(timezone: string): boolean {
  try {
    // Create a DateTimeFormat object with the given timezone
    const formatter = new Intl.DateTimeFormat("en-US", {
      timeZone: timezone,
      timeZoneName: "short",
      hour: "numeric",
      minute: "numeric",
    });

    // Get the current date and time in the given timezone
    const date = new Date();
    const parts = formatter.formatToParts(date);
    const timeZonePart = parts.find((part) => part.type === "timeZoneName");

    // If no timeZonePart is found, the timezone is invalid
    if (!timeZonePart) {
      throw new Error("Invalid timezone");
    }

    // Extract the offset from the timeZonePart
    const offsetMatch = timeZonePart.value.match(/GMT([+-]\d*)(?::(\d{2}))?/);
    if (!offsetMatch) {
      throw new Error("Could not parse timezone offset");
    }

    const offsetMinutes = offsetMatch[2] ? parseInt(offsetMatch[2], 10) : 0;

    // Check if the offset is a full hour or half hour
    if (offsetMinutes === 30) {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
}

export function generateRandomNumber(
  min: number = 0,
  max: number = 10000
): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomElement<T>(array: T[]): T {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}

export const contactEmail = "founders@empower.dev";
export const contactEmailUrl = "mailto:founders@empower.dev";
export const discordInvitationUrl = "https://discord.gg/PVaggZ3z6r";
export const documentationsUrl = "https://docs.empower.dev";
export const bookAMeetingUrl =
  "https://calendly.com/yulong-empower/empower-dev";
