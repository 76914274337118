import { Box, Button, Divider, Flex, Text } from "@chakra-ui/react";
import PaymentMethods from "../components/billing/payment-methods";
import ProductPlanCard from "../components/billing/product-plan-card";
import ExternalLink from "../components/external-link";
import { ProductPlansResponse } from "../interfaces/billing";
import DashboardContentLayout from "../layouts/dashboard-content-layout";
import { getApi } from "../services/api/api-service";
import { bookAMeetingUrl } from "../utils";

export default function BillingSettingsPage() {
  const result = getApi<ProductPlansResponse>("/billing/plan");

  if (!result.data) {
    return <div>Loading...</div>;
  }

  return (
    <DashboardContentLayout
      rootNavSection="Billing"
      subNavSection="Settings"
      mainTitle="Billing Settings"
      mainTitleHelperText={
        <>
          <Text color="gray.600" fontSize="sm" pt={2}>
            Manage product plan and payment methods. More details of the pricing
            can be found{" "}
            <ExternalLink text="here" href="https://www.empower.dev/pricing" />.
          </Text>
        </>
      }
      sectionTitle="Product Plan"
      sectionTitleHelperText="Manage the product plan."
    >
      <Flex direction="row" justifyContent="space-between" flexWrap="wrap">
        {result.data.plans.map((plan, idx) => {
          return (
            <ProductPlanCard
              key={idx}
              title={plan.name}
              price={plan.monthlyPremium / 100}
              features={[
                plan.monthlyPremium > 0
                  ? `$${plan.monthlyCredits / 100} monthly model usage credits*`
                  : `$${plan.monthlyCredits / 100} life-time model usage credits`,
                `${plan.requestPerMinute} request per minute inference rate limiting`,
                `Up to ${plan.numModels} LoRA deployments`,
              ]}
              isCurrent={plan.name === result.data.currentPlan.name}
            />
          );
        })}

        <ProductPlanCard
          title="Enterprise"
          features={[
            "Unlimited rate limit",
            "Unlimited LoRA deployments",
            "Custom base model",
            "On-prem or private VPC deployment",
          ]}
          customCTA={
            <Button
              variant="cta"
              w="100%"
              onClick={() => window.open(bookAMeetingUrl, "_blank")}
            >
              Contact Us
            </Button>
          }
        />
      </Flex>
      <Box>
        <Text>
          * monthly model usage credits is reset in the end of each month{" "}
        </Text>
        <Text>
          {" "}
          * model usage charge is a charge based on model usage, calculated on a
          per-token basis, see{" "}
          <ExternalLink
            href="https://www.empower.dev/pricing"
            text="pricing"
          />{" "}
          for details.
        </Text>
        <Text>
          * billing cycle is based on the calendar month, from the 1st day of
          the month to the last day of the month. Invoices will be generated on
          the last day of each month and the first invoice will be prorated
          based on the subscription start date.
        </Text>
      </Box>
      <Divider my={4} />
      <PaymentMethods />
    </DashboardContentLayout>
  );
}
