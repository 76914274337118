import { Flex, Icon, Link, ListItem, Text } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { NavLink } from "react-router-dom";

interface SidenavItemProps {
  label: string;
  icon: IconType;
  to: string;
}

export default function SidenavItem({ label, icon, to }: SidenavItemProps) {
  return (
    <ListItem>
      <Link as={NavLink} to={to} variant="sideNavItem">
        <Flex alignItems="center" pt={2} pb={2} pl={4}>
          <Icon boxSize="5" as={icon} />
          <Text ml={3} fontSize="sm">
            {label}
          </Text>
        </Flex>
      </Link>
    </ListItem>
  );
}
