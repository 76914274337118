import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Heading,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement } from "react";
import { FaCheck } from "react-icons/fa";
import { postApiMutation } from "../../services/api/api-service";
import { useBilling } from "../../services/billing/billing-context";
import { useGlobalAlert } from "../../services/global-alert/global-alert-context";
import { ProductPlanConfirmationDialog } from "../product-plan-confirmation-dialog";

type Props = {
  title: string;
  price?: number;
  features: string[];
  isHighlighted?: boolean;
  isCurrent?: boolean;
  customCTA?: ReactElement;
};

export default function ProductPlanCard({
  title,
  price,
  features,
  isCurrent,
  customCTA,
}: Props) {
  const { hasPayment } = useBilling();
  const { mutate } = postApiMutation("/billing/plan");
  const { addAlert } = useGlobalAlert();
  const queryClient = useQueryClient();

  const handleChoosePlan = async () => {
    mutate(
      { tier: title },
      {
        onSuccess: () => {
          addAlert({
            type: "success",
            body: <div>Successfully subscribed to {title} tier.</div>,
          });
          queryClient.resetQueries();
        },
        onError: (error) => {
          addAlert({
            type: "error",
            body: (
              <div>
                Failed to subscribe to {title} tier: {error.message}
              </div>
            ),
          });
        },
      }
    );
  };

  function renderCTA() {
    if (customCTA) {
      return customCTA;
    } else if (isCurrent) {
      return (
        <Button variant="cta" w="100%" isDisabled={true}>
          Current Plan
        </Button>
      );
    } else if (hasPayment) {
      return (
        <ProductPlanConfirmationDialog tier={title}>
          <Button variant="cta" w="100%" onClick={handleChoosePlan}>
            Choose Plan
          </Button>
        </ProductPlanConfirmationDialog>
      );
    } else {
      return (
        <Button isDisabled variant="cta" w="100%">
          Please Add Payment Method
        </Button>
      );
    }
  }

  return (
    <Card p={8} my={2} w="24%" minW="400px">
      <Heading fontSize="xl" my={2}>
        {title}
      </Heading>
      <Flex gap={2} align="baseline">
        <Text fontSize="2xl">
          {price !== undefined ? `$${price}/month` : `Custom Pricing`}
        </Text>
        {price !== undefined && <Text>+ model usage charge*</Text>}
      </Flex>
      <Divider my={4} />
      <Box height="150px">
        <List spacing={3} my={3}>
          {features.map((feature, index) => (
            <ListItem key={index} fontSize="sm">
              <ListIcon as={FaCheck} color="green" />
              {feature}
            </ListItem>
          ))}
        </List>
      </Box>
      {renderCTA()}
    </Card>
  );
}
