import { Card, Flex, Text, useClipboard } from "@chakra-ui/react";
import { FaCheck, FaRegClipboard } from "react-icons/fa6";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { vs } from "react-syntax-highlighter/dist/esm/styles/hljs";

interface CodeBlockBlockProps {
  code: string;
  language: string;
}

export default function CodeBlock({ code, language }: CodeBlockBlockProps) {
  const { onCopy, hasCopied } = useClipboard(code);

  return (
    <Card>
      <Flex
        bg="highlightBg"
        borderTopRadius="0.5em"
        p={1}
        pl={2}
        pr={2}
        justify="space-between"
      >
        <Text fontSize="sm">{language}</Text>
        <Flex
          align="center"
          className="gap-1"
          cursor="pointer"
          onClick={() => !hasCopied && onCopy()}
        >
          {hasCopied ? (
            <>
              <FaCheck />
              <Text fontSize="sm">Copied</Text>
            </>
          ) : (
            <>
              <FaRegClipboard />
              <Text fontSize="sm">Copy code</Text>
            </>
          )}
        </Flex>
      </Flex>
      <SyntaxHighlighter
        language={language}
        style={vs}
        customStyle={{
          padding: "10px",
          borderBottomLeftRadius: "0.5em",
          borderBottomRightRadius: "0.5em",
        }}
      >
        {code}
      </SyntaxHighlighter>
    </Card>
  );
}
