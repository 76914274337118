import { Button, Flex, Input, useClipboard } from "@chakra-ui/react";
import { useState } from "react";

interface ApiKeyContainerProps {
  apiKey: string;
}

export default function ApiKeyContainer({ apiKey }: ApiKeyContainerProps) {
  const [isShown, setIsShown] = useState<boolean>(false);
  const { onCopy, hasCopied } = useClipboard(apiKey);

  return (
    <Flex w="fit-content">
      <Input
        type={isShown ? "text" : "password"}
        w="350px"
        value={apiKey}
        readOnly
        borderRightRadius={0}
      />
      <Flex
        w="auto"
        px={2}
        bgColor="white"
        borderColor="gray.200"
        borderRightRadius="0.375em"
        borderWidth="1px"
        borderLeft="none"
      >
        <Flex align="center" className="gap-1">
          <Button
            variant="link"
            fontSize="sm"
            fontWeight="400"
            color="black"
            onClick={onCopy}
          >
            {hasCopied ? "Copied" : "Copy"}
          </Button>
          <Button
            variant="link"
            fontSize="sm"
            fontWeight="400"
            color="black"
            onClick={() => setIsShown(!isShown)}
          >
            {isShown ? "Hide" : "Show"}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
