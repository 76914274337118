import { AbsoluteCenter, Text } from "@chakra-ui/react";
import { useOrganizationList } from "@clerk/clerk-react";
import * as Sentry from "@sentry/react";
import { ReactNode } from "react";
import { AiOutlineExperiment } from "react-icons/ai";
import { FiHome, FiSettings } from "react-icons/fi";
import { IoChatboxOutline, IoStatsChartOutline } from "react-icons/io5";
import { LuLayers } from "react-icons/lu";
import { MdOutlineDashboardCustomize, MdOutlinePayment } from "react-icons/md";
import { RiLock2Line } from "react-icons/ri";
import { Outlet, useNavigate } from "react-router-dom";
import { ContactSupportLink } from "../components/contact-support-link";
import LoadingIndicator from "../components/loading-indicator";
import { Sidenav, SidenavContainer, SidenavItem } from "../components/sidenav";
import { SidenavItemGroup } from "../components/sidenav/sidenav-items";

interface DashboardLayoutProps {
  children?: ReactNode;
}

export default function DashboardLayout({ children }: DashboardLayoutProps) {
  const navigate = useNavigate();

  const { isLoaded, userMemberships } = useOrganizationList({
    userMemberships: {
      infinite: true,
    },
  });

  if (!isLoaded || userMemberships.isLoading) {
    return <LoadingIndicator />;
  }

  if (userMemberships.count === 0) {
    navigate("/select-org");
  }

  if (!isLoaded) return "Loading...";

  const mainNavItems: (SidenavItem | SidenavItemGroup)[] = [
    { icon: FiHome, label: "Home", to: "/" },
    {
      icon: MdOutlineDashboardCustomize,
      label: "LoRAs",
      to: "/lora",
    },
    { icon: LuLayers, label: "Base Models", to: "/base-model" },
    { icon: AiOutlineExperiment, label: "Playground", to: "/playground" },
    { icon: RiLock2Line, label: "Api Keys", to: "/api-key" },
    {
      icon: MdOutlinePayment,
      label: "Billing",
      sidenavItems: [
        {
          icon: IoStatsChartOutline,
          label: "Usage",
          to: "/billing/usage-stats",
        },
        {
          icon: FiSettings,
          label: "Settings",
          to: "/billing/settings",
        },
      ],
    },
    {
      icon: IoChatboxOutline,
      label: "Empower Functions Chat Demo",
      to: "/chat-demo",
    },
  ];
  return (
    <SidenavContainer
      sidenav={<Sidenav mainNavItems={mainNavItems} isAnonymous={false} />}
    >
      <Sentry.ErrorBoundary
        fallback={
          <AbsoluteCenter>
            <Text fontSize="xl">
              Unexpected errors happened, please retry and{" "}
              <ContactSupportLink /> if the issue persists.
            </Text>
          </AbsoluteCenter>
        }
      >
        {children}
        <Outlet />
      </Sentry.ErrorBoundary>
    </SidenavContainer>
  );
}
