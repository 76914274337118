import { Flex, Text } from "@chakra-ui/react";
import {
  DateRangePicker,
  DateRangePickerItem,
  DateRangePickerValue,
  SearchSelect,
  SearchSelectItem,
} from "@tremor/react";
import { useEffect, useState } from "react";
import { ApiKeyResponse } from "../../interfaces/api-key";
import {
  BaseModelResponse,
  LoRAResponse,
  ModelType,
} from "../../interfaces/model";
import { createDateWithMidnightTime } from "../../utils";

type UsageStatsProps = {
  apiKeys: ApiKeyResponse[];
  loras: LoRAResponse[];
  baseModels: BaseModelResponse[];
  dateRange: DateRangePickerValue;
  setApiKeyName: (apiKeyName: string) => void;
  setModelName: (modelName: string) => void;
  setDateRange: (dateRange: DateRangePickerValue) => void;
  selectedModel?: { name: string; type: ModelType };
  selectedApiKeyName?: string;
};

const presetPeriods = [
  {
    key: "today",
    label: "Today",
    fromDateGenerator: () => createDateWithMidnightTime(),
  },
  {
    key: "l7d",
    label: "Last 7 days",
    fromDateGenerator: () =>
      createDateWithMidnightTime((date) => date.setDate(date.getDate() - 6)),
  },
  {
    key: "l30d",
    label: "Last 30 days",
    fromDateGenerator: () =>
      createDateWithMidnightTime((date) => date.setDate(date.getDate() - 29)),
  },
  {
    key: "mtd",
    label: "Month to Date",
    fromDateGenerator: () =>
      createDateWithMidnightTime((date) => date.setDate(1)),
  },
  {
    key: "ytd",
    label: "Year to Date",
    fromDateGenerator: () =>
      createDateWithMidnightTime((date) =>
        date.setFullYear(date.getFullYear(), 0, 1)
      ),
  },
];

export default function UsageStatsController({
  apiKeys,
  loras,
  baseModels,
  dateRange,
  setApiKeyName,
  setModelName,
  setDateRange,
  selectedModel,
  selectedApiKeyName,
}: UsageStatsProps) {
  const [localDateRange, setLocalDateRangeImpl] =
    useState<DateRangePickerValue>(dateRange);

  useEffect(() => {
    if (localDateRange.from && localDateRange.to) {
      if (localDateRange.from.getTime() === localDateRange.to.getTime()) {
        const toDate = localDateRange.to;
        toDate.setHours(23, 59, 59);
        setDateRange({
          ...localDateRange,
          to: toDate,
        });
      }
      setDateRange(localDateRange);
    }
  }, [localDateRange, setDateRange]);

  function setLocalDateRange(dateRange: DateRangePickerValue) {
    const toDate = dateRange.to || new Date();
    toDate.setHours(23, 59, 59);
    setLocalDateRangeImpl({
      ...dateRange,
      to: toDate,
    });
    // setLocalDateRangeImpl(dateRange);
  }

  function getApiKeyControl() {
    if (selectedApiKeyName) {
      return <Text as="b">{selectedApiKeyName}</Text>;
    }

    return (
      <SearchSelect
        className="w-fit"
        placeholder="All"
        onValueChange={setApiKeyName}
      >
        {apiKeys.map((data) => (
          <SearchSelectItem
            title={data.name}
            key={data.name}
            value={data.name}
            className="cursor-pointer"
          >
            {data.name}
          </SearchSelectItem>
        ))}
      </SearchSelect>
    );
  }

  function getModelControl() {
    if (selectedModel) {
      return <Text as="b">{selectedModel.name}</Text>;
    }

    return (
      <SearchSelect
        className="max-content"
        placeholder="All"
        onValueChange={setModelName}
      >
        {loras.map((data) => (
          <SearchSelectItem
            title={data.name}
            key={data.name}
            value={data.name}
            className="cursor-pointer"
          >
            LoRA: {data.name}
          </SearchSelectItem>
        ))}
        {baseModels.map((data) => (
          <SearchSelectItem
            title={data.name}
            key={data.name}
            value={data.name}
            className="cursor-pointer"
          >
            Base Model: {data.name}
          </SearchSelectItem>
        ))}
      </SearchSelect>
    );
  }

  return (
    <Flex align="center" justify="space-between" gap={2} flexWrap={"wrap"}>
      <Flex align="center" gap={2} textStyle="bold">
        <Text fontSize="sm" display={"inline-block"} whiteSpace="nowrap">
          Date Range:
        </Text>
        <DateRangePicker
          className="justify-content-center flex"
          value={localDateRange}
          onValueChange={setLocalDateRange}
          enableClear={false}
        >
          {presetPeriods.map((period) => (
            <DateRangePickerItem
              className="cursor-pointer"
              key={period.key}
              value={period.key}
              from={period.fromDateGenerator()}
              to={new Date()}
            >
              {period.label}
            </DateRangePickerItem>
          ))}
        </DateRangePicker>
      </Flex>
      <Flex align="center" gap={2} w="max-content">
        <Text fontSize="sm" display={"inline-block"} whiteSpace="nowrap">
          Api Key:
        </Text>
        {getApiKeyControl()}
        <Text fontSize="sm">Model:</Text>
        {getModelControl()}
      </Flex>
    </Flex>
  );
}
