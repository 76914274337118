import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

export interface SidenavContainerProps {
  children: ReactNode;
  sidenav: ReactElement;
}

export function SidenavContainer({ children, sidenav }: SidenavContainerProps) {
  return (
    <Grid templateAreas={`'sidebar main'`} templateColumns="auto 1fr" w="100vw">
      <GridItem area="sidebar" as="aside" w="full" p={0}>
        <Box
          pos="sticky"
          top={0}
          w={{ base: 0, md: "250px" }}
          borderRight="1px solid"
          borderColor="highlightBg"
          p={{ base: 0, md: 2 }}
          paddingTop={8}
          height="100vh"
          overflow="auto"
          css={{
            "&::-webkit-scrollbar": {
              height: "var(--chakra-sizes-1)",
              width: "var(--chakra-sizes-1)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "var(--chakra-colors-gray-400)",
            },
          }}
          backgroundColor="highlightBg"
        >
          {sidenav}
        </Box>
      </GridItem>
      <GridItem as="main" area="main">
        <Flex
          minH="99vh"
          w="full"
          pt={10}
          pb={{ base: 0, md: 10 }}
          backgroundColor="white"
          justifyContent="center"
          mt="1vh"
          borderTopLeftRadius="lg"
          borderWidth="1px"
          borderColor="gray.300"
        >
          {children}
        </Flex>
      </GridItem>
    </Grid>
  );
}

export default SidenavContainer;
