import {
  Card,
  Divider,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { DateRangePickerValue } from "@tremor/react";
import { useEffect, useState } from "react";
import { ApiKeyResponse } from "../../interfaces/api-key";
import {
  BaseModelResponse,
  LoRAResponse,
  ModelType,
} from "../../interfaces/model";
import { getApi } from "../../services/api/api-service";
import { useGlobalAlert } from "../../services/global-alert/global-alert-context";
import {
  createDateWithLastSecondOfTheDay,
  createDateWithMidnightTime,
} from "../../utils";
import { ContactSupportLink } from "../contact-support-link";
import UsageStatsController from "./usage-stats-controller";
import StatsDashboard from "./usage-stats-dashboard";

interface UsageStatsProps {
  selectedModel?: { name: string; type: ModelType };
  selectedApiKeyName?: string;
}

export default function UsageStats({
  selectedModel,
  selectedApiKeyName,
}: UsageStatsProps) {
  const [apiKeyName, setApiKeyName] = useState<string | undefined>(
    selectedApiKeyName
  );
  const [modelName, setModelName] = useState<string | undefined>(
    selectedModel?.name
  );

  const [dateRange, setDateRange] = useState<DateRangePickerValue>({
    from: createDateWithMidnightTime((date) =>
      date.setDate(date.getDate() - 6)
    ),
    to: createDateWithLastSecondOfTheDay((date) =>
      date.setDate(date.getDate())
    ),
    selectValue: "l7d",
  });

  const lorasResult = getApi<LoRAResponse[]>("/lora");
  const baseModelsResult = getApi<BaseModelResponse[]>("/base-model");
  const apiKeysResult = getApi<ApiKeyResponse[]>("/api-key");

  const { addAlert } = useGlobalAlert();

  useEffect(() => {
    if (lorasResult.error || baseModelsResult.error || apiKeysResult.error) {
      addAlert({
        type: "error",
        body: (
          <Text>
            Unexpected error happened when loading usage stats, please retry{" "}
            <ContactSupportLink /> if the issue persists.
          </Text>
        ),
      });
    }
  }, [
    lorasResult.error,
    baseModelsResult.error,
    apiKeysResult.error,
    addAlert,
  ]);

  if (!lorasResult.data || !baseModelsResult.data || !apiKeysResult.data) {
    return (
      <Stack spacing={5}>
        <Skeleton height="50px" />
        <Divider />
        <SimpleGrid minChildWidth={"550px"} spacing={5}>
          <Card>
            <Skeleton height="450px"></Skeleton>
          </Card>
          <Card>
            <Skeleton height="450px"></Skeleton>
          </Card>
        </SimpleGrid>
      </Stack>
    );
  }

  const granularity =
    dateRange.to!.getTime() - dateRange.from!.getTime() < 1000 * 24 * 3600
      ? "HOUR"
      : "DAY";

  return (
    <Stack spacing={5}>
      <UsageStatsController
        apiKeys={apiKeysResult.data}
        loras={lorasResult.data}
        baseModels={baseModelsResult.data}
        dateRange={dateRange}
        setApiKeyName={setApiKeyName}
        setModelName={setModelName}
        setDateRange={setDateRange}
        selectedModel={selectedModel}
        selectedApiKeyName={selectedApiKeyName}
      />
      <Divider />
      <StatsDashboard
        startTimestamp={Math.floor(dateRange.from!.getTime() / 1000)}
        endTimestamp={Math.floor(dateRange.to!.getTime() / 1000)}
        granularity={granularity}
        apiKeyName={apiKeyName}
        modelName={modelName}
      />
    </Stack>
  );
}
