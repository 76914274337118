import DatasetViewer from "../components/dataset/dataset-viewer";
import DashboardContentLayout from "../layouts/dashboard-content-layout";

export function DatasetViewerPage() {
  return (
    <DashboardContentLayout
      subNavSection="Overview"
      mainTitle="Dataset Viewer"
      mainTitleHelperText="View and manage datasets."
      sectionTitle=""
      sectionTitleHelperText=""
    >
      <DatasetViewer />
    </DashboardContentLayout>
  );
}
