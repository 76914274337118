import { Link } from "@chakra-ui/react";
import ContactModal from "./contact-modal";

export function ContactSupportLink() {
  return (
    <ContactModal>
      <Link href="#" textDecoration="none">
        contact support
      </Link>
    </ContactModal>
  );
}
