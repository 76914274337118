import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    action: "#596aff",
    actionHover: "#0040fc",
    highlightBg: "#edeffd",
  },
  components: {
    Text: {
      baseStyle: {
        fontSize: "sm",
      },
    },
    Checkbox: {
      baseStyle: {
        icon: {
          bg: "action",
        },
        label: {
          fontSize: "sm",
        },
      },
    },
    Divider: {
      baseStyle: {
        borderColor: "gray.300",
      },
    },
    Card: {
      baseStyle: {
        container: {
          borderWidth: "1px",
          borderColor: "highlightBg",
        },
      },
      variants: {
        information: {
          header: {
            bgColor: "highlightBg",
            px: 6,
            py: 3,
            fontSize: "xs",
            textTransform: "uppercase",
            letterSpacing: "wider",
            fontFamily: "heading",
            color: "gray.600",
            fontWeight: "bold",
            borderRadius: 0,
          },
          container: {
            borderRadius: 0,
            borderWidth: "1px",
            borderColor: "highlightBg",
          },
          body: {
            mx: 3,
          },
        },
      },
    },
    Button: {
      variants: {
        cta: {
          color: "white",
          fontSize: "sm",
          backgroundColor: "action",
          borderColor: "action",
          borderWidth: "1px",
          _hover: {
            backgroundColor: "actionHover",
            color: "white",
            borderColor: "actionHover",
            borderWidth: "1px",
            _disabled: {
              backgroundColor: "actionHover",
              color: "white",
              borderColor: "actionHover",
              borderWidth: "1px",
            },
          },
        },
      },
    },
    Link: {
      baseStyle: {
        color: "messenger.500",
        textDecoration: "underline",
        _hover: {
          textDecoration: "underline",
        },
      },
      variants: {
        sideNavItem: {
          display: "block",
          _focus: { bg: "gray.300" },
          _hover: {
            textDecoration: "none",
            color: "black",
            bg: "gray.300",
          },
          _activeLink: { bg: "gray.300" },
          color: "black",
          textDecoration: "none",
          w: "100%",
          borderRadius: "md",
        },
      },
    },
    Stat: {
      baseStyle: {
        label: {
          fontSize: "sm",
          color: "gray.500",
        },
      },
    },
  },
});
