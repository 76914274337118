import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { ReactElement, useRef } from "react";
import { deleteApiMutation } from "../services/api/api-service";
import { useGlobalAlert } from "../services/global-alert/global-alert-context";
import { ContactSupportLink } from "./contact-support-link";

interface DeleteConfirmationDialog {
  children: ReactElement<{
    onClick: () => void;
  }>;
  title?: string;
  entityName: string;
  deletingName: string;
  deletingKey: string;
  deletionApiPath: string;
  description?: string | ReactElement;
  onDeleted: () => Promise<void>;
}

export function DeleteConfirmationDialog<T>({
  title,
  entityName,
  deletingName,
  deletingKey,
  deletionApiPath,
  description,
  children,
  onDeleted,
}: DeleteConfirmationDialog) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { addAlert } = useGlobalAlert();

  const cancelRef = useRef<HTMLButtonElement>(null);

  const { isPending, mutate } = deleteApiMutation<object, T>(
    `${deletionApiPath}/${encodeURIComponent(deletingKey)}`
  );

  async function onDelete() {
    mutate(
      {},
      {
        onError: () => {
          addAlert({
            type: "error",
            body: (
              <Text>
                Unexpected error happened when deleting the{" "}
                {entityName.toLocaleLowerCase()} <b>{deletingName}</b>, please
                retry and <ContactSupportLink /> if the issue persists.
              </Text>
            ),
          });
          onClose();
        },
        onSuccess: async () => {
          addAlert({
            type: "success",
            body: (
              <Text>
                Successfully deleted the {entityName.toLocaleLowerCase()}{" "}
                <b>{deletingName}</b>!
              </Text>
            ),
          });

          onClose();
          await onDeleted();
        },
      }
    );
  }

  function getDescription() {
    if (description) {
      return description;
    }

    return (
      <>
        {entityName} <b>{deletingName}</b> will be permanently deleted.
      </>
    );
  }

  return (
    <>
      {React.cloneElement(children, {
        onClick: onOpen,
      })}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        closeOnOverlayClick={!isPending}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title ?? `Delete ${entityName}`}
            </AlertDialogHeader>
            <AlertDialogBody>
              Please confirm the deletion. {getDescription()}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Flex className="gap-1">
                <Button
                  colorScheme="red"
                  onClick={onDelete}
                  ml={3}
                  isLoading={isPending}
                  loadingText="Deleting"
                >
                  Delete
                </Button>
                <Button
                  ref={cancelRef}
                  onClick={onClose}
                  isDisabled={isPending}
                >
                  Cancel
                </Button>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
